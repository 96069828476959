<template>
    <div class="mt-1 p-3 loadingcontainer mb-2" v-show="uploadProgress[this.caseId] && uploadProgress[this.caseId].length > 0">
        <div class="upload-state d-flex w-100" v-for="uploadItem in uploadProgress[this.caseId]" :key="uploadItem.id">
            <!-- progress bar container -->
            <div class="progress-bar-container">
                <!-- layer: upper -->
                <div class="upload-info d-flex align-items-center">
                    <div class="upload-info-item info-width d-flex align-items-center">
                        <IconFile class="fill-gray"/>
                        <span class="text-truncate">{{ uploadItem.meta.fileName }}</span>
                    </div>
                    <div class="upload-info-item info-width">
                        <IconEvidNum class="fill-gray"/>
                        <span class="text-truncate">{{ uploadItem.meta.evidenceNumber }}</span>
                    </div>
                    <div class="upload-info-item percentage">{{ uploadItem.progress }}%</div>
                </div>
                <!-- layer: lower -->
                <div class="evidence-progress-bar" :style="{ width: uploadItem.progress + '%' }"></div>
            </div>
            <!-- btn container -->
            <div class="progress-bar-btns-container">
                <IconPause class="hover-fill-gray hover-stroke-gray cursor-pointer"
                    @click="pauseUpload(uploadItem.id)"
                    v-if="boolDisplayPauseIcon(uploadItem.id)" />
                <IconPlay class="hover-fill-gray cursor-pointer"
                    @click="continueUpload(uploadItem.id)" 
                    v-else />
                <IconRemove class="hover-stroke-gray svg-size-20px cursor-pointer"
                    @click="removeUpload(uploadItem.id, this.caseId)" />
            </div>
        </div>
    </div>
</template>

<script>
// icons
import IconFile from '@/components/icons/File.vue';
import IconEvidNum from '@/components/icons/EvidNum.vue';
import IconPlay from '@/components/icons/Play.vue';
import IconPause from '@/components/icons/Pause.vue';
import IconRemove from '@/components/icons/Remove.vue';
// reactive variable
import {uploadProgress, uploadState, tusUploadInstances} from '@/store/upload.state.js'

export default {
    name: "ProgressBars",
    components: {
        IconFile,
        IconEvidNum,
        IconPlay,
        IconPause,
        IconRemove
    },
    props: ['caseId'],
    setup() {

        const boolDisplayPauseIcon = (uploadId) => {
            return uploadState[uploadId]?.state === 'upload';
        };

        const pauseUpload = (uploadId) => {
            const uploadInstance = tusUploadInstances[uploadId];
            if (uploadInstance) {
                uploadInstance.abort();
                uploadState[uploadId].state = 'pause';
            };
        };

        const continueUpload = (uploadId) => {
            const uploadInstance = tusUploadInstances[uploadId];
            if (uploadInstance) {
                uploadInstance.start();
                uploadState[uploadId].state = 'upload';
            };
        };

        const removeUpload = (uploadId, caseId) => {
            const uploadInstance = tusUploadInstances[uploadId];
            if (uploadInstance) {
                uploadInstance.abort();
                delete tusUploadInstances[uploadId];
                delete uploadState[uploadId];
            }

            const progress = uploadProgress[caseId];
            const progressIdx = progress.findIndex(p => p.id === uploadId);
            if (progressIdx !== -1) {
                progress.splice(progressIdx, 1);
                if (progress.length === 0) {
                    delete uploadProgress[caseId];
                }
            }
        };

        return {
            uploadProgress,
            boolDisplayPauseIcon,
            pauseUpload,
            continueUpload,
            removeUpload,
        }
    },

}
</script>

<style scoped>
.loadingcontainer {
    border: solid 1px var(--grey-200);
    border-radius: 5px;
}

.progress-bar-container {
    position: relative;
    flex-grow: 1;
    background-color: white;
    border-radius: 0.2em;
    overflow: hidden;
}

.progress-bar-btns-container {
    margin-left: 0.5em;
    flex-basis: 1%;
    height: 32px;
    display: flex;
    justify-content: right;
    align-items: center;
}

.evidence-progress-bar {
    position: absolute;
    height: 100%;
    background-color: rgb(165, 233, 185);
    z-index: 1;
    left: 0;
    top: 0;
}

.upload-info {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    color: gray;
    mix-blend-mode: difference;
    margin-left: 3px;
}

.upload-info-item {
    width: auto;
    font-size: 13px;
}

.info-width {
    width: 30%;
}

.percentage {
    width: 80%;
    margin-right: 10px;
    text-align: right;
}

.upload-state:not(:last-child) {
    margin-bottom: 0.5rem;
}
</style>