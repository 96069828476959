<template>
    <div :class="['side-modal-bg', { open: props.isOpen }]" @click="closeModal"></div>
    <div :class="['side-modal-process', { open: props.isOpen }]">
        <div class="side-modal-process-content d-flex flex-column p-3 bg-white h-100">
            <div class="d-flex justify-content-between align-center">
                <div>
                    <h6 class="cursor-default m-0">{{ props.title }}</h6>
                    <p class="text-sm color-grey-700 cursor-default m-0">
                        <TimeFormat :dateString="props.item?.updatedAt" :showTime="true" />
                    </p>

                </div>
                <XMarkIcon class="p-1 cursor-pointer icon-size-2rem" @click="closeModal" />
            </div>
            <div>
                <div class="summary-content d-flex flex-column cursor-default w-100">
                    <div class="w-100 d-flex mb-1">
                        <div class="m-0 text-sm font-weight-700 flex-1">Type</div>
                        <div class="text-sm flex-1">
                            <div :class="statusClass(props.item?.type)">
                                {{ statusText(props.item?.type) }}
                            </div>

                        </div>
                    </div>
                    <div class="w-100 d-flex mb-1">
                        <div class="m-0 text-sm font-weight-700 flex-1">Process ID</div>
                        <div class="text-sm flex-1" style="overflow: auto;">{{ props.item?.ProcessingProcessId }}</div>
                    </div>
                    <div class="w-100 d-flex mb-1">
                        <div class="m-0 text-sm font-weight-700 flex-1">Cost per File</div>
                        <div class="text-sm flex-1">{{ props.item?.filePricePerUnit / 100 }} Cent</div>
                    </div>
                    <div class="w-100 d-flex mb-1">
                        <div class="m-0 text-sm font-weight-700 flex-1">Cost per Message</div>
                        <div class="text-sm flex-1">{{ props.item?.msgPricePerUnit / 100 }} Cent</div>
                    </div>
                </div>

                <div class="summary-content d-flex flex-column cursor-default w-100">
                    <div class="w-100 d-flex mb-1">
                        <div class="m-0 text-sm font-weight-700 flex-1">Messages</div>
                        <div class="text-sm flex-1">{{ formatNumber(props.item?.fileSuccess) }}</div>
                    </div>
                    <div class="w-100 d-flex mb-1">
                        <div class="m-0 text-sm font-weight-700 flex-1">Files</div>
                        <div class="text-sm flex-1">{{ formatNumber(props.item?.msgSuccess) }}</div>
                    </div>
                    <div class="w-100 d-flex mb-1">
                        <div class="m-0 text-sm font-weight-700 flex-1">Failed Messages</div>
                        <div class="text-sm flex-1">{{ formatNumber(props.item?.fileFailed) }}</div>
                    </div>
                    <div class="w-100 d-flex mb-1">
                        <div class="m-0 text-sm font-weight-700 flex-1">Failed Files</div>
                        <div class="text-sm flex-1">{{ formatNumber(props.item?.msgFailed) }}</div>
                    </div>
                </div>

                <div class="summary-content d-flex flex-column cursor-default w-100">
                    <div class="w-100 d-flex mb-1">
                        <div class="m-0 text-sm font-weight-700 flex-1">Last Update</div>
                        <div class="text-sm flex-1">
                            <TimeFormat :dateString="props.item?.updatedAt" :showTime="true" />
                        </div>
                    </div>
                    <div class="w-100 d-flex mb-1">
                        <div class="m-0 text-sm font-weight-700 flex-1">Files Updated</div>
                        <div class="text-sm flex-1">
                            <TimeFormat :dateString="props.item?.fileUpdatedAt" :showTime="true" />
                        </div>
                    </div>
                    <div class="w-100 d-flex mb-1">
                        <div class="m-0 text-sm font-weight-700 flex-1">Messages Updated</div>
                        <div class="text-sm flex-1">
                            <TimeFormat :dateString="props.item?.msgUpdatedAt" :showTime="true" />
                        </div>
                    </div>
                </div>

                <div class="summary-content d-flex flex-column cursor-default w-100">
                    <div class="d-flex flex-column">
                        <p class=" text-sm font-weight-700 ">Files</p>
                        <div class="evidence-item" v-for="evidence in props.item?.Evidence"
                            v-bind:key="evidence?.evidId">
                            <div :class="statusClassEvid(evidence.state)">
                                {{ statusTextEvid(evidence.state) }}
                            </div>
                            {{ evidence.fileName }}
                            <b>{{ evidence.evidId }}</b>
                        </div>
                    </div>
                </div>

                <div class="summary-content d-flex flex-column cursor-default w-100">
                    <div class="w-100 d-flex mb-1">
                        <div class="m-0 text-sm font-weight-700 flex-1">Total Cost</div>
                        <div class="text-sm flex-1">{{ props.item?.totalCost }} €</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import XMarkIcon from '@/components/icons/XMarkIcon.vue';
import TimeFormat from '@/components/widgets/TimeFormat.vue';
import  { formatNumber } from '@/_helpers/helpers.js';

const props = defineProps({
    isOpen: {
        type: Boolean,
        required: true
    },
    title: {
        type: String,
        required: false
    },
    item: {
        type: Object,
        required: true
    }
});

const emit = defineEmits(['close']);

const closeModal = () => {
    emit('close');
};

const statusClass = (state) => {
    return {
        'status-running': state === 'running',
        'status-success': state === 'finished',
        'status-failed': state === 'failed',
    };
};

const statusText = (state) => {
    switch (state) {
        case 'initialize (file crawler)':
            return 'Initializing';
        case 'ready':
            return 'Ready';
        case 'running':
            return 'Processing';
        case 'finished':
            return 'Processed';
        case 'failed':
            return 'Failed';
        default:
            return 'Unknown';
    }
};

const statusClassEvid = (state) => {
    return {
        'status-init': state === 'initialize (file crawler)',
        'status-ready': state === 'ready',
        'status-running': state === 'running',
        'status-success': state === 'success',
        'status-failed': state === 'failed'
    };
};

const statusTextEvid = (state) => {
    switch (state) {        
        case 'initialize (file crawler)':
            return 'Initializing';
        case 'ready':
            return 'Ready';
        case 'running':
            return 'Processing';
        case 'success':
            return 'Success';
        case 'failed':
            return 'Failed';
        default:
            return 'Unknown';
    }
};
</script>

<style scoped>
.side-modal-process {
    height: 100vh;
    width: 24rem;
    position: absolute;
    display: flex;
    right: 0;
    top: 0;
    flex-direction: column;
    overflow: hidden;
    z-index: 10;
    transition: right 0.5s ease-in-out, opacity 0.75s ease, margin-right 0.5s ease-in-out;
    display: none;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.side-modal-process-content {
    overflow: auto;
}

@media only screen and (max-width: 600px) {
    .side-modal {
        width: 100%;
    }
}

.side-modal-process.open {
    right: 0%;
    display: block
}

.modal-close {
    align-self: flex-end;
    margin-bottom: 20px;
}

.side-modal-bg {
    height: 100vh;
    width: 100%;
    background: #0000001a;
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    z-index: 9;
}

.side-modal-bg.open {
    display: block;
}

.summary-content {
    margin-top: 20px;
    border-top: solid 1px #00000017;
    padding-top: 20px;
}

.color-ready {
    color: #3b82f6 !important;
}

.color-success {
    color: #16c758 !important;
}

.spin-right-round {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.evidence-item {
    overflow-x: scroll;
    font-size: 12px;
    display: ruby;
    gap: 20px;
    margin-bottom: 5px;
}
</style>
