<template>
    <Modal :isOpen="props.isOpen" status="none" @update:isOpen="$emit('update:isOpen', $event)">
        <div class="modal-length">
            <h3 class="pb-2 cursor-default" style="font-size:1rem;">Frequently asked questions</h3>
            <hr class="m-0">
            <div v-for="(faq, index) in faqStore.faqs" :key="faq.question" class="cursor-default" style="font-size: 0.8125rem;">
                <div class="question-item font-weight-700">
                    <div class="flex justify-inbetween align-center cursor-pointer" @click="toggleAnswer(index)">
                        {{ faq.question }}
                        <template v-if="visibleAnswers[index]">
                            <MinusIcon class="border-radius-0-2rem icon-size-15px flex justify-content-center"  />
                        </template>
                        <template v-else>
                            <PlusIcon class="border-radius-0-2rem icon-size-15px flex justify-content-center"/>
                        </template>
                    </div>
                    <div v-if="visibleAnswers[index]">
                        <p class="text-sm">{{ faq.answer }}</p>
                        <button v-if="index === 3" 
                            class="newbtn"
                            @click="restartUserControlTutorial">Start Tutorial</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex row mt-4">
            <p class="text-sm m-0 cursor-default mb-1">Need further assistance?</p>
            <p class="text-sm m-0">
                <PhoneIcon class="icon-size-20px" /> 0619-69216657
            </p>
        </div>
        <button @click="emit('close')" class="newbtn d-flex justify-content-center mt-4 w-100 bg-red-ef5350 color-white hover-bg-red-600">Close</button>
    </Modal>
</template>

<script setup>
import { ref } from 'vue';
import { defineProps } from 'vue';
import router from '@/router';
import { useFaqStore } from '@/store/frontend/faqStore';
import tutorialLandingStore from '@/store/frontend/tutorialLandingStore.js';
// 
import Modal from '@/components/widgets/modal/Modal.vue';
// icons
import PlusIcon from "@/components/icons/PlusIcon.vue";
import MinusIcon from "@/components/icons/MinusIcon.vue";
import PhoneIcon from "@/components/icons/PhoneIcon.vue";

const props = defineProps({
    isOpen: Boolean,
});
const emit = defineEmits(['close']);

const faqStore = useFaqStore();
const visibleAnswers = ref(faqStore.faqs.map(() => false));
const toggleAnswer = (index) => {
    visibleAnswers.value[index] = !visibleAnswers.value[index];
};
const { setRestartTutor } = tutorialLandingStore;

const restartUserControlTutorial = () => {
    setRestartTutor(true);
    router.push('/user-control');
    emit('close');
};
</script>

<style scoped>
.question-item {
    border-bottom: solid 1px var(--grey-300);
    padding: 10px 0px;
}
</style>