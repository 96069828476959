<style scoped>
.modal-input-big {
    border: none;
    outline: none;
    font-size: 1.125rem;
    font-weight: 500;
}

.modal-input {
    border: none;
    outline: none;
}

.modal-textarea {
    border: none;
    outline: none;
    color: black;
    padding: 0;
}

.input-error-message {
    color: var(--red-500);
    font-size: 0.875rem;
}

.cbtn-disabled {
    color: black !important;
    font-size: 0.8125rem !important;
    font-weight: 500 !important;
    padding: 4px 10px;
    border: solid 1px #00000036;
    border-radius: 5px;
    background: white !important;
    box-shadow: 0px 2px 3px 0px #0000000a;
    width: 100%;
}

.cbtn-enabled {
    color: #fff !important;
    font-size: 0.8125rem !important;
    font-weight: 500 !important;
    padding: 4px 10px;
    border: solid 1px #00000036;
    border-radius: 5px;
    background: #ff8a26 !important;
    box-shadow: 0px 2px 3px 0px #0000000a;
    width: 100%;
}
</style>

<template>
    <div class="d-flex color-grey-800 flex-column m-0 mt-4">
        <!-- case name -->
        <label for="case-name" class="color-grey-800 font-weight-500">
            Case Name
        </label>
        <sinacluster-input ref="caseNameInput" placeholder="Ekolite" class="py-1"
            sanitize="normalize-alpha-numeric-space" v-model="newCaseForm.caseName" @input="validateForm" />
        <div v-if="!isCaseNameUnique && !isEmptyCaseName" class="text-xs input-error-message mt-2">Case name is already taken!</div>

        <!-- responsible person -->
        <label for="responsible-person" class="font-weight-500 color-grey-800 mt-3">
            Responsible Person
        </label>
        <sinacluster-input ref="responsiblePersonInput" placeholder="John Doe" class="py-1"
            sanitize="normalize-alpha-numeric-space" v-model="newCaseForm.responsiblePerson" @input="validateForm" />

        <hr>

        <!-- additional information -->
        <textarea ref="additionalInformationInput" class="no-resize modal-textarea"
            placeholder="Tell us something about the case" id="notes" rows="6"
            v-model="newCaseForm.additionalInformation"></textarea>

        <!-- btn -->
        <div class="d-flex justify-content-between mt-3">
            <button :class="{ 'cbtn-enabled': isFormValid, 'cbtn-disabled': !isFormValid }" @click="newCase"
                :disabled="!isFormValid">
                <span v-if="!waiting">Create Case</span>
                <span v-else>Initializing. Please wait.</span>
            </button>
        </div>
    </div>
</template>

<script setup>
import { toRefs, ref, computed, watch } from 'vue';
import SinaclusterInput from '@/components/widgets/SinaclusterInput.vue';
import { useRouter } from 'vue-router';
import { useCaseStore } from '@/store/caseStore';
// import sideNotificationStore from '@/store/frontend/sideNotificationStore.js';

const props = defineProps({
    newCaseForm: {
        type: Object,
        required: true
    }
});
const emit = defineEmits(['reset-form']);
const { newCaseForm } = toRefs(props);

const router = useRouter();
const caseStore = useCaseStore();

// const warning = ref(false);
const waiting = ref(false);
const isEmptyCaseName = computed(() => newCaseForm.value.caseName.trim() === '');
const isEmptyResponsiblePerson = computed(() => newCaseForm.value.responsiblePerson.trim() === '');
const isCaseNameUnique = ref(true);

const isFormValid = computed(() => !isEmptyCaseName.value && !isEmptyResponsiblePerson.value && isCaseNameUnique.value);

const validateForm = async () => {
    if (newCaseForm.value.caseName.trim() !== '') {
        isCaseNameUnique.value = await checkCaseNameUnique(newCaseForm.value.caseName);
    } else {
        isCaseNameUnique.value = true;
    }
    // warning.value = !isFormValid.value;
};

const checkCaseNameUnique = async (caseName) => {
    const cases = await caseStore.cases;
    return !cases.some(caseItem => caseItem.caseName.toLowerCase() === caseName.toLowerCase());
};

const newCase = async () => {
    waiting.value = true;
    try {
        const result = await caseStore.createCase(newCaseForm.value);
        if (result.success) {
            const caseId = result.response.caseId;
            router.push(`/new-evidence/${caseId}`);
        }
    } finally {
        emit('reset-form');
        waiting.value = false;
    }
};

// Watch for changes in case name to trigger validation
watch(() => newCaseForm.value.caseName, validateForm);
</script>
