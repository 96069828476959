<template>
    <div class="d-flex flex-column align-items-center justify-content-center vh-100 vw-100 cursor-default">
        <div class="d-flex">
            <img src="../assets/img/illustrations/logo.svg" width="50" height="50" />
            <h2><span class="color-primary-700">SINA</span>CLUSTER</h2>
        </div>
        <h3 class="mt-2" style="font-weight: 400">The screen of your device is too small.</h3>
        <span>Please use a bigger screen to access this page.</span>
        <span class="mt-1" style="color: var(--grey-500); font-size: smaller;">
            © Sinabis GmbH | All Rights Reserved
        </span>
    </div>

</template>