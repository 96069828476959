<template>
    <div class="d-flex flex-column h-100 py-2 gap-2">
        <!-- Evidence Meta -->
        <div class="row d-flex mx-auto w-100">
            <div class="evidence-edit d-flex flex-column align-items-center justify-content-center bg-white w-100 p-2">
                <div id="evidence-meta-summary-header"
                    class="d-flex justify-content-between align-items-center w-100 mb-3">
                    <div class="cursor-default">
                        <h6 class="m-0" style="font-size: 0.9rem;">Evidence Metadata</h6>
                    </div>
                    <div class="cursor-pointer" 
                        @click.prevent="backToStep(0)">
                        <PencilIcon class="edit-icon " />
                    </div>
                </div>
                <div id="evidence-meta-summary-content"
                    class="d-flex flex-column w-100 cursor-default">
                    <div id="summary-evid-num"
                        class="w-100 d-flex mb-1">
                        <div style="font-size: 0.8125rem;" class="m-0 font-weight-700 flex-1">Evidence name</div>
                        <div style="font-size: 0.8125rem;" class="flex-1">{{ newEvidForm.evidenceNumber }}</div>
                    </div>
                    <div id="summary-unique-desc"
                        class="w-100 d-flex mb-1">
                        <div style="font-size: 0.8125rem;" class="m-0 font-weight-700 flex-1">Description</div>
                        <div style="font-size: 0.8125rem;" class="flex-1">{{ newEvidForm.uniqueDescription }}</div>
                    </div>
                    <div id="summary-notes"
                        class="w-100 d-flex mb-1">
                        <div style="font-size: 0.8125rem;" class="m-0 font-weight-700 flex-1">Notes</div>
                        <div style="font-size: 0.8125rem;" class="flex-1">{{ newEvidForm.notes }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Selected File -->
        <div class="row d-flex mx-auto w-100">
            <div class="evidence-edit d-flex flex-column align-items-center justify-content-center bg-white p-2 w-100">
                <div id="evidence-meta-summary-header"
                    class="d-flex justify-content-between align-items-center w-100 mb-3">
                    <div class="cursor-default">
                        <h6 style="font-size: 0.9rem;" class="m-0">Selected File</h6>
                    </div>
                    <div class="cursor-pointer" 
                        @click.prevent="backToStep(1)">
                            <PencilIcon class="edit-icon " />
                    </div>
                </div>
                <div id="evidence-meta-summary-content"
                    class="d-flex flex-column cursor-default w-100">
                    <div id="summary-file-name"
                        class="w-100 d-flex mb-1">
                        <div style="font-size: 0.8125rem;" class="m-0 font-weight-700 flex-1">File name</div>
                        <div style="font-size: 0.8125rem;" class="flex-1">{{ newEvidForm.fileName }}</div>
                    </div>
                    <div id="summary-file-size"
                        class="w-100 d-flex mb-1">
                        <div style="font-size: 0.8125rem;" class="m-0 font-weight-700 flex-1">File size</div>
                        <div style="font-size: 0.8125rem;" class="flex-1">{{ fileSize }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!--Check Box-->
        <div class="d-flex justify-content-between align-items-center border-bottom-solid-1px-grey-200 py-4">
            <p class="text-xs m-0">Please check the box if all the information is correct.</p>
            <div class="form-check p-0 m-0">
                <input class="cluster-checkbox" type="checkbox" @change="toggleEvidenceVerified" :checked="evidenceVerified" />
                <label class="form-check-label" for="flexCheckDefault"></label>
            </div>
        </div>
        <!-- Button -->
        <div class="mt-3 d-flex ms-auto">
            <button class="newbtn color-white"
                :class="{ 'bg-primary-500 hover-bg-primary-700': evidenceVerified, 'bg-grey-400': !evidenceVerified}"
                @click="startUpload">
                Start upload
            </button>
        </div>
    </div>
</template>

<script setup>
import { toRefs, computed, ref } from 'vue';
import { useStore } from "vuex";
import { handleUpload } from '@/_helpers/upload.js';
// helper
import  { calculateFileSize } from '@/_helpers/helpers.js';
import notificationStore from '@/store/frontend/notifications.js';
// icons
import PencilIcon from '@/components/icons/PencilIcon.vue';

const props = defineProps({
    newEvidForm: {
        type: Object,
        required: true,
    },
    caseId: {
        type: String,
        required: true
    },
    caseName: {
        type: String,
        required: true
    }
})
const emit = defineEmits(['reset']);
const { newEvidForm } = toRefs(props);

const store = useStore();
const evidenceVerified = ref(false);

// computed
const fileSize = computed(() => {
    if (newEvidForm.value.file) {
        return calculateFileSize(newEvidForm.value.file.size);
    } else {
        return 'NaN'
    }
})

// workflow
const backToStep = (stepNum) => {
    store.dispatch('changeEvidenceStep', stepNum);
};

const toggleEvidenceVerified = () => {
    evidenceVerified.value = !evidenceVerified.value;
}

const startUpload = async () => {
    if (evidenceVerified.value) {
        const fileName = newEvidForm.value.fileName;
        const evidenceNumber = newEvidForm.value.evidenceNumber;
        const uniqueDescription = newEvidForm.value.uniqueDescription;
        const notes = newEvidForm.value.notes;
        const file = newEvidForm.value.file;
        // reset
        emit('reset');
        // input
        const evidenceMeta = {
            fileName: fileName,
            evidenceNumber: evidenceNumber,
            uniqueDescription: uniqueDescription,
            notes: notes
        };
        try {
            // response
            const result = await handleUpload(
                file,
                props.caseId,
                props.caseName,
                evidenceMeta
            );
            if (result.success) {
                notificationStore.addNew('success', 'Upload evidence', `${evidenceMeta.fileName} has been added to case: ${props.caseName}`, `/new-evidence/${props.caseId}`);
            }
        } catch (error) {
            if(error.message.includes("ProcessingProcess")) {
                notificationStore.addNew('alert', 'Unable to upload evidence', 'Process is running.');
            } else if(error.message.includes("already in case")) {
                notificationStore.addNew('alert', 'Unable to upload evidence', 'This file has been already uploaded.');
            } else {
                notificationStore.addNew('alert', 'Unable to upload evidence', 'An unexpected error occured during upload.');
            }
        }
    }
};
</script>

<style>
.evidence-edit {
  border: solid 1px #00000014;
  box-shadow: 4.5px 4.5px 3.6px rgba(0, 0, 0, 0.003), 12.5px 12.5px 10px rgba(0, 0, 0, 0.005), 30.1px 30.1px 24.1px rgba(0, 0, 0, 0.007), 100px 100px 80px rgba(0, 0, 0, 0.01);
  border-radius: .6rem !important;
  padding: 0.86rem !important;
}

.edit-icon {
    height: 2.17rem;
    width: 2.17rem;
    padding: 0.37rem;
    background: white;
    border: solid 1px white;
    transition: all 0.15s ease-in;
} 

.edit-icon:hover {
    stroke: var(--primary-600);
}
</style>