<style>
.blob-step {
    height: 1.25rem;
    background: white;
    border: solid 1px #0000000d;
    border-radius: 1rem;
}

.blob {
    color: #28364f;
    gap: 10px;
}

.blob-icon {
    background: white;
    width: 3.1rem;
    height: 3.1rem;
    margin-bottom: 0.62rem;
    border-radius: 50%;
}

.blob-step-activate {
    background: var(--primary-400);
}

.welcome-container {
    border: 0.5px solid rgb(216, 216, 216);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 3px 8px, rgba(0, 0, 0, 0.01) 0px 2px 5px, rgba(0, 0, 0, 0.067) 0px 1px 1px;
}
</style>

<template>
    <div class="d-flex flex-column p-4">
        <!-- greetings -->
        <div style="flex-shrink: 0">
            <h2 class="color-grey-900" style="font-size: 1.4rem;font-stretch: normal;letter-spacing: normal;">Welcome to
                Sinacluster</h2>
        </div>
        <!-- landing contents -->
        <div class=" my-4 welcome-container">
            <!-- step bars -->
            <div class="d-flex w-100 p-4">
                <div class="blob-step w-100 me-2" :class="{ 'blob-step-activate': landingStep >= 1 }"></div>
                <div class="blob-step w-100 me-2" :class="{ 'blob-step-activate': landingStep >= 2 }"></div>
                <div v-if="firstTutor" class="blob-step w-100" :class="{ 'blob-step-activate': landingStep === 3 }"></div>
            </div>
            <!-- step details -->
            <div class="blob p-4 w-100 d-flex">
                <div class="flex-1">
                    <div class="blob-icon border d-flex align-items-center justify-content-center">
                        <component :is="stepData.icon" class="icon-xs color-grey-850" />
                    </div>
                    <div class="col">
                        <h5 style="font-size: 1rem;" class="font-weight-700">{{ stepData.title }}</h5>
                        <p style="font-size: 0.8125rem" v-html="stepData.description"></p>
                    </div>
                    <button @click="handleTutorial"
                        class="newbtn bg-white mt-4">
                        {{ stepData.buttonText }}
                    </button>
                </div>
                <div class="flex-1" :style="backgroundImageStyle"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import tutorialLandingStore from '@/store/frontend/tutorialLandingStore.js';
// icons & svgs
import UploadIcon from '@/components/icons/UploadCloud.vue';
import InboxIcon from '@/components/icons/InboxIcon.vue';
import UserGroupIcon from '@/components/icons/UserGroupIcon.vue';
import createCaseSVG from "@/assets/img/illustrations/createcase.svg";
import uploadEvidenceSVG from "@/assets/img/illustrations/addFiles.svg";
import manageUsersSVG from "@/assets/img/illustrations/manageusers.svg";

const store = useStore();

const { landingStep, firstTutor } = tutorialLandingStore;

const props = defineProps({
    caseItem: {
        type: Object,
        required: false,
        default: () => ({ Evidence: [], caseId: '' })
    }
});


// 'blob-step-activate'
const stepData = computed(() => {
    if (landingStep.value === 1) {
        return {
            icon: InboxIcon,
            title: 'Your first case',
            description: 'To get started, simply create your first case by providing a name and assigning a responsible person.',
            buttonText: 'Create case'
        };
    } else if (landingStep.value === 2) {
        return {
            icon: UploadIcon,
            title: 'Upload your first evidence',
            description: 'Now that you have created a case, you can upload evidence related to it.<br>Click the button below to start uploading.',
            buttonText: 'Upload evidence'
        };
    } else if (landingStep.value === 3) {
        return {
            icon: UserGroupIcon,
            title: 'Manage your team',
            description: 'Easily manage your team members, upload additional evidence, and handle various case-related tasks.',
            buttonText: 'Manage Users'
        };
    } else {
        return {
            icon: InboxIcon,
            title: 'Landing step not found',
            desription: 'landing step not found',
            buttonText: 'reset'
        }
    }
});

const backgroundImageStyle = computed(() => {
    if (landingStep.value === 1) {
        return {
            background: `url(${createCaseSVG}) no-repeat`,
            backgroundPosition: 'right',
            backgroundSize: '60%'
        };
    } else if (landingStep.value === 2) {
        return {
            background: `url(${uploadEvidenceSVG}) no-repeat`,
            backgroundPosition: 'right',
            backgroundSize: '50%'
        };
    } else {
        return {
            background: `url(${manageUsersSVG}) no-repeat`,
            backgroundPosition: 'right',
            backgroundSize: '50%'
        };
    }
});

const handleTutorial = () => {
    if (landingStep.value === 1) {
        store.dispatch('openCaseModal');
        router.push('/case-overview/new-case');
    } else if (landingStep.value === 2) {
        store.dispatch('openEvidenceModal');
        // console.log(`${props.caseItem.caseId}`);
        router.push(`/new-evidence/${props.caseItem.caseId}`);
    } else {
        router.push('/user-control');
    }
};
</script>