
// user right: admin & user
class PermissionError extends Error {
    constructor(message) {
        super(message);
        this.name = "PermissionError";
    }
}

// login & change pw
class AuthorizationError extends PermissionError {
    constructor(message) {
        super(message);
        this.name = "";
    }
}

class UnexpectedError extends Error {
    constructor(message) {
        super(message);
        this.name = "UnexpectedError";
    }
}

export {
    PermissionError,
    AuthorizationError,
    UnexpectedError
}