<template>
    <figure class="mt-2">
        <svg width="220" height="125" viewBox="0 0 340 190" xmlns="http://www.w3.org/2000/svg">
            <path d="M320 170c0-82.843-67.157-150-150-150S20 87.157 20 170" stroke="var(--blue-200)" stroke-width="10" fill="none" fill-rule="evenodd" stroke-linecap="round"/>
        </svg>    
        <svg width="220" height="125" viewBox="0 0 340 190" xmlns="http://www.w3.org/2000/svg">
            <path class="pie-chart-line" d="M320 170c0-82.843-67.157-150-150-150S20 87.157 20 170" stroke="var(--primary-400)" stroke-width="10" stroke-dashoffset="-471" stroke-dasharray="471" fill="none" fill-rule="evenodd" stroke-linecap="round"/>
        </svg>
        <div class="text">
            <div class="percent">
                <span class="number me-2">{{ displayedPercent }}</span>
                <span class="symbol">%</span>
            </div>
        </div>
    </figure>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

const userTotalSpace = 500;
const displayedPercent = ref(0);

const props = defineProps({
    usedSizeGB: {
        type: Number,
        required: true
    }
});

const freeSpacePercent = computed(() => {
    const restSpaceGB = userTotalSpace - props.usedSizeGB;
    return ((restSpaceGB / userTotalSpace) *100 );
});

onMounted(() => {
    const path = document.querySelector('.pie-chart-line');
    const lineLength = Math.round(path.getTotalLength());

    const linePercent = -Math.abs(lineLength * (1 - freeSpacePercent.value / 100));
    path.style.strokeDashoffset = linePercent;
    path.style.strokeDasharray = lineLength;

    const interval = 1000 / freeSpacePercent.value;
    let count = 1;
    const intervalId = setInterval(() => {
    if (count <= freeSpacePercent.value) {
        displayedPercent.value = count;
        count++;
    } else {
        clearInterval(intervalId);
    }
    }, interval);
});
</script>

<style scoped>
figure {
  position: relative;
  display: flex;
  align-self: begin;
  justify-content: center;
  width: 234px;
  height: 125px;
  margin: 0;
}

figure svg {
  position: absolute;
  left: 0;
  top: 0;
}

figure svg path {
  transition: all 1s;
}

figure .text {
  align-self: flex-end;
  text-align: center;
  z-index: 1;
}

figure .percent {
  font-size: 30px;
  font-weight: 700;
  display: block;
}

figure .symbol {
  font-size: 14px;
  margin-left: -8px;
}

figure .complete {
  font-size: 14px;
}
</style>