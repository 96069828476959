<template>
    <div :class="['modal-overlay', { open: props.isOpen }]">
        <div class="modal-content d-flex flex-column p-3">
            <!-- header -->
            <div class="d-flex justify-content-between items-center">
                <div class="d-flex">
                    <span class="current-view x-hover cursor-pointer">{{ props.currentview }}</span>
                    <p class="modal-title">
                        <!-- chevron right-->
                        <ChevronRight class="p-1 cursor-pointer icon-size-20px" />

                        <span>{{ props.title }}</span>
                    </p>
                </div>
                <XMarkIcon class="x-hover p-1 cursor-pointer icon-size-25px" @click="closeModal" />
            </div>
            <!-- content -->
            <div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>


<script setup>
import XMarkIcon from '@/components/icons/XMarkIcon.vue';
import ChevronRight from '@/components/icons/ChevronRight.vue';

const props = defineProps({
    isOpen: {
        type: Boolean,
        required: true
    },
    title: {
        type: String,
        required: false
    },
    desc: {
        type: String,
        required: false
    },
    currentview: {
        type: String,
        required: false
    },
});

const emit = defineEmits(['close']);

const closeModal = () => {
    emit('close');
};
</script>



<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    display: none;
    /* Default hidden */
    align-items: center;
    justify-content: center;
    z-index: 9999;
    /* above verything */
    transition: visibility 0.3s ease, opacity 0.3s ease;
    /* For smooth transition */
    visibility: hidden;
    /* Hide visibility */
}

.modal-overlay.open {
    display: flex;
    /* Show the modal */
    visibility: visible;
    /* Make it visible */
}

.modal-content {
    width: 90%;
    max-width: 500px;
    /* Adjust based on your preferred maximum width */
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    /* Adjust padding as needed */
}

.icon-size-2rem {
    width: 2rem;
    height: 2rem;
}

/* Optional: Add responsive styles for smaller screens */
@media only screen and (max-width: 600px) {
    .modal-content {
        width: 95%;
        /* Adjust based on your design preferences */
    }
}

.current-view {
    font-size: 12px;
    background: white;
    box-shadow: 0px 1px 2px #0003;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px;
}

.modal-title {
    display: flex;
    align-items: center;
    gap: -5px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

</style>
