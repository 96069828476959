<template>
    <div class="collapse sidenav-menu navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">

        <ul class="navbar-nav color-grey-900">
            
            <li>
                <sidenav-item class="nav-item-sinacluster" :class="getRoute() === 'dashboard' ? 'active' : ''"
                    iconPadding="7px" to="/dashboard" navText="Dashboard">
                    <template v-slot:icon>
                        <DashboardIcon />
                    </template>
                </sidenav-item>
            </li>

            <li>
                <sidenav-item class="nav-item-sinacluster"
                    :class="getRoute() === 'case-overview ' ? 'active' : ''"
                    iconPadding="7px" to="/case-overview"
                    :notificationBadge="numCaseWithOpenedCase"
                    navText="Case Overview">
                    <template v-slot:icon>
                        <InboxStack />
                    </template>
                </sidenav-item>
                <div v-if="store.state.isEvidenceModalOpen"
                    class="mt-2" >
                    <!--TODO: active: when upload evidence side modal is opened -->
                    <transition name="fade">
                        <BulletNav :active-step="activeStep"
                            :is-visible="store.state.isCaseModalOpen"
                            :steps="[ { label: 'Case description' }, { label: 'Summary' } ]"
                            type="case-overview" />
                    </transition>
                    <transition name="fade">
                        <BulletNav :active-step="activeEvidenceStep"
                            :is-visible="store.state.isEvidenceModalOpen"
                            :steps="[ { label: 'Evidence Description' }, { label: 'Upload File' }, { label: 'Summary' } ]"
                            type="new-evid" />
                    </transition>                
                </div>
            </li>
            
            <li class="nav-item">
                <sidenav-item class="nav-item-sinacluster" to="/user-control"
                    :class="getRoute() === 'user-control' ? 'active' : ''" navText="User Management"
                    :iconPadding="'7px'">
                    <template v-slot:icon>
                        <UserGroupIcon />
                    </template>
                </sidenav-item>
            </li>
        </ul>
    </div>

    <div style="border-top: solid 1px var(--grey-200);padding-top:10px;" class="collapse sidenav-menu navbar-collapse w-auto h-auto h-100">

        <ul class="navbar-nav">
            <li class="mt-1">
                <div @click="store.dispatch('openHelpModal')" class="d-flex align-items-center cursor-pointer nav-item-sinacluster p-1 gap-1">
                    <LifeBuoyIcon class="icon-sm color-grey-800" style="padding: 0.5em;"/>
                    <span class="m-0">Help Center</span>
                </div>
            </li>
        </ul>
        <ul class="navbar-nav">
            <li class="mt-1">
                <div onclick="window.open('https://demo.sinabis.io/login', '_blank');"
                    class="d-flex align-items-center cursor-pointer nav-item-sinacluster p-1 gap-1">
                    <SaiLogoIcon class="icon-sm" style="fill: #616161; padding: 0.5em" />
                    <span class="m-0">Sinabis Inspector</span>
                </div>
            </li>
        </ul>
    </div>

    <div style="border-top: solid 1px var(--grey-200);padding-top:10px;" class="collapse sidenav-menu navbar-collapse w-auto h-auto h-100">


<ul class="navbar-nav">
    <li class="mt-1">
        <div @click="signOut"
            class="d-flex align-items-center cursor-pointer nav-item-sinacluster p-1 gap-1">
            <LogOut class="icon-sm" style="padding: 0.5em" />
            <span class="m-0">Logout</span>
        </div>
    </li>
</ul>
</div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useCaseStore } from '@/store/caseStore';
import SidenavItem from "./SidenavItem.vue";
import BulletNav from "./BulletNav.vue";
import router from '@/router';
import { useAuthStore } from "@/store/auth.store";
// icons
import DashboardIcon from "@/components/icons/DashboardIcon.vue";
import UserGroupIcon from "@/components/icons/UserGroupIcon.vue";
import InboxStack from "@/components/icons/InboxStack.vue";
import LifeBuoyIcon from "@/components/icons/LifeBuoyIcon.vue";
import SaiLogoIcon from "@/components/icons/SaiLogoIcon.vue";
import LogOut from '@/components/icons/LogOut.vue'

const getRoute = () => {
    const route = useRoute();
    const routeArr = route.path.split("/");
    return routeArr[1];
};
const authStore = useAuthStore();

const store = useStore();
const caseStore = useCaseStore();

// case step & evidence step
const activeStep = computed(() => store.state.newCaseStep);
const activeEvidenceStep = computed(() => store.state.newEvidenceStep);

const signOut = () => {
    const logoutState = authStore.logout();
    if (logoutState) {
        router.push('/signin')
    };
};

// open case notification
const numCaseWithOpenedCase = computed(() => {
    let count = 0;
    caseStore.cases.forEach(caseItem => {
        if (caseItem.Evidence) {
            let caseCounted = false;
            caseItem.Evidence.forEach(evidItem => {
                if (!caseCounted && evidItem.FilecrawlingProcess?.state === 'success' && (!evidItem.ProcessingProcess || evidItem.ProcessingProcess?.state !== 'success')) {
                    count++;
                    caseCounted = true;
                }
            });
        }
    });
    if ( count === 0 ) {
        count = false;
    }
    return count;
});
</script>

<style scoped>
.nav-item-sinacluster {
    border: solid 1px var(--grey-50);
    border-radius: 0.5rem !important;
    color: var(--grey-900) !important;
    transition: background-color 0.3s ease-in-out, color 0.1s ease-in-out, font-weight 0.1s ease-in-out !important;
}

.nav-item-sinacluster:hover {
    background-color: var(--grey-100);
    color: var(--grey-900) !important;
}

.nav-item-sinacluster.active {
    color: black !important;
  font-size: 0.8125rem !important;
  font-weight: 500 !important;
  border: solid 1px #00000036;
  background: white !important;
  box-shadow: 0px 2px 3px 0px #0000000a;
}
</style>