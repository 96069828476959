<template>
    <Modal :isOpen="props.isOpen" :title="modalText.title" status="alert">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <p class="m-0 color-red-500 font-weight-500">{{ modalText.content }}</p>
            <span>{{ modalText.contentQuestion }}</span>
        </div>

        <div class="d-flex gap-2">
            <button @click="closeModal" 
                class="cbtn-sm mt-4 w-100 text-grey-800 border hover-bg-grey-100">
                Cancel
            </button>
            <button @click="startProcessAll"
                class="cbtn-sm mt-4 w-100 color-white bg-primary-700 hover-bg-primary-600">
                Continue
            </button>
        </div>
    </Modal>
</template>

<script setup>
import Modal from '@/components/widgets/modal/Modal.vue';

const props = defineProps({
    isOpen: {
        type: Boolean,
        required: true
    }
});
const emit = defineEmits(['update:isOpen', 'close', 'process-all']);

const closeModal = () => { emit('close') };
const startProcessAll = () => { emit('process-all'); emit('close'); };

const modalText = {
    title: "Process All Uploaded Files",
    content: "Uploading additional files is not possible during processing.",
    contentQuestion: "Are you sure to continue processing all files?"
}
</script>