<template>
    <div :class="['popup-alert', { open: isOpen }]">
        <div :class="['popup-alert-content', statusClass]">
            <div class="d-flex gap-2 align-items-center">
                <component class="border-radius-0-2rem icon-sm text-center d-flex align-items-center justify-content-center"
                    :is="statusIcon" :class="[statusClass]" />
                <div>
                    <p class="m-0 text-sm font-weight-700" :class="statusClass">{{ title }}</p>
                    <p class="m-0 text-xs" :class="statusClass">{{ desc }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { computed, watch } from 'vue';
import WarningIcon from '@/components/icons/WarningIcon.vue';
import InfoIcon from '@/components/icons/Info.vue';
import CheckIcon from '@/components/icons/Check.vue';

const props = defineProps({
    status: {
        type: String,
        required: false,
        default: 'info'
    },
    isOpen: {
        type: Boolean,
        required: true
    },
    title: {
        type: String,
        required: false
    },
    desc: {
        required: false
    }
});

const statusIcon = computed(() => {
    switch (props.status) {
        case 'alert':
        return WarningIcon;
        case 'success':
        return CheckIcon;
        case 'info':
        default:
        return InfoIcon;
    }
});

const statusClass = computed(() => {
    switch (props.status) {
        case 'alert':
            return 'popup-red';
        case 'success':
            return 'popup-green';
        case 'info':
            return 'popup-blue';
        default:
            return 'popup-blue';
    }
});

const emit = defineEmits(['close']);

watch(() => props.isOpen, (newVal) => {
    if (newVal) {
        setTimeout(() => {
            emit('close');
        }, 3500); 
    }
});
</script>

<style scoped>
.popup-alert {
    position: fixed;
    top: 1rem;
    right: 0rem;
    z-index: 1000;
    width: 25.5rem;
    opacity: 0;
    right: -100%;
    transition: top 1s ease-in-out, opacity 0.5s ease, margin-right 1s ease-in-out;
}

.popup-alert.open {
    right: 10px;
    opacity: 1;
}

.popup-alert-content {
    border-radius: 8px;
    padding: 16px;
    width: auto;
    min-width: 400px;
}
</style>
  