import { fetchWrapper } from '../_helpers/fetch-wrapper';
import { defineStore } from 'pinia';
import { useAuthStore } from './auth.store';
import notificationStore from '@/store/frontend/notifications.js';
import router from '@/router';


const API_URL = process.env['VUE_APP_API_URL'];

export const useUserStore = defineStore('user', {
    state: () => ({
        users: [],
        currentUser: null,
        connected: false,
    }),
    getters: {
        getUserById: (state) => (userId) => {
            return state.users.find((u) => u.userId === userId);
        },
        loggedInUser: (state) => {
            const { user } = useAuthStore();
            return state.users.find((u) => u.userId === user?.userId);
        }
    },
    actions: {
        bindEvents(socket) {
            socket.on("connect", async () => {
                this.users = await this.fetchAllUsers();
                this.connected = true;
            });
            socket.on("disconnect", () => {
                this.connected = false;
            });
            socket.on("user:created", (msg) => {
                this.users.push(msg)
            });
            socket.on("user:updated", (msg) => {
                const index = this.users.findIndex((u) => u.userId === msg.userId);
                if (index !== -1) {
                    this.users[index] = msg;
                }
            });
            socket.on("user:destroyed", (msg) => {
                this.users = this.users.filter((u) => u.userId !== msg.userId);
            })
        },
        async fetchAllUsers() {
            try {
                const response = await fetchWrapper.get(`${API_URL}/user`);
                return response
            } catch (error) {
                console.error(error);
            }
        },
        async fetchUserById(userId) {
            try {
                const response = await fetchWrapper.get(`${API_URL}/user/${userId}`);
                this.currentUser = await response;
                return response
            } catch (error) {
                console.error(error);
            }
        },
        async createUser(user) {
            try {
                const response = await fetchWrapper.post(`${API_URL}/user`, user);
                notificationStore.addNew('success', 'Create user', `${response.name} is created.`);
            } catch (error) {
                notificationStore.addNew('alert', 'Unable to create user', error);
            }
        },
        async deleteUser(userId) {
            try {
                const userItem = this.users.find( item => item.userId === userId );
                await fetchWrapper.delete(`${API_URL}/user/${userId}`);
                notificationStore.addNew('success', 'Remove user', `${userItem.name} is removed.`);
            } catch (error) {
                notificationStore.addNew('alert', 'Unable to remove user', error);
            }
        },
        // TODO： #51 (notifications)
        async updateTutorialDone(tutorialDone) {
            const userId = this.loggedInUser.userId;
            await this.updateUser(userId, {tutorial_done: tutorialDone});
        },
        async updateUser(userId, user) {
            try {
                const response = await fetchWrapper.patch(`${API_URL}/user/${userId}`, user);
                const updatedUser = await response;
                notificationStore.addNew('success', 'Update user');
                return updatedUser
            } catch (error) {
                notificationStore.addNew('alert', 'Unable to update user', error);
            }
        },
        async changePassword(username, oldPassword, newPassword) {
            try {
                const response = await fetchWrapper.patch(`${API_URL}/user/change-password/${username}`, { old_password: oldPassword, new_password: newPassword });
                notificationStore.addNew('success', ' Update user', response.message);
                router.push('/dashboard');
            } catch (error) {
                notificationStore.addNew('alert', 'Unable to Change Password', error);
            }
        },
    },
});
