<template>
    <div class="d-flex justify-content-between align-items-center pb-3 border-bottom-solid-1px-grey-200">
        <h5 class="m-0 cursor-default" style="font-size: 0.9rem;">Processes</h5>
        <div class="text-center m-0 p-0 hide-mobile">
            <ul class="legend cursor-default m-0 p-0" style="font-size: 0.8125rem;">
                <li v-for="(legend, index) in legends" :key="index">
                    {{ legend }}
                </li>
            </ul>
        </div>
    </div>

    <div class="d-flex flex-column h-100 overflow-auto justify-content-center">
        <figure class="d-flex flex-column m-0 pt-2">
            <div v-for="caseItem in sortedChartData"
                class="d-flex flex-row gap-2 align-items-center py-2"
                :style="{ height: itemHeight }" :key="caseItem.name">
                <div style="flex-basis: 15%;">
                    <h6 class="m-0 text-truncate cursor-default d-flex gap-2" style="font-size: 0.8125rem;">
                        <span class="icon-size"><svg data-v-43cf4042="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4"><path data-v-43cf4042="" d="M5.75 7.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM7.25 8.25A.75.75 0 0 1 8 7.5h2.25a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75ZM5.75 9.5a.75.75 0 0 0 0 1.5H8a.75.75 0 0 0 0-1.5H5.75Z"></path><path data-v-43cf4042="" fill-rule="evenodd" d="M4.75 1a.75.75 0 0 0-.75.75V3a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2V1.75a.75.75 0 0 0-1.5 0V3h-5V1.75A.75.75 0 0 0 4.75 1ZM3.5 7a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4.5a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V7Z" clip-rule="evenodd"></path></svg></span>
                        <TimeFormat :dateString="caseItem.updatedAt" :showTime="true" />

                    </h6>
                </div>
                <div style="flex-basis: 15%;">
                    <h6 class="m-0 text-truncate cursor-default" style="font-size: 0.8125rem;">{{ caseItem.name }}</h6>
                </div>
                <div style="flex-basis: 70%;">
                    <div class="bar-animation">
                        <span class="bar-block" v-for="(block, index) in caseItem.data" :key="index"
                            :title="block.title" :style="setProgressBarBorderRadius(caseItem, index)"></span>
                    </div>
                </div>
            </div>
        </figure>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import TimeFormat from '@/components/widgets/TimeFormat.vue';

const props = defineProps({
    chartData: {
        type: Object,
        required: true
    }
});


const maxBarHeightPercent = ref(100);
const legends = ref(['Ready', 'Processed', 'Running', 'Failed']);

const itemHeight = computed(() => {
  const itemCount = props.chartData.slice(0, 4).length;
  return `${maxBarHeightPercent.value / itemCount}%`;
});

// sort after last update (updatedAt)
const sortedChartData = computed(() => {
    return [...props.chartData].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
});

// bar block border radius
const setProgressBarBorderRadius = (caseItem, index) => {

    let style = { width: caseItem.data[index].value }
    if (caseItem.data[index].value === '0%') {
        return style;
    } else {
        const previousBlocks = caseItem.data.slice(0, index);
        const nextBlocks = caseItem.data.slice(index + 1);

        const isFirstNonZero = !previousBlocks.some(b => parseFloat(b.value.replace('%', '')) > 0);
        const isLastNonZero = !nextBlocks.some(b => parseFloat(b.value.replace('%', '')) > 0);
        if (isFirstNonZero) {
            style.borderTopLeftRadius = '10rem';
            style.borderBottomLeftRadius = '10rem';
        }
        if (isLastNonZero) {
            style.borderTopRightRadius = '10rem';
            style.borderBottomRightRadius = '10rem';
        }
        return style;
    }
};
</script>

<style>
@keyframes expand {
    from { width: 0%; }
    to { width: 100%; }
}

.bar-animation {
    animation: expand 1.5s ease forwards;
}

/* progress bars */
.bar-block {
    display: block;
    height: 0.5rem;
    float: left;
    position: relative;
    overflow: hidden;
    opacity: 1;
    transition: opacity, .3s ease;
}

.bar-block:nth-of-type(1),
.legend li:nth-of-type(1):before {
    background-color: var(--blue-500);
    /*state: ready*/
}

.bar-block:nth-of-type(2),
.legend li:nth-of-type(2):before {
    background-color: var(--green-500);
    /*state: success*/
}

.bar-block:nth-of-type(3),
.legend li:nth-of-type(3):before {
    background-color: var(--primary-500);
    /*state: running*/
}

.bar-block:nth-of-type(4),
.legend li:nth-of-type(4):before {
    background-color: var(--red-500);
    /*state: failed*/
}

.bar-block:nth-of-type(5),
.legend li:nth-of-type(5):before {
    background-color: var(--grey-300);
}

.bar-block:hover {
    opacity: .65;
}

/* legend */ 
.legend {
    font-size: .9em;
}

.legend li {
    display: inline-block;
    padding: .25em 1em;
    line-height: 1em;
}

.legend li:before {
    content: "";
    margin-right: .5em;
    display: inline-block;
    width: .5rem;
    height: .5rem;
    background-color: #334D5C;
    border-radius: 99rem;
}

/* responsive */ 
/*@media screen and (min-width: 768px) {
    .bar-block {
        font-size: 1em;
    }
}*/

/*@media screen and (min-width: 768px) {
    @keyframes expand {
        from {
            width: 0%;
        }

        to {
            width: calc(100% - 75px);
        }
    }
}*/

.icon-size {
  width: 14px;
  height: 23px;
}
</style>