<template>
    <Modal :isOpen="props.isOpen" :title="`Delete Case: ${props.selectedCaseItem.caseName}`" status="alert">
        <span>Are you sure you want to delete this case?</span>
        <div class="d-flex gap-2">
            <button @click="closeModal" class="cbtn-sm mt-4 w-100 text-grey-800 border hover-bg-grey-100">Cancel</button>
            <button @click="delCase" class="cbtn-sm mt-4 w-100 bg-red-ef5350 color-white hover-bg-red-600">Delete</button>
        </div>
    </Modal>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useCaseStore } from '@/store/caseStore';
import Modal from '@/components/widgets/modal/Modal.vue';

const props = defineProps({
    isOpen: {
        type: Boolean,
        required: true
    },
    selectedCaseItem: {
        type: Object,
        required: true
    }
});

const router = useRouter();
const caseStore = useCaseStore();

const emit = defineEmits(['update:isOpen', 'close']);

const closeModal = () => {
    emit('close');
};

const delCase = () => {
    caseStore.deleteCase(props.selectedCaseItem.caseId);
    router.push('/case-overview');
}
</script>