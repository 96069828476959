<template>
    <div class="d-flex justify-content-between gap-4 mt-2 costflex">

        <div class="flex-1" v-if="!props.loading">
            <h6 class="text-sm color-grey-700">Size</h6>
            <h1 class="fs-4 color-grey-900">{{ uploadSize }}</h1>
            <p class="text-xs">{{ totalEvid }} Uploads</p>
        </div>
        <div class="table-skeleton mt-2" v-else>
            <div style="height: 5.8rem; width: 23.2rem;" class="row-white"></div>
        </div>

        <div class="flex-1" v-if="!props.loading">
            <h6 class="text-sm color-grey-700">Total messages</h6>
            <h1 class="fs-4 text-dark">
                {{ formatNumber(resultAccumulator.msgSuccess) }}
            </h1>
            <p class="text-xs">Cost {{ resultAccumulator.msgCost }}€</p>
        </div>
        <div class="table-skeleton mt-2" v-else>
            <div style="height: 5.8rem; width: 23.2rem;" class="row-black"></div>
        </div>

        <div class="flex-1" v-if="!props.loading">
            <h6 class="text-sm color-grey-700">Total files</h6>
            <h1 class="fs-4 text-dark">
                {{ formatNumber(resultAccumulator.fileSuccess) }}
            </h1>
            <p class="text-xs">Cost {{ resultAccumulator.fileCost }}€</p>
        </div>
        <div class="table-skeleton mt-2" v-else>
            <div style="height: 5.8rem; width: 23.2rem;" class="row-white"></div>
        </div>

        <div class="flex-1" v-if="!props.loading">
            <h6 class="text-sm color-grey-700">Total cost</h6>
            <h1 class="fs-4 text-dark">{{ formatNumber(resultAccumulator.totalCost) }}€</h1>
            <p class="text-xs" v-if="resultAccumulator.totalCost > 0">{{ resultAccumulator.allocation }}</p>
        </div>
        <div class="table-skeleton mt-2" v-else>
            <div style="height: 5.8rem; width: 23.2rem;" class="row-black"></div>
        </div>
    </div>

    <div v-if="props.selectedCaseItem.additionalInformation?.length > 0"
        class="d-flex flex-column">
        <h6 style="font-size: .9rem;" class="m-0 py-2 border-bottom-solid-1px-grey-100">Additional Information</h6>
        <span v-if="!props.loading" class="border-bottom-solid-1px-grey-200 color-grey-850 text-sm m-0 py-2">{{ props.selectedCaseItem.additionalInformation }}</span>
        <div class="table-skeleton py-2 border-bottom-solid-1px-grey-200" v-else>
            <div class="row-white" style="height: 2rem"></div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import  { formatNumber, calculateFileSize } from '@/_helpers/helpers.js';

const props = defineProps({
    selectedCaseItem: {
        type: Object,
        required: true
    },
    loading: {
        type: Boolean,
        required: true
    },
    processItems: {
        type: Array,
        required: true
    }
});

const totalEvid = computed(() => {
    return props.selectedCaseItem.Evidence?.length;
});

const uploadSize = computed(() => {
    if (props.selectedCaseItem.Evidence) {
        const totalBytes = props.selectedCaseItem.Evidence?.reduce((acc, evidence) => acc + evidence.meta.upload.size, 0);
        return calculateFileSize(totalBytes);
    } else {
        return '0.00 MB'
    }
});

const resultAccumulator = computed(() => {
    // TODO: caculate file/msgFailed
    let msgCost = 0;
    let fileCost = 0;
    let msgSuccess = 0;
    let fileSuccess = 0;
    let msgFailed = 0;
    let fileFailed = 0;

    props.processItems?.forEach(processItem => {
        msgSuccess += processItem.msgSuccess;
        fileSuccess += processItem.fileSuccess;
        msgFailed += processItem.msgFailed;
        fileFailed += processItem.fileFailed;
        const processMsgCost = Number((processItem.msgSuccess * processItem.msgPricePerUnit / 10000).toFixed(2));
        const processFileCost = Number((processItem.fileSuccess * processItem.filePricePerUnit / 10000).toFixed(2));
        msgCost += processMsgCost;
        fileCost += processFileCost;
    });

    const totalCost = msgCost + fileCost;
    const totalAmount = msgSuccess + fileSuccess;
    const msgProcentage = msgSuccess / totalAmount * 100;
    const fileProcentage = fileSuccess / totalAmount * 100;

    return {
        msgSuccess: msgSuccess,
        msgFailed: msgFailed,
        msgCost: msgCost.toFixed(2),
        fileSuccess: fileSuccess,
        fileFailed: fileFailed,
        fileCost: fileCost.toFixed(2),
        totalCost: totalCost.toFixed(2),
        allocation: `${msgProcentage.toFixed(0)}% Messages ${fileProcentage.toFixed(0)}% Files`,
    }
});
</script>