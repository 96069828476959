<template>
    <div v-if="isVisible">
        <div class="d-flex flex-column">
            <div v-for="(item, index) in steps" :key="index" 
                class="d-flex align-items-center gap-2 ms-4"
                style="height: 2rem!important;">
                <div class="position-relative d-flex align-items-center justify-content-center h-100">
                    <div class="dango-size border-round align-self-center" :class="{'bg-primary-500': activeStep >= index, 'bg-grey-400': activeStep < index}"></div>
                    <div class="skewer-size position-absolute h-100 bg-grey-400-np" :class="rectangleClasses(index)"> </div>
                </div>
                <span class="text-xs m-0 cursor-default" :class="{ 'color-primary-500 semibold': activeStep >= index }">{{ item.label }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    activeStep: Number,
    isVisible: Boolean,
    steps: Array,
    type: String
});

const rectangleClasses = (index) => ({
    'bg-primary-500': props.activeStep >= index,
    'bottom-1rem': index === props.steps.length - 1,
    'top-1rem': props.type === 'new-evid' && index === 0,
    'bg-grey-400': props.type === 'new-evid' && props.activeStep === 0
});
</script>

<style scoped>
.bottom-1rem {
    bottom: 1rem;
}

.top-1rem {
    top: 1rem;
}

.dango-size {
    width: 9px;
    height: 9px;
    z-index: 9;
}

.skewer-size {
    width: 2px;
}
</style>
