<template>
    <div class="d-flex flex-row gap-4 realign-db-stats">
        <div class="d-flex flex-column flex-1  dashboard-card-border">
            <div class="p-4">
                <div class="d-flex justify-content-between align-items-center pb-3 border-bottom-solid-1px-grey-200 ">
                    <h5 class="m-0 cursor-default" style="font-size: 0.9rem">Data Breakdown</h5>
                    <div data-v-0b6eab7f="" class="icon-size-20px d-flex align-items-center"><svg data-v-0b6eab7f=""
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                            class="size-6 color-success">
                            <path fill-rule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                clip-rule="evenodd"></path>
                        </svg></div>
                </div>
                <!-- cost pie -->
                <div class="d-flex align-items-center gap-2">
                    <Responsive style="width: 230px; padding: 20px;">
                        <template #main="{ width }">
                            <Chart direction="circular" :size="{ width, height: 150 }"
                                :data="props.messages === 0 && props.files === 0 ? [{ name: 'Empty', value: 100 }] : [{ name: 'messages', value: props.messages }, { name: 'files', value: props.files }]"
                                :config="{ controlHover: false }">
                                <template #layers>
                                    <Pie :dataKeys="['name', 'value']"
                                        :pie-style="{ innerRadius: 55, padAngle: 0.05, colors: ['#ff8a26', '#3b82f6'] }" />
                                </template>
                                <template #widgets>
                                    <Tooltip :config="{
                                        name: { label: ''},
                                        value: { label: '' },
                                    }" hideLine />
                                </template>
                            </Chart>
                        </template>
                    </Responsive>
                    <div class="d-flex gap-4 flex-column">
                        <div class="pie-stats">
                            <h6>Processed Messages</h6>
                            <p class="d-flex gap-2 align-items-center"><span class="color-dot bg-primary-500"></span>{{
                                formatNumber(props.messages) }}</p>
                        </div>
                        <div class="pie-stats">
                            <h6>Processed Files</h6>
                            <p class="d-flex gap-2 align-items-center"><span class="color-dot bg-blue-500"></span>{{
                                formatNumber(props.files) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-1 flex-column h-100">
            <div class="dashboard-card-border p-4" style="height: 277px;">
                <div class="d-flex justify-content-between align-items-center pb-3 border-bottom-solid-1px-grey-200 ">
                    <h5 class="m-0 cursor-default" style="font-size: 0.9rem">Storage</h5>
                    <StorageIcon class="icon-size-22px" />
                </div>
                <div id="storage-content" class="d-flex gap-2 align-items-center mt-4 realign-db-stats">
                    <FreeDiskDoughnut :usedSizeGB="progressTableData.totalSizeGB" />
                    <div class="col gap-2 align-items-center">
                        <div class="d-flex gap-2">
                            <div class="storage-legend-size bg-primary-400"></div>
                            <div class="col" style="width: 7.66rem;">
                                <p class="m-0 font-weight-700 cursor-default" style="font-size: 0.8125rem">Free Space
                                </p>
                                <p class="m-0 cursor-default" style="font-size: 0.8125rem">{{ (500 -
                                    roundedTotalSizeGB).toFixed(2) }} GB</p>
                            </div>
                        </div>
                        <div class="d-flex gap-2">
                            <div class="storage-legend-size" style="background-color: var(--blue-200);"></div>
                            <div class="col" style="width: 7.66rem">
                                <p class="m-0 font-weight-700 cursor-default" style="font-size: 0.8125rem">Used Space
                                </p>
                                <p class="m-0 cursor-default" style="font-size: 0.8125rem">{{ roundedTotalSizeGB }} GB
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useCaseStore } from "@/store/caseStore";
import { Chart, Responsive, Pie, Tooltip } from 'vue3-charts'
import { formatNumber } from '@/_helpers/helpers.js';
// components
import FreeDiskDoughnut from '@/components/dashboard/FreeDiskDoughnut.vue';
// icons
import StorageIcon from '@/components/icons/StorageIcon.vue'

const props = defineProps({
    messages: {
        type: Number,
        required: true
    },
    files: {
        type: Number,
        required: true
    }
});

const caseStore = useCaseStore();
const cases = computed(() => caseStore.cases);

const roundedTotalSizeGB = computed(() => progressTableData.value.totalSizeGB.toFixed(2));
const progressTableData = computed(() => {
    const _report = {
        totalSizeGB: 0,
        barChartData: []
    };
    cases.value.forEach((caseItem) => {
        _report[caseItem.caseName] = {
            count: { processed: 0, running: 0, failed: 0, ready: 0, otal: 0 },
            sumSizeMB: { processed: 0, running: 0, failed: 0, ready: 0, total: 0 }
        }
        let _barChartData = {
            id: caseItem.caseId,
            name: caseItem.caseName,
            updatedAt: caseItem.updatedAt,
            data: []
        };
        if (caseItem.Evidence && caseItem.Evidence?.length > 0) {
            caseItem.Evidence.forEach((evidItem) => {
                const _evidSizeMB = evidItem.meta.upload.size / 1024 / 1024;
                _report.totalSizeGB += _evidSizeMB / 1024;
                if (evidItem.ProcessingProcess !== null) {
                    const _processingState = evidItem.ProcessingProcess.state;
                    if (_processingState === 'success') {
                        _report[caseItem.caseName].count.processed += 1;
                        _report[caseItem.caseName].sumSizeMB.processed += _evidSizeMB;
                    } else if (_processingState === 'failed') {
                        _report[caseItem.caseName].count.failed += 1
                        _report[caseItem.caseName].sumSizeMB.failed += _evidSizeMB;
                    } else {
                        // state: running or queued
                        _report[caseItem.caseName].count.running += 1;
                        _report[caseItem.caseName].sumSizeMB.running += _evidSizeMB;
                    }
                } else {
                    _report[caseItem.caseName].count.ready += 1;
                    _report[caseItem.caseName].sumSizeMB.ready += _evidSizeMB;
                }
            });
            _report[caseItem.caseName].count.total = caseItem.Evidence.length;
            _barChartData.data.push({
                title: 'Ready',
                value: `${Math.round(100 * _report[caseItem.caseName].count.ready / _report[caseItem.caseName].count.total)}%`
            });
            _barChartData.data.push({
                title: 'Processed',
                value: `${Math.round(100 * _report[caseItem.caseName].count.processed / _report[caseItem.caseName].count.total)}%`
            });
            _barChartData.data.push({
                title: 'Running',
                value: `${Math.round(100 * _report[caseItem.caseName].count.running / _report[caseItem.caseName].count.total)}%`
            });
            _barChartData.data.push({
                title: 'Failed',
                value: `${Math.round(100 * _report[caseItem.caseName].count.failed / _report[caseItem.caseName].count.total)}%`
            });
            _barChartData.data.push({
                title: 'NoEvid',
                value: '0%'
            });
        } else {
            _barChartData.data.push({ title: 'Ready', value: '0%' });
            _barChartData.data.push({ title: 'Processed', value: '0%' });
            _barChartData.data.push({ title: 'Running', value: '0%' });
            _barChartData.data.push({ title: 'Failed', value: '0%' });
            _barChartData.data.push({ title: 'NoEvid', value: '100%' });
        };
        _report.barChartData.push(_barChartData);
    });
    return _report;
})
</script>

<style>
.storage-legend-size {
    height: 3.1rem;
    width: 0.3rem;
}

.axis {
    display: none !important;
}

.pie-stats h6 {
    font-style: normal;
    color: rgb(58, 58, 58);
    line-height: normal;
    font-weight: 500;
    font-size: .9rem;
    margin: 0;
}

.pie-stats p {
    font-style: normal;
    color: rgb(27, 27, 27);
    line-height: normal;
    font-weight: 700;
    font-size: 1.1125rem;
    margin: 0;
}

.color-dot {
    background: black;
    border-radius: 50px;
    width: 10px;
    height: 10px;
    display: inline-block;
}
</style>