// stores/faqStore.js
import { defineStore } from 'pinia';

export const useFaqStore = defineStore('faq', {
    state: () => ({
        faqs: [
            {
                question: "How can I create Cases?",
                answer:
                    "To create a case, navigate to the case overview page. On the right side of this page, you'll find a button labeled 'New Case'. Clicking this button opens a modal where you can enter your details."
            },
            {
                question: "How can I upload Evidence?",
                answer:
                    "Inside the case overview, after creating your first case, all cases are listed. Click on a case to reveal the 'Upload Evidence' button in the top right corner. Clicking this button opens a modal, similar to when you created your first case."
            },
            {
                question: "How can I create new Users?",
                answer:
                    "To add a new team member to your project, navigate to the user management view. Within this view, access your directory and click on the 'Create User' button to proceed with creating a new user."
            },
            {
                question: "How do I assign cases to my team members?",
                answer:
                    "Within the user management overview, navigate to the directory on the left side and select the team member. Once selected, open their user profile and click the 'Manage Cases' button on the right side. From there, you can choose which cases the user should have access to."
            },
        ]
    })
});
