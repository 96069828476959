<template>
    <div class="d-flex flex-column m-0">
        <!-- Input: evidence name -->
        <label for="evidenceNumber" class="font-weight-500 color-grey-800" 
            :class="{ 'color-red-500': highlightEvidenceNumber }">
            Name
            <span class="color-grey-400 text-xxs " v-show="newEvidForm.evidenceNumber.trim() === ''">
                (required)
            </span>
        </label>
        <sinacluster-input class="w-100 py-1" 
            placeholder="Evidence name" 
            :class="{ 'border-solid-1px-red-200': highlightEvidenceNumber }" 
            sanitize="normalize-alpha-numeric-space"
            v-model="newEvidForm.evidenceNumber" />
        <!-- Textarea: unique description -->
        <label for="uniqueDescription" class="font-weight-500 color-grey-800 mt-3"
            :class="{ 'color-red-500': highlightUniqueDescription }">
            Description
            <span class="color-grey-400 text-xxs " v-show="newEvidForm.uniqueDescription.trim() === ''">
                (required)
            </span>
        </label>
        <textarea class="no-resize w-100"
            :class="{ 'border-solid-1px-red-200': highlightUniqueDescription }" rows="3"
            v-model="newEvidForm.uniqueDescription" placeholder="Evidence description" />
        <!-- Textarea: notes -->
        <label for="notes" class="font-weight-500 color-grey-800 mt-3">
            Notes
        </label>
        <textarea class="no-resize w-100" 
            placeholder="Additional notes" 
            rows="3" v-model="newEvidForm.notes" />
        
        <div class="d-flex justify-content-between mt-3">
            <button class="newbtn bg-white" @click="closeModal">
                <span>Close</span>
            </button>
            <button class="newbtn d-flex color-white bg-primary-500"
                @click="handleNextStep">
                Next Step
            </button>
        </div>
    </div>
</template>

<script setup>
import { watchEffect, toRefs, computed, ref } from 'vue';
import { useStore } from "vuex";
import SinaclusterInput from '@/components/widgets/SinaclusterInput.vue'
// helpers
import { normalizeEvidUniqueDescription } from '@/_helpers/sanitize-input.js'

const props = defineProps({
    newEvidForm: {
        type: Object,
        required: true,
    }
})
const emit = defineEmits(['close']);
const closeModal = () => { emit('close') };
const { newEvidForm } = toRefs(props);

const store = useStore();

// handle next step
const warning = ref(false);
const handleNextStep = () => {
    if ( newEvidForm.value.evidenceNumber.trim() !== '' && newEvidForm.value.uniqueDescription.trim() !== '' ) {
        warning.value = false;
        store.dispatch('changeEvidenceStep', 1);
    } else {
        warning.value = true;
    }
};

// sanitize input
watchEffect(() => {
    newEvidForm.value.uniqueDescription = normalizeEvidUniqueDescription(newEvidForm.value.uniqueDescription);
});

// highlight
const highlightEvidenceNumber = computed(() => {
    return warning.value && newEvidForm.value.evidenceNumber.trim() === ''
})

const highlightUniqueDescription = computed(() => {
    return warning.value && newEvidForm.value.uniqueDescription.trim() === ''
})
</script>

<style scoped>
textarea::placeholder {
    color: var(--grey-500)!important;
    opacity: 1!important;
}
</style>