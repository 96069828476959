<template>
    <div class="icon-wrapper relative" @click="toggleModal($event)">
        <!-- <div v-if="unreadNotifications.length > 0" class="redcircle">
            {{ unreadNotifications.length }}
        </div> -->
        <HistoryIcon class="cursor-pointer icon-xs text-center d-flex align-items-center justify-content-center" />
        <div v-if="isModalOpen" class="bell-overlay d-flex flex-column" @click="toggleModal($event)" ref="modalRef">
            <div class="triangle-up"></div> 
            <div class="bell-content" @click.stop>
                <div class="d-flex justify-content-between mb-2 align-items-center">
                    <h6 class="m-0">History</h6>
                    <button v-if="notifications.length > 0" class="newbtn bg-white text-xs" @click="clearAll">
                        Clear all
                    </button>
                </div>
                <div class="d-flex flex-column gap-2">
                    <div v-for="(notification, index) in notifications" :key="index"
                        class="d-flex gap-2 noti-bubble justify-content-between align-center"
                        :style="{ backgroundColor: notification.read ? 'white' : '#fbfbff' }">
                        <div class="d-flex gap-2 align-center">
                            <div class="d-flex flex-column gap-2">
                                <TimeFormat class="text-xs color-grey-500" :dateString="notification.date"
                                    :showTime="true" />
                                <!-- TODO: beutify message | message = header/title + errorMsg -->
                                <p class="m-0 color-grey-700" style="font-size: 13px;text-wrap: balance;">
                                    {{ notification.message }}
                                </p>
                                <a class="cursor-pointer" v-if="notification.link"
                                    @click="goToLink(notification.link), toggleModal($event)"
                                    style="font-size: 12px; margin: 0; color: #3b82f6;">
                                    Go to case
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column align-center gap-2 mt-2 justify-content.center"
                    v-if="notifications.length === 0">
                    <FaceSmileIcon class="icon-size-25px color-grey-800" />
                    <p class="m-0 text-xs color-grey-800">You're all caught up. Enjoy your day!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="js">
import { useRouter } from 'vue-router';
import { ref, toRefs, onMounted, onUnmounted } from 'vue';
// store
import notificationStore from '@/store/frontend/notifications.js';
// component
import TimeFormat from '@/components/widgets/TimeFormat.vue';
// icon
import HistoryIcon from '@/components/icons/HistoryIcon.vue';
import FaceSmileIcon from '@/components/icons/FaceSmileIcon.vue';

const router = useRouter();

const { notifications } = toRefs(notificationStore);
const { removeNotification, clearNotifications } = notificationStore;

// notification modals
const isModalOpen = ref(false);
const toggleModal = (event) => {
    if (event) {
        event.stopPropagation();  // Stop event from propagating further
    }
    isModalOpen.value = !isModalOpen.value;
    if (isModalOpen.value) {
        clearNotifications();
    }
};

const goToLink = link => (toggleModal(), router.push(link));
const clearAll = () => notifications.value.forEach(item => removeNotification(item.index));

const modalRef = ref(null);

const handleOutsideClick = (event) => {
    if (modalRef.value && !modalRef.value.contains(event.target) && isModalOpen.value) {
        toggleModal();
    }
};

onMounted(() => {
    window.addEventListener('click', handleOutsideClick);
});

onUnmounted(() => {
    window.removeEventListener('click', handleOutsideClick);
});
</script>

<style scoped>
.icon-wrapper {
    position: relative;
    display: inline-block;
}

.bell-overlay {
    position: absolute;
    top: 100%;
    right: -164px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    width: 350px;
}

.bell-content {
    background: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    border: solid 1px #00000026;
    max-height: 400px;
    overflow: auto;
}

.triangle-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #d9d9d9;
}

/* TODO: not used css */
.noti-bubble {
    border-bottom: solid 1px #00000014;
    padding: 7px;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    75% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}

.shake {
    animation: shake 0.5s ease-in-out;
    z-index: 30;
}

.redcircle {
    background: var(--red-500);
    border-radius: 99px;
    font-size: 12px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    color: white;
    position: absolute;
    top: -7px;
    right: -6px;
    z-index: 40;
}
</style>