import notificationStore from '@/store/frontend/notifications.js';

// general
export function removeItemFromArray(array, item) {
    const index = array.indexOf(item);
    if (index > -1) {
        // console.log(`remove item from array: ${index} | ${item}`);
        array.splice(index, 1);
        // console.log(`remove item from array - final result: ${array} | ${JSON.stringify(array)}`);
    }
};

// error handling
export function monitorAirflowProcess(state, targetId, watchList, notificationTitle, successMsg, failedMsg, successLink='', failedLink='') {
    if (watchList.includes(targetId)) {
        if (state === 'success') {
            notificationStore.addNew('success', notificationTitle, successMsg, successLink);
            removeItemFromArray(watchList, targetId);
        } else if (state === 'failed') {
            notificationStore.addNew('alert', `[Error] ${notificationTitle}`, failedMsg, failedLink);
            removeItemFromArray(watchList, targetId);
        }
    }
};

// format
export function calculateFileSize(sizeInBytes) {
    if (!sizeInBytes) { return "0.00 MB"; }

    const units = ['KB', 'MB', 'GB'];
    let size = sizeInBytes / 1024;
    let unitIndex = 0;

    while (size >= 1000 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
};

export function formatNumber(number) {
  if (typeof number === 'number') {
    const parts = String(number).split('.');
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    const reversedArray = integerPart.split('').reverse();
    const groupedArray = [];

    for (let i = 0; i < reversedArray.length; i += 3) {
      groupedArray.push(reversedArray.slice(i, i + 3).join(''));
    }

    return groupedArray.join(',').split('').reverse().join('') + decimalPart;
  } else {
    return number
  }
}
