<template>
    <!-- card header -->
    <div class="bg-grey-50 p-2 border-bottom-solid-1px-grey-200 cursor-default">
        <!-- uploads -->
        <div v-if="props.inputItem.type==='upload'" class="d-flex align-items-center position-relative">
            <h6 class="m-0 text-grey-900" style="font-size: 0.8125rem;" v-if="!props.warning">Unprocessed Items</h6>
            <h6 class="m-0 color-red-500" style="font-size: 0.8125rem;" v-else>Please remove file crawler failed uploads first.</h6>
            <button class="newbtn position-absolute"
                style="right: 0;" v-if="displayProcessBtn"
                @click="openProcessAllModal">
                Process All
            </button>
        </div>
        <!-- file crawler failed -->
        <!-- TODO: delete evidence function-->
        <div v-else-if="props.inputItem.type==='file-crawler-failed'" class="d-flex align-items-center position-relative">
            <h6 class="m-0 color-red-500" style="font-size: 0.8125rem;" v-if="!deleting && !props.needReload">
                <template v-if="!props.warning">File Crawler Failed</template>
                <template v-else>Delete me first! Then process!</template>

            </h6>
            <h6 class="m-0 color-red-500" style="font-size: 0.8125rem;" v-else-if="deleting && !props.needReload">Please wait. Please don't leave this page.</h6>
            <h6 class="m-0 color-red-500" style="font-size: 0.8125rem;" v-else-if="props.needReload">Please reload the page manually.</h6>

            <button @click="removeAllEvidence" v-if="!deleting && !props.needReload" class="newbtn position-absolute" style="right: 0">
                Delete All
            </button>
            <button @click="reloadPage" v-if="props.needReload" class="newbtn position-absolute" style="right: 0">
                Reload
            </button>

        </div>
        <!-- process in progress -->
        <div v-else-if="props.inputItem.type==='inProgress'">
            <h6 class="m-0 text-grey-900" style="font-size: 0.8125rem;">In Progress</h6>
        </div>
        <!-- process finished -->
        <div v-else-if="props.inputItem.type==='finished'" class="d-flex align-items-center row g-0">
            <h6 class="m-0 color-grey-900 col-4" style="font-size: 0.8125rem;"><TimeFormat :dateString="props.inputItem.updatedAt" :showTime="true"/></h6>
            <!-- TODO: fileFailed / msgFailed [v0.0.7] -->
            <h6 class="m-0 col-4" style="font-size: 0.8125rem;">
                <span v-if="typeof props.inputItem.fileSuccess === 'number'">{{ formatNumber(props.inputItem.fileSuccess) }} Files</span>
                <span v-if="typeof props.inputItem.msgSuccess === 'number' && typeof props.inputItem.fileSuccess === 'number'"> and </span>
                <span v-if="typeof props.inputItem.msgSuccess === 'number'"> {{ formatNumber(props.inputItem.msgSuccess) }} Messages</span>
            </h6>
            <h6 style="font-size: 0.8125rem;" class="m-0 col-4 d-flex justify-content-end">{{ formatNumber(props.inputItem.totalCost) }}€</h6>
        </div>
    </div>
    <!-- card contents -->
    <div @click="handleOpenModal"  v-for="evidItem in props.inputItem.Evidence" :key="evidItem.evidId" 
        class="row g-0 d-flex flex-row align-items-center p-2 hover-bg-grey-100 border-bottom-solid-1px-grey-100 cursor-pointer">
        <!-- state icon + evidNum / size -->
        <div class="col-4 d-flex flex-row align-items-center">
            <div class="icon-size-20px d-flex align-items-center">
                <ArrowUpIcon v-if="evidItem.state === 'ready'" class="color-ready" />
                <CloudUpIcon v-else-if="evidItem.state === 'success'" class="color-success" />
                <ExclamationWarningIcon v-else-if="(evidItem.state === 'failed' || evidItem.state === 'file-crawler-failed') && !deleting" class="color-red-500" />
                <ArrowPath v-else-if="deleting" class="spin-right-round color-grey-400" />
                <ArrowPath v-else class="spin-right-round color-grey-400" />
            </div>
            <div class="d-flex flex-column align-items-start text-sm ms-4">
                <span class="color-grey-800">{{ evidItem.evidenceNumber }}</span>
                <span v-if="evidItem.size" class="color-grey-600">{{ calculateFileSize(evidItem.size) }}</span>
                <span v-else class="color-grey-600">{{ calculateFileSize(evidItem.meta.upload.size) }}</span>
            </div>
        </div>
        <!-- file-name / unique-desc + notes -->
        <div class="col-4 d-flex flex-column hide-mobile">
            <div class="text-sm color-grey-900 text-truncate">{{ evidItem.fileName }}</div>
            <div class="text-xs color-grey-600">
                <strong>{{ evidItem.uniqueDescription }}</strong>
                <span class="ms-1">{{ evidItem.notes }}</span>
            </div>
        </div>
        <!-- create time -->
        <div class="col-2">
            <p class="text-sm m-0 color-grey-600">
                <TimeFormat :dateString="evidItem.createdAt" :showTime="true" />
            </p>
        </div>
        <!-- state badge -->
        <div class="col-2 hide-mobile d-flex justify-content-end">
            <div :class="statusClass(evidItem.state)">
                {{ statusText(evidItem.state) }}
            </div>
        </div>
    </div>

</template>

<script setup>
import { computed, ref, watch } from 'vue';
import  { formatNumber, calculateFileSize } from '@/_helpers/helpers.js';
// component
import TimeFormat from '@/components/widgets/TimeFormat.vue';
// icons
import ArrowUpIcon from '@/components/icons/ArrowUpIcon.vue';
import ArrowPath from '@/components/icons/ArrowPath.vue';
import CloudUpIcon from '@/components/icons/CloudUpIcon.vue';
import ExclamationWarningIcon from '@/components/icons/ExclamationWarningIcon.vue';

const props = defineProps({
    inputItem: { 
        type: Object, 
        required: true 
    },
    needReload: {
        type: Boolean,
        required: false,
        default: false
    },
    warning: {
        type: Boolean,
        required: false,
        default: false
    }
});

const emit = defineEmits(['process-all', 'openModal', 'remove-evids']);
const openProcessAllModal = () => {emit('process-all')};

const handleOpenModal = () => {
    emit('openModal', props.inputItem);
  };

const displayProcessBtn = computed(() => {
    let _result = false;
    if (props.inputItem.type==='upload') {
        if (props.inputItem.Evidence.some(evidence => evidence.state === 'ready')) {
            _result = true;
        }
    }
    return _result
});

const statusClass = (state) => {
    return {
        'status-init': state === 'initialize (file crawler)',
        'status-ready': state === 'ready',
        'status-running': state === 'running',
        'status-success': state === 'success',
        'status-failed': state === 'failed' || state === 'file-crawler-failed'
    };
};

const statusText = (state) => {
    switch (state) {        
        case 'initialize (file crawler)':
            return 'Initializing';
        case 'ready':
            return 'Ready';
        case 'running':
            return 'Processing';
        case 'success':
            return 'Processed';
        case 'failed': 
        case 'file-crawler-failed':
            return 'Failed';
        default:
            return 'Unknown';
    }
};

// TODO: watch process to update delete | replace polling
const removeAllEvidence = () => {
    emit('remove-evids', props.inputItem);
    deleting.value = true;
};

const deleting = ref(false);

const reloadPage = () => { window.location.reload(); };

watch(() => props.needReload, ()=>{
    if (props.needReload) {deleting.value=false};
});

</script>

<style scoped>
.color-ready {
    color: #3b82f6 !important;
}

.color-success {
    color: #16c758 !important;
}

.spin-right-round {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>