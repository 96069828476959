<style scoped>
.cO {}

.cO h5 {
    font-style: normal;
    color: rgb(27, 27, 27);
    line-height: normal;
    font-weight: 500;
    font-size: 0.8125rem;
    margin: 0;
}

.cO .desc {
    font-size: 0.8125rem;
    margin: 0;
}

.cO .search {
    border-top: solid 1px #00000010;
    border-bottom: solid 1px #00000010;
    padding: 6px 0px;
}

.cO .search input {
    border: none;
    outline: none;
    width: 100%;
    padding-left: 30px;
    font-size: 0.8rem;
}

.cO button {
    color: black !important;
    font-size: 0.8125rem !important;
    font-weight: 500 !important;
    padding: 4px 10px;
    border: solid 1px #00000036;
    border-radius: 5px;
    background: white !important;
    box-shadow: 0px 2px 3px 0px #0000000a;
}

.cO button:hover {
    background: rgb(243, 243, 243) !important;
    border-radius: 7px;
}

.cO .top {
    padding: 10px 0px;
    position: fixed;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.349);
    backdrop-filter: blur(10px);
}

.filterandsort {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.filterandsort .rbuttons {
    font-size: 0.8125rem;
    color: #444;
    border: solid 1px #00000024;
    border-radius: 3px;
    padding: 2px 8px;
} .filterandsort .rbuttons:hover {
    background-color: var(--grey-50);
}

.filterselect {
    background-color: white;
    outline: none;
    border: solid 1px #0003;
    border-radius: 4px;
    min-width: 100px;
    max-width: 200px;
    padding: 5px;
}

.filterandsort .sorted {
    border: solid 1px #00000024;
    font-size: 0.8125rem;
    border-radius: 3px;
    padding: 2px 8px;
    color: #444;
} .filterandsort .sorted:hover {
    background-color: var(--grey-50);
}

.filterandsort .sorted .person {
    border-left: solid 1px #0000002b;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    gap: 3px;
}

.filterandsort .sorted .evidgroup {
    border-left: solid 1px #0000002b;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    gap: 3px;
}

.filterandsort .sorted .pl-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.qModalContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
</style>

<template>
    <div class="cO">
        <!-- header -->
        <div class="top d-flex align-items-center justify-content-between realign-overview"
            style="padding-left:15px;padding-right: 15px;">
            <div>
                <h5>Case Overview</h5>
            </div>

            <div class="d-flex gap-2 align-items-center">
                <a class="cursor-pointer x-hover" @click="router.push('/user-control')">
                    <AssignUserIcon class="icon-size-18px color-grey-700" style="margin-top: -2px;" />
                </a>
                <button class="d-flex gap-2 align-items-center" @click="openCaseModal">
                    <PlusIcon class="icon-size-15px color-grey-700"></PlusIcon>
                    <span class="hide-mobile hide-tablet">New Case</span>
                </button>
            </div>
        </div>
        <div class=" p-2">
            <div class="">
                <!-- search -->
                <div class="search" style="margin-top:45px;">
                    <div class="position-relative">
                        <input class="pl-10" v-model="searchQuery" placeholder="Search for a legal case" />
                        <div class="position-absolute h-full d-flex pl-2" style="top:3px; left:7px;">
                            <SearchIcon class="icon-size-18px text-grey-500" />
                        </div>
                    </div>
                </div>

                <!-- sort area -->
                <div class="filterandsort px-2">
                    <div class="sorted d-flex align-items-center position-relative">
                        
                        <SortCriterion :currentCriterion="sortedBy(sortCriterion)" @set-criterion="setCriterion" />

                        <span v-if="selectedResponsiblePerson" class="person">
                            <span style="width: 15px;">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                                    class="size-4">
                                    <path fill-rule="evenodd"
                                        d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0Zm-5-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM8 9c-1.825 0-3.422.977-4.295 2.437A5.49 5.49 0 0 0 8 13.5a5.49 5.49 0 0 0 4.294-2.063A4.997 4.997 0 0 0 8 9Z"
                                        clip-rule="evenodd" />
                                </svg>
                            </span>
                            {{ selectedResponsiblePerson }}
                        </span>

                        <span v-if="selectedEvidenceGroup" class="evidgroup">
                            <span style="width: 15px;">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                                    class="size-4">
                                    <path fill-rule="evenodd"
                                        d="M11 4V3a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v1H4a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1ZM9 2.5H7a.5.5 0 0 0-.5.5v1h3V3a.5.5 0 0 0-.5-.5ZM9 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
                                        clip-rule="evenodd" />
                                    <path d="M3 11.83V12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-.17c-.313.11-.65.17-1 .17H4c-.35 0-.687-.06-1-.17Z" />
                                </svg>
                            </span>
                            {{ selectedEvidenceGroup }}
                        </span>

                    </div>
                    
                    <div class="rbuttons cursor-pointer d-flex gap-2 align-items-center">
                        <QuickModal>
                            <template #icon>
                                <AdjustmentIcon class="icon-size-15px color-grey-700" />
                                Settings
                            </template>
                            <template #content>
                                <div class="qModalContent">
                                    <div class="d-flex align-items-center justify-inbetween gap-2">
                                        <div class="d-flex gap-2">
                                            <FilterUpIcon class="icon-size-15px" />
                                            <p class="m-0 text-xs">Sort by</p>
                                        </div>
                                        <select v-model="sortCriterion" class="filterselect">
                                            <option value="createdAt_desc">Created (Newest to Oldest)</option>
                                            <option value="createdAt_asc">Created (Oldest to Newest)</option>
                                            <option value="updatedAt_desc">Updated At (Newest to Oldest)</option>
                                            <option value="updatedAt_asc">Updated At (Oldest to Newest)</option>
                                            <option value="cost_desc">Cost (Highest to Lowest)</option>
                                            <option value="cost_asc">Cost (Lowest to Highest)</option>
                                        </select>
                                    </div>
                                    <hr>
                                    <div class="d-flex align-items-center justify-inbetween gap-2">
                                        <div class="d-flex gap-2">
                                            <UserIcon class="icon-size-15px" />
                                            <p class="m-0 text-xs">Group by Person</p>
                                        </div>
                                        <select v-model="selectedResponsiblePerson" class="filterselect">
                                            <option value="">All</option>
                                            <option v-for="person in uniqueResponsiblePersons" :key="person"
                                                :value="person">
                                                {{ person }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="d-flex align-items-center justify-inbetween gap-2">
                                        <div class="d-flex gap-2">

                                            <InboxIcon class="icon-size-15px" />
                                            <p class="m-0 text-xs">Group by Evidence</p>
                                        </div>
                                        <select v-model="selectedEvidenceGroup" class="filterselect">
                                            <option value="">All Cases</option>
                                            <option value="with_Evidence">With Evidence</option>
                                            <option value="without_Evidence">Without Evidence</option>
                                        </select>
                                    </div>
                                </div>
                            </template>
                        </QuickModal>
                    </div>
                </div>

                <!-- table -->
                <CasesTable v-if="sortedCases?.length !== 0" :sortedCases="sortedCases" :loading="loading" />

                <EmptyState v-else class="pt-4" :icon="InboxIcon" title="No cases found"
                    desc="It looks like you don't have any cases yet. You can create new cases, or if you were searching or filtering, try adjusting your criteria. Once you have cases, you’ll be able to view, modify, and track their progress here." />
            </div>
            <SideModalNewCase />
        </div>
    </div>
</template>


<script setup>
import router from '@/router';
import { ref, watch, computed, onBeforeUnmount, onMounted, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useCaseStore } from '@/store/caseStore';
import { useResultStore } from "@/store/resultStore";
// helper
import evidenceParser from '@/_helpers/ResponseParser.js';
// component
import SideModalNewCase from '@/components/case_overview/SideModalNewCase.vue';
import CasesTable from '@/components/case_overview/CasesTable.vue';
import SortCriterion from '@/components/case_overview/Functions/SortCriterion.vue';
// icon
import InboxIcon from '@/components/icons/InboxIcon.vue'
import PlusIcon from '@/components/icons/PlusIcon2.vue';
import SearchIcon from '@/components/icons/SearchIcon.vue';
import AssignUserIcon from '@/components/icons/AssignUserIcon.vue';
import AdjustmentIcon from '../components/icons/AdjustmentIcon.vue';
import UserIcon from '../components/icons/UserIcon.vue';
import QuickModal from '../components/widgets/modal/QuickModal.vue';
import FilterUpIcon from '../components/icons/FilterUpIcon.vue';
// empty state
import EmptyState from "@/components/widgets/EmptyState.vue";


// get all cases
const store = useStore();
const caseStore = useCaseStore();
const resultStore = useResultStore();

// TODO: user right!
const userRight = ref('admin');
const storeConnection = ref(false);
const caseLength = ref(0);
const loading = computed(() => {
    return !(storeConnection.value && caseLength.value > 0)
})

const _checkStoreConnection = (_storeConnection) => {
    storeConnection.value = _storeConnection;
    if (_storeConnection) {
        caseLength.value = caseStore.cases?.length;
        if (caseLength.value === 0) {
            handleCaseNotFound(userRight.value);
        }
    }
};

//TODO: resultStore connect
onMounted(() => {
    const _storeConnection = caseStore.connected;
    _checkStoreConnection(_storeConnection);
});

watch(() => [caseStore.connected, resultStore.connected], () => {
    const _storeConnection = caseStore.connected && resultStore.connected;
    _checkStoreConnection(_storeConnection);
});

function handleCaseNotFound(userRight) {
    if (userRight === 'admin') {
        if (!isCaseModalOpen.value) {
            openCaseModal();
        }
    } else if (userRight === 'user') {
        console.log("user can't create new case.");
    }
};

const isCaseModalOpen = computed(() => store.state.isCaseModalOpen);

// all cases
// all cases -> filtered cases -> sorted cases
const allCases = ref([])
watchEffect(async () => {
    if (storeConnection.value) {
        let _allCases = caseStore.cases;
        for ( const item of _allCases ) {
            item.cost = await calculateCaseCost(item);
        }
        allCases.value = _allCases;
    }
})

// search
const searchQuery = ref('');
const filteredCases = computed(() => {
    const query = searchQuery.value.toLowerCase();
    return allCases.value?.filter(caseItem =>
        caseItem.caseName.toLowerCase().includes(query) ||
        caseItem.responsiblePerson.toLowerCase().includes(query)
    );
});

// Grouping
const selectedResponsiblePerson = ref('');
const selectedEvidenceGroup = ref('');

const uniqueResponsiblePersons = computed(() => {
    return [...new Set(caseStore.cases.map(caseItem => caseItem.responsiblePerson))];
});

const groupedCases = computed(() => {
    let cases = filteredCases.value;

    // Group by responsible person if selected
    if (selectedResponsiblePerson.value) {
        cases = cases.filter(caseItem => caseItem.responsiblePerson === selectedResponsiblePerson.value);
    }

    // Group by evidence uploaded if selected
    if (selectedEvidenceGroup.value === 'with_Evidence') {
        cases = cases.filter(caseItem => caseItem.Evidence && caseItem.Evidence.length > 0);
    } else if (selectedEvidenceGroup.value === 'without_Evidence') {
        cases = cases.filter(caseItem => !caseItem.Evidence || caseItem.Evidence.length === 0);
    }

    return cases;
});


// sorting criteria
const sortCriterion = ref('createdAt_desc');

const setCriterion = (choice) => {
    sortCriterion.value = choice;
};

const sortedBy = () => {
    switch (sortCriterion.value) {
        case "createdAt_desc":
            return "Date descending";
        case "createdAt_asc":
            return "Date ascending";
        case "updatedAt_desc":
            return "Updated descending";
        case "updatedAt_asc":
            return "Updated ascending";
        case "cost_desc":
            return "Cost descending";
        case "cost_asc":
            return "Cost ascending";
        default:
            return "Date descending"
    }
}

const sortedCases = computed(() => {
    return groupedCases.value.slice().sort((a, b) => {
        if (sortCriterion.value === 'cost_desc') {
            return Number(b.cost) - Number(a.cost);
        } else if (sortCriterion.value === 'cost_asc') {
            return Number(a.cost) - Number(b.cost);
        } else if (sortCriterion.value === 'createdAt_desc') {
            return new Date(b.createdAt) - new Date(a.createdAt);
        } else if (sortCriterion.value === 'createdAt_asc') {
            return new Date(a.createdAt) - new Date(b.createdAt);
        } else if (sortCriterion.value === 'updatedAt_desc') {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
        } else if (sortCriterion.value === 'updatedAt_asc') {
            return new Date(a.updatedAt) - new Date(b.updatedAt);
        }
    });
});

// new-case side modal
function openCaseModal() {
    store.dispatch('openCaseModal');
};

onBeforeUnmount(() => {
    store.dispatch('closeCaseModal');
});

// export functions
async function calculateCaseCost(caseItem) {
    if (storeConnection.value) {
        const resultItem = await resultStore.getResultByCaseId(caseItem.caseId);
        const costPerCase = new evidenceParser(caseItem, resultItem).calculateCostPerCase();
        return costPerCase;
    } else {
        return 'NaN'
    }
}
</script>
