<template>
    <svg width="20px" height="20px" viewBox="-1.2 -1.2 14.40 14.40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000">
        <g id="SVGRepo_bgCarrier" stroke-width="0"/>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
        <g id="SVGRepo_iconCarrier">
            <title>play [#1000]</title> 
            <desc>Created with Sketch.</desc> 
            <defs> </defs> 
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> 
                <g id="Dribbble-Light-Preview" transform="translate(-65.000000, -3803.000000)" fill="#000000"> 
                    <g id="icons" transform="translate(56.000000, 160.000000)">
                        <path d="M18.074,3650.7335 L12.308,3654.6315 C10.903,3655.5815 9,3654.5835 9,3652.8985 L9,3645.1015 C9,3643.4155 10.903,3642.4185 12.308,3643.3685 L18.074,3647.2665 C19.306,3648.0995 19.306,3649.9005 18.074,3650.7335" id="play-[#1000]"> </path> 
                    </g> 
                </g> 
            </g> 
        </g>
    </svg>
</template>

<script>
export default {
    name: "IconPlay"
}
</script>