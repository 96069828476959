<template>
    <Modal id="unsaved-case-modal" :isOpen="props.openStepOne" title="Unsaved asigned cases" status="alert">
        <span>Click save to update the changes.</span>
        <div id="unsaved-case-modal-btns" class="d-flex gap-3">
            <button class="cbtn-sm mt-4 w-100 bg-red-ef5350 color-white hover-bg-red-600" 
                @click="abortAsignedCaseChanges">
                Abort
            </button>
            <button class="cbtn-sm mt-4 w-100 bg-blue-400 color-white btn-blue-hover" 
                @click="saveAsignedCaseChanges">
                Save
            </button>
        </div>
    </Modal>

    <Modal id="unsaved-case-modal-confirm" :isOpen="props.openStepTwo" title="Changes saved" status="success">
        <button @click="closeUnsavedCaseModalConfirm"
            class="cbtn-sm mt-4 w-100 bg-red-ef5350 color-white hover-bg-red-600">
            Close
        </button>
    </Modal>
</template>

<script setup>
import Modal from '@/components/widgets/modal/Modal.vue';

const props = defineProps({
    openStepOne: { type: Boolean, required: true },
    openStepTwo: { type: Boolean, required: true },
});
const emit = defineEmits(
    ['abort-asigned-case-changes', 'save-asigned-case-changes', 'close-unsaved-case-modal-confirm']
);

const abortAsignedCaseChanges = () => {
    emit('abort-asigned-case-changes');
};

const saveAsignedCaseChanges = () => {
    emit('save-asigned-case-changes');
};

const closeUnsavedCaseModalConfirm = () => {
    emit('close-unsaved-case-modal-confirm');
};
</script>