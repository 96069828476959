<template>
    <div class="d-flex gap-1">
        <span>{{ formattedDate }}</span>
        <span v-if="showTime">{{ hh_mm }}</span>
    </div>
</template>

<script setup>
import { computed, toRefs } from 'vue';

const props = defineProps({
    dateString: {
        type: String,
        required: true
    },
    showTime: {
        type: Boolean,
        default: false
    }
});

const { dateString, showTime } = toRefs(props);

const formattedDate = computed(() => {
    const createAt = new Date(dateString.value);
    const createDate = new Date(createAt).setHours(0, 0, 0, 0);
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const dayDifference = Math.floor((now - createDate) / (1000 * 60 * 60 * 24));
    if (dayDifference === 0) {
        return 'Today';
    } else if (dayDifference === 1) {
        return 'Yesterday';
    } else {
        const day = createAt.getDate().toString().padStart(2, '0');
        //const monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
        const month = createAt.getMonth();
        const year = createAt.getFullYear();
        return `${day}.${month}.${year}`;
    }
});

const hh_mm = computed(() => {
    const date = new Date(dateString.value);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
});
</script>