<template>
    <div class="d-flex p-1 pw-input-border bg-white"
        :class="{'shadow-none': isFocused,
            'input-state-error': error }">
        <input required=true
            :type="inputType"
            :placeholder="placeholder"
            class="w-100"
            :class="{ 'input-placeholder-red': error }"
            @focus="isFocused = true"
            @blur="isFocused = false"
            v-model="rawInput" 
            @input="handleInput" />
        <div class="justify-content-center align-item-center cursor-pointer pr-4"
        style="margin-top: -7px;height:20px" 
            @click="togglePasswordVisibility"
            v-show="passwordMode">
            <PwShow class="stroke-grey-500" v-if="!showPassword"
                title="show" />
            <PwHide class="stroke-grey-500" v-else
                title="hide" />
        </div>
    </div>
</template>

<script setup>
import { ref, watchEffect, watch} from 'vue';
import { normalizeAlphaNumericSpace, normalizeSpace } from '@/_helpers/sanitize-input.js';
import PwShow from '@/components/icons/PwShow.vue';
import PwHide from '@/components/icons/PwHide.vue';

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
    passwordMode: {
        type: Boolean,
        default: false
    },
    error: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: String,
        default: "",
    },
    placeholder: {
        type: String,
        default: "",
    },
    sanitize: {
        type: String,
        default: ""
    }
});

const inputType = ref('');
const showPassword = ref(false);
const isFocused = ref(false);
const rawInput = ref(props.modelValue);

const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
    inputType.value = showPassword.value ? 'text' : 'password';
};

watchEffect(() => {
    inputType.value = props.passwordMode ? 'password': 'text';
});

// sanitize input
watch(rawInput, (newValue) => {
    if (props.sanitize === "" && !props.passwordMode) {
        emit('update:modelValue', newValue);
    } else {
        let sanitizedValue = '';
        if ( props.sanitize === 'normalize-alpha-numeric-space' ) {
            sanitizedValue = normalizeAlphaNumericSpace(newValue);
        } else if (props.passwordMode) {
            sanitizedValue = normalizeSpace(newValue);
        }
        emit('update:modelValue', sanitizedValue);
        if (rawInput.value !== sanitizedValue) {
            rawInput.value = sanitizedValue;
        }
    }
});

watch(() => props.modelValue, (newVal) => {
    rawInput.value = newVal;
});

const handleInput = (event) => {
    rawInput.value = event.target.value;
};
</script>

<style scoped>
.pw-input-border {
    border-radius: 5px;
    border: 1px solid var(--grey-300);
}

.input-state-error {
    border: solid 1px var(--red-500) !important;
}

/* input tags */ 
input {
    border: none;
    outline: none;
    font-size: 0.8125rem;
    padding: 0.3rem;
    background-color: transparent;
    color: #495057;
    height:20px;
}

input::placeholder {
    color: var(--grey-500)!important;
    opacity: 1!important;
}

.input-placeholder-red::placeholder {
}

input:focus {
    border: none;
    box-shadow: transparent;
}
</style>