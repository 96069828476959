<template>
    <Modal id="user-change-pw-modal" 
        :isOpen="props.isOpen" title="Change password" status="empty">
        <div class="position-relative d-flex flex-column align-items-center">
            <!-- user hint -->
            <p class="text-sm">Please set a new password for <User class="ms-1 mb-1 icon-size-15px"/> <span class="text-font-weight-700">{{ props.userItem?.username }}</span></p>
            <!-- pw input -->
            <sinacluster-input id="user-new-pw-input"
                key="new-pw-input-${resetCounter}"
                :passwordMode="true"
                class="mb-3"
                style="width: 19rem!important;"
                placeholder="new password"
                v-model="newPw"/>
            <!-- user warning -->
            <div class="warning-msg" :style="{ display: warning ? 'block' : 'none' }">
                * Please input a new password.
            </div>
            <!-- button -->
            <div class="d-flex w-100 justify-content-between mt-3">
                <button class="newbtn px-5" 
                    @click.prevent="emit('close')">Back</button>
                <button class="newbtn font-weight-700 color-white bg-primary-400 px-5"
                    :class="{ 'bg-primary-500 hover-bg-primary-700': newPw, 'bg-light-gray': !newPw }"
                    @click.prevent="handleSubmitNewPw">
                    Submit
                </button>
            </div>
        </div>
    </Modal>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { useUserStore } from '@/store/userStore';
// icon
import User from '@/components/icons/User.vue';
// components
import Modal from '@/components/widgets/modal/Modal.vue';
import SinaclusterInput from '@/components/widgets/SinaclusterInput.vue';

const props = defineProps({
    isOpen: { type: Boolean, required: true },
    userItem: { type: Object, required: true }
})
const emit = defineEmits(['close']);

const newPw = ref('');
const warning = ref(false);

const userStore = useUserStore();

const handleSubmitNewPw = () => {
    if ( newPw.value ) {
        userStore.updateUser(
            props.userItem?.userId,
            { password: newPw.value }
        );
        newPw.value = '';
        emit('close');
    } else {
        warning.value = true;
    }
};

watchEffect(() => {
  if (newPw.value && warning.value) {
    warning.value = false;
  }
});
</script>

<style scoped>
.warning-msg {
    position: absolute;
    top: 5.1rem;
    left: 0.1rem;
    font-size: x-small;
    color: var(--red-500);
}
</style>