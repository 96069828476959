import io from 'socket.io-client';
import { useCaseStore } from './store/caseStore';
import { useEvidenceStore } from './store/evidenceStore';
import { useUserStore } from './store/userStore';
import { useAuthStore } from './store/auth.store';
import { useResultStore } from './store/resultStore';
let socket;


const API_URL = process.env['VUE_APP_API_URL'];

export const initializeSocket = (newToken) => {
  socket = io(`${API_URL}`, {
    extraHeaders: {
      Authorization: `Bearer ${newToken}`
    }
  });

  socket.on('connect_error', (error) => {
    console.log('Connection Error:', error);
    // Handle connection error here
    if (error.message == "Authentication error") {
      useAuthStore().logout();
    }
  });

  socket.on('error', (error) => {
    console.log('Error:', error);
    // Handle general error here
  });

  socket.on('connect', () => {
    console.log('Socket connected!');
  });

  // bind CaseStoreEvents
  useCaseStore().bindEvents(socket);

  useUserStore().bindEvents(socket)

  useEvidenceStore().bindEvents(socket);

  useResultStore().bindEvents(socket);

  socket.on('disconnect', () => {
    console.log('Socket disconnected!');
  });

  return socket;
};

export const getSocket = () => {
  if (!socket) {
    throw new Error("Socket not initialized. Call initializeSocket() first.");
  }
  return socket;
};

export const updateSocketToken = (newToken) => {
  if (socket) {
    socket.disconnect();
  }
  return initializeSocket(newToken);
};
