import { reactive } from 'vue';

// STATUS: 'alert' 'info' 'success'
// notifications: side-notification-modal & notification-log-navbar
// widget: sideNotification
const sideNotification = reactive({
    state: '',
    isOpen: false,
    title: '',
    message: ''
});

const showSideNotification = (state, title, message) => {
    sideNotification.state = state;
    sideNotification.title = title;
    sideNotification.message = message;
    sideNotification.isOpen = true;
};

const closeSideNotification = () => {
    sideNotification.isOpen = false;
};

// widget: notificationLog
// read[boolean]   date[str]   message[str]   *link[str]
const notifications = reactive([]);

const addNotification = (state, messageHeader, message, link) => {
    // prepend new item
    notifications.unshift({
        state: state,
        read: false,
        date: Date.now(),
        message: `${messageHeader}: ${message}`,
        link: link
    });
};

const removeNotification = (index) => {
    notifications.splice(index, 1);
};

const clearNotifications = () => {
    notifications.forEach(notificationItem => {
        notificationItem.read = true;
    })
};

// trigger both notification
const addNew = (state, title, message, link='') => {
    // shared: state, title, message
    showSideNotification(state, title, message);
    // notification: link (optional)
    addNotification(state, title, message, link);
};

export default {
    sideNotification,
    notifications,
    showSideNotification,
    closeSideNotification,
    addNotification,
    removeNotification,
    clearNotifications,
    addNew
};

