<template>
    <!-- Modals -->
    <ModalRemoveCase :isOpen="isOpenRemoveCase" :selectedCaseItem="selectedCase" @close="isOpenRemoveCase = false"/>
    <ModalProcessAll :isOpen="isOpenProcessAll" @close="isOpenProcessAll = false" @process-all="startProcessAll"/>

    <!-- main content -->
    <div class="app-container">
        <div class="main-content p-3 w-100">
            <!-- Router: navigate back -->
            <div class="d-flex gap-1 align-items-center cursor-pointer" @click="routerToCaseOverview">
                <ChevronLeft class="icon-size-15px"/>
                <p class="text-sm color-grey-600 m-0">Case overview</p>
            </div>
            <!-- Title -->
            <CaseInfo :selectedCaseItem="selectedCase" :loading="!storeConnection"
                      @remove-case="isOpenRemoveCase = true"/>
            <!-- Content -->
            <div class="hidden-overflow" style="overflow: auto; height:100vh;">
                <!-- 4 * statistic cards + additional information -->
                <CaseCost :selectedCaseItem="selectedCase" :loading="!storeConnection"
                          :processItems="sortedItems.finished ? sortedItems.finished : []"/>
                <ProgressBars :caseId="selectedCase.caseId"/>
                <!-- empty state || evidence process table -->
                <EmptyState v-if="selectedCase.Evidence?.length === 0" class="pt-4" :icon="InboxIcon"
                            title="Please Upload Evidence"
                            desc="Here, you can track the status of your evidence uploads and processing"/>
                <div v-else>
                    <h6 style="font-size: 0.9rem;" class="m-0 py-3 d-flex align-items-center">Evidence and Processes</h6>
                    <!-- unprocessed items (uploading & uploaded) -->
                    <ProcessCard v-if="sortedItems.upload?.Evidence.length > 0" :inputItem="sortedItems.upload" :warning="warningFailedUploads"
                                 @process-all="openProcessAllModal"/>
                    <!-- processing items || processing process id / state === running / queued -->
                    <ProcessCard v-if="sortedItems.inProgress?.Evidence.length > 0"
                                 :inputItem="sortedItems.inProgress"/>
                    <!-- processed items -->
                    <div v-if="sortedItems.finished?.length > 0">
                        <div v-for="processItem in sortedItems.finished" :key="processItem.AirflowProcessId">
                            <ProcessCard :inputItem="processItem" @openModal="openModal"/>
                        </div>
                    </div>
                    <!-- unprocessed items (file crawler failed) -->
                    <ProcessCard v-if="sortedItems.fileCrawlerFailed?.Evidence.length > 0" :inputItem="sortedItems.fileCrawlerFailed" :needReload="needReload" :warning="warningFailedUploads" @remove-evids="deleteAllEvids"/>
                    <SideModalProcess title="Process Information" :isOpen="isProcessModalOpen" @close="store.dispatch('closeProcessModal')" :item="selectedProcessItem" />
                </div>
            </div>
        </div>
        <SideModalUpload v-if="selectedCase.caseId" :caseId="selectedCase.caseId" :caseName="selectedCase.caseName"/>
    </div>
</template>

<script setup>
import {ref, computed, watch, onBeforeUnmount, onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from 'vue-router';
import {useCaseStore} from '@/store/caseStore';
import {useResultStore} from "@/store/resultStore";
import {useEvidenceStore} from '@/store/evidenceStore';
import notificationStore from '@/store/frontend/notifications.js';
// helper
import {fetchWrapper} from '@/_helpers/fetch-wrapper';
import evidenceParser from '@/_helpers/ResponseParser.js';
// main content
import CaseInfo from '@/components/add_evids/CaseInfo.vue';
import CaseCost from '@/components/add_evids/CaseCost.vue';
import ProgressBars from '@/components/add_evids/_components/ProgressBars.vue';
import ProcessCard from '@/components/add_evids/_components/ProcessCard.vue';
import SideModalUpload from '@/components/add_evids/SideModalUpload.vue';
// widgets
import ModalRemoveCase from '@/components/add_evids/_modals/RemoveCase.vue';
import ModalProcessAll from '@/components/add_evids/_modals/ProcessAll.vue';
import EmptyState from "@/components/widgets/EmptyState.vue";
// icons
import ChevronLeft from '@/components/icons/ChevronLeft.vue';
import InboxIcon from '@/components/icons/InboxIcon.vue';
// ProcessModal
import SideModalProcess from '@/components/add_evids/_modals/SideModalProcess.vue'

// find caseItem
const props = defineProps({
    caseId: {type: String, required: true}
});

const store = useStore();
const router = useRouter();
const caseStore = useCaseStore();
const resultStore = useResultStore();
const evidenceStore = useEvidenceStore();

// modals
const isOpenRemoveCase = ref(false);
const isOpenProcessAll = ref(false);
//const isEvidenceModalOpen = computed(() => store.state.isEvidenceModalOpen);
const selectedProcessItem = ref(null);
const isProcessModalOpen = computed(() => store.state.isProcessModalOpen);
const openProcessAllModal = () => {
    isOpenProcessAll.value = true
};

const openModal = (item) => {
    store.dispatch('closeEvidenceModal');
    selectedProcessItem.value = item;
    store.dispatch('openProcessModal');
  };

// calculation
const storeConnection = computed(() => caseStore.connected && resultStore.connected);

const selectedCase = computed(() => {
    const caseItem = caseStore.getCaseById(props.caseId);
    return caseItem ? caseItem : {caseId: null};
});

const resultItems = ref([]);
const sortedItems = ref({});

const fetchAndUpdate = async () => {
    if (storeConnection.value) {
        // fetch
        selectedCase.value = caseStore.getCaseById(props.caseId);
        resultItems.value = await resultStore.getResultByCaseId(props.caseId);
        if (selectedCase.value.caseId) {
            // update
            sortedItems.value = new evidenceParser(selectedCase.value, resultItems.value).sort();
        } else {
            selectedCase.value = {caseName: 'notFound', caseId: null, Evidence: [], additionalInformation: null};
        }
    }
};

onMounted(() => {
    fetchAndUpdate();
});

// update
watch(() => [caseStore.connected, resultStore.connected], () => {
    console.log(`watch store connection`);
    fetchAndUpdate();
});

watch(() => selectedCase.value.Evidence, () => {
    console.log(`watch evidence change`);
    fetchAndUpdate();
});
watch(() => sortedItems.value.inProgress?.Evidence?.length, (newLength, oldLength) => {
    if (oldLength > 0 && newLength === 0) {
        console.log(`watch processing progress change`);
        fetchAndUpdate();
    }
});

// back to case overview
const routerToCaseOverview = () => {
    router.push('/case-overview');
};

onBeforeUnmount(() => {
    store.dispatch('closeEvidenceModal');
    store.dispatch('closeProcessModal');
    store.dispatch('changeEvidenceStep', 0);
});

// process-all
const startProcessAll = async () => {
    const API_URL = process.env['VUE_APP_API_URL'];
    try {
        await fetchWrapper.post(`${API_URL}/case/${props.caseId}/processing`, {
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "conf": {
                    "evidences": "TODO"
                }
            })
        });
        notificationStore.addNew('info', 'Process started', '');
        fetchAndUpdate(caseStore.connected);
    } catch (error) {
        notificationStore.addNew('alert', 'Processes failed', `${error}`);
        warningFailedUploads.value = true;
    }
};

// user-hint: please remove crawler failed files first
const warningFailedUploads = ref(false);
watch(warningFailedUploads, async (newValue) => {
    if (newValue) {
        await sleep(10000);
        warningFailedUploads.value = false;
    }
});

// delete evidence
// TODO: wait for bug fix (evidence store not updated)
// TODO: notification failed
const pollingEvid = ref(false);
const evidLength = ref(0);
const delEvidId = ref([]);
const needReload = ref(false);

const deleteAllEvids = async (evidItems) => {
    const evidsNow = await evidenceStore.fetchAllEvidences();
    evidLength.value = evidsNow.length;
    for (const item of evidItems.Evidence) {
        delEvidId.value.push(item.evidId);
        const response = await evidenceStore.deleteEvidence(item.evidId);
        if (!needReload.value && !response.success) { needReload.value = true }
    }
    if (!needReload.value)  {pollingEvid.value = true; }
};

const removeEvidFromSelectedCase = (evidId) => {
    selectedCase.value.Evidence = selectedCase.value.Evidence.filter(item => item.evidId !== evidId);
};

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

async function pollingEvidencesFromStore() {
    const maxAttempts = 20;
    let attempt = 0;

    while (attempt < maxAttempts) {
        const _evids = await evidenceStore.fetchAllEvidences();
        const currentLen = _evids.length;
        console.log(`[${attempt}] current: ${currentLen} | prev: ${evidLength.value}`);
        if (currentLen < evidLength.value) {
            pollingEvid.value = false;
            evidLength.value = 0;
            console.log(`end of polling`);
            notificationStore.addNew('success', 'Remove evidence', `For case ${selectedCase.value.caseName}.`);
            break;
        } else {
            await sleep(1000);
            attempt++;
        }
    }
};

watch(() => pollingEvid.value, () => {
    if (pollingEvid.value) {
        pollingEvidencesFromStore();
    } else {
        console.log(`remove evidence object from selected case object`);
        if (delEvidId.value) {
            for (const evidId of delEvidId.value) {
                removeEvidFromSelectedCase(evidId);
            }
            delEvidId.value = [];
        }
    }
})
</script>