<template>
    <div class="pfp" :style="{ background: backgroundColor }">
        {{ pfp(name) }}
    </div>
</template>

<script setup lang="js">
import { computed } from 'vue';
const props = defineProps({
    name: {
        type: String,
        required: true,
        default: "Unknown"
    },
    isAdmin: {
        type: Boolean,
        required: false
    }
});
// Define colors for each letter
const colorMap = {
    'A': 'var(--blue-500)',
    'B': 'var(--blue-500)',
    'C': 'var(--primary-500)',
    'D': 'var(--primary-500)',
    'E': 'var(--green-500)',
    'F': 'var(--green-500)',
    'G': 'var(--lime-500)',
    'H': 'var(--lime-500)',
    'I': 'var(--blue-500)',
    'J': 'var(--blue-500)',
    'K': 'var(--primary-500)',
    'L': 'var(--primary-500)',
    'M': 'var(--green-500)',
    'N': 'var(--green-500)',
    'O': 'var(--lime-500)',
    'P': 'var(--lime-500)',
    'Q': 'var(--blue-500)',
    'R': 'var(--blue-500)',
    'S': 'var(--primary-500)',
    'T': 'var(--primary-500)',
    'U': 'var(--green-500)',
    'V': 'var(--green-500)',
    'W': 'var(--lime-500)',
    'X': 'var(--lime-500)',
    'Y': 'var(--blue-500)',
    'Z': 'var(--blue-500)'
};

// Function to get initials from the name
const pfp = (name) => {
    const space = name.indexOf(' ');
    if (space !== -1) {
        const charAfterSpace = name.charAt(space + 1);
        return `${name.substring(0, 1)}${charAfterSpace}`
    }
    return `${name.substring(0, 1)}`
}

// Function to get the color based on the starting letter
const backgroundColor = computed(() => {
    if (props.isAdmin) {
        return "var(--red-500)";
    }
    const firstLetter = props.name.charAt(0).toUpperCase();
    return colorMap[firstLetter] || 'var(--grey-500)'; 
})

</script>
<style scoped>
.pfp {
    width: 30px;
    height: 30px;
    background: black;
    border-radius: 50px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8125rem;
    font-weight: 700;
    text-transform: uppercase;
}
</style>