// backend: v0.0.6
class evidenceParser {

    constructor(caseItem, resultArray) {
        this.caseItem = caseItem;
        this.resultArray = resultArray;
    }

    // general helpers
    compareTimeStamp(timeStampOne, timeStampTwo) {
        const date1 = new Date(timeStampOne);
        const date2 = new Date(timeStampTwo);
        if (date1 >= date2) {
            return timeStampOne
        } else {
            return timeStampTwo
        }
    }

    sortItemByDate(items, dateKey, latestFirst=true) {
        return items.sort((a, b) => {
            const dateA = new Date(a[dateKey]);
            const dateB = new Date(b[dateKey]);
            return latestFirst ? dateB - dateA : dateA - dateB;
        })
    }

    getEvidItemState(evidItem) {
        let state = 'unknown';
        if (evidItem.FilecrawlingProcess?.state === "success" && !evidItem.ProcessingProcess) {
            state = 'ready';
        } else if (!evidItem.ProcessingProcess && (evidItem.FilecrawlingProcess?.state === "running" || evidItem.FilecrawlingProcess?.state === 'queued')) {
            state = 'initialize (file crawler)';
        } else if (!evidItem.ProcessingProcess && evidItem.FilecrawlingProcess?.state === 'failed') {
            state = 'file-crawler-failed'
        } else if (evidItem.ProcessingProcess?.state === "running" || evidItem.ProcessingProcess?.state === "queued") {
            state = 'running';
        } else if (evidItem.ProcessingProcess?.state === "success") {
            state = 'success';
        } else if (evidItem.ProcessingProcess?.state === 'failed') {
            state = 'failed';
        }
        return state;
    }

    getProcessingIdArray(evidItems) {
        const uniqueIds = new Set();
        for (const item of evidItems) {
            if (item.ProcessingProcessId) {
                uniqueIds.add(item.ProcessingProcessId);
            }
        }
        return Array.from(uniqueIds);
    }

    // main helpers
    getProcessedEvidArray(processId, evidItems) {
        let _evidenceArray = []
        evidItems.forEach(evidItem => {
            const processingProcessId = evidItem.ProcessingProcessId;
            if (processingProcessId === processId) {
                const evidState = this.getEvidItemState(evidItem)
                const sortedEvidItem = this.generateEvidenceItem(evidItem, evidState)
                _evidenceArray.push(sortedEvidItem)
            }
        })
        return _evidenceArray;
    }

    getProcessingResult(processId) {

        let _fileSuccess = 0;
        let _fileFailed = 0;
        let _msgSuccess = 0;
        let _msgFailed = 0;

        let _filePricePerUnit = 0;
        let _msgPricePerUnit = 0;
        let _fileUpdatedAt = "";
        let _msgUpdatedAt = "";
        let _processMsgCost = 0;
        let _processFileCost = 0;

        this.resultArray.forEach(resultItem => {
            if (resultItem.AirflowProcessId === processId) {
                if (resultItem.BillingType.step_name === 'count_files_content') {
                    if (resultItem.state === 'success') {
                        _fileSuccess = resultItem.amount;
                        _fileUpdatedAt = resultItem.updatedAt;
                        _filePricePerUnit = resultItem.BillingType.price_per_unit;
                        _processFileCost += Number((_fileSuccess * _filePricePerUnit / 10000).toFixed(2));
                    } else if (resultItem.state === 'failed') {
                        _fileFailed = resultItem.amount;
                    } 
                } else if (resultItem.BillingType.step_name === 'count_messages') {
                    if (resultItem.state === 'success') {
                        _msgSuccess = resultItem.amount;
                        _msgPricePerUnit = resultItem.BillingType.price_per_unit;
                        _msgUpdatedAt = resultItem.updatedAt;
                        _processMsgCost += Number((_msgSuccess * _msgPricePerUnit / 10000).toFixed(2));
                    } else if (resultItem.state === 'failed') {
                        _msgFailed = resultItem.amount
                    }
                }
            }
        })
        const _totalCost = _processMsgCost + _processFileCost;
        return {
            fileSuccess: _fileSuccess,
            fileFailed: _fileFailed,
            msgSuccess: _msgSuccess,
            msgFailed: _msgFailed,
            filePricePerUnit: _filePricePerUnit,
            msgPricePerUnit: _msgPricePerUnit,
            fileUpdatedAt: _fileUpdatedAt,
            msgUpdatedAt: _msgUpdatedAt,
            totalCost: _totalCost.toFixed(2),
            updatedAt: this.compareTimeStamp(_fileUpdatedAt, _msgUpdatedAt)
        }
    }

    getSortedProcesses(evidItems) {
        const uniqueProcessIds = this.getProcessingIdArray(evidItems);
        const _processDetails = [];
        uniqueProcessIds.forEach(_processId => {
            const _evidItems = this.getProcessedEvidArray(_processId, evidItems);
            const _resultItem = this.getProcessingResult(_processId);
            const processDetail = this.generateProcessDetailItem(
                _processId, _evidItems, _resultItem
            );
            _processDetails.push(processDetail);
        })
        return _processDetails
    }

    // data structure
    generateSortedResult(uploadItems, fileCrawlerFailedItems, inProgressItems, finishedItems, unknownItems, exceptionItems) {
        const result = {
            upload: {
                type: "upload",
                Evidence: uploadItems
            },
            fileCrawlerFailed: {
                type: "file-crawler-failed",
                Evidence: fileCrawlerFailedItems
            },
            inProgress: {
                type: "inProgress",
                Evidence: inProgressItems
            },
            finished: this.sortItemByDate(finishedItems, 'updatedAt', true),
            unknown: { type: "unknown", Evidence: unknownItems },
            exception: { type: "exception", Evidence: exceptionItems }
        }
        return result
    }

    generateProcessDetailItem(processId, evidenceArray, resultItem) {
        const processDetail = {
            type: "finished",
            ProcessingProcessId: processId,
            Evidence: evidenceArray,
            fileSuccess: resultItem.fileSuccess,
            fileFailed: resultItem.fileFailed,
            msgSuccess: resultItem.msgSuccess,
            msgFailed: resultItem.msgFailed,
            filePricePerUnit: resultItem.filePricePerUnit,
            msgPricePerUnit: resultItem.msgPricePerUnit,
            fileUpdatedAt: resultItem.fileUpdatedAt,
            msgUpdatedAt: resultItem.msgUpdatedAt,
            updatedAt: resultItem.updatedAt,
            totalCost: resultItem.totalCost
        };
        return processDetail
    }

    generateEvidenceItem(evidItem, state) {
        const sortedEvidItem = {
            evidId: evidItem.evidId,
            fileName: evidItem.fileName,
            evidenceNumber: evidItem.evidenceNumber,
            uniqueDescription: evidItem.uniqueDescription,
            notes: evidItem.notes,
            updatedAt: evidItem.updatedAt,
            createdAt: evidItem.createdAt,
            size: evidItem.meta.upload.size,
            state: state
        }
        return sortedEvidItem
    }

    // main
    sort() {
        let upload = [];
        let fileCrawlerFailed = [];
        let inProgress = [];
        let finished = [];
        let unknown = [];
        let exception = [];

        this.caseItem.Evidence?.forEach(evidItem => {
            // sort evidence after state: file-crawler-init / file-crawler-failed / ready / queued / running / success / failed
            const evidState = this.getEvidItemState(evidItem);
            if (evidState === 'ready' || evidState === 'initialize (file crawler)') {
                upload.push(this.generateEvidenceItem(evidItem, evidState));
            } else if (evidState === 'running') {
                inProgress.push(this.generateEvidenceItem(evidItem, evidState));
            } else if (evidState === 'success' || evidState === 'failed') {
                finished.push(evidItem);
            } else if (evidState === 'unknown') {
                unknown.push(evidItem);
            } else if (evidState === 'file-crawler-failed') {
                fileCrawlerFailed.push(this.generateEvidenceItem(evidItem, evidState))
            }
            else {
                exception.push(evidItem);
            }
        });
        const processDetails = this.getSortedProcesses(finished);
        return this.generateSortedResult(upload, fileCrawlerFailed, inProgress, processDetails, unknown, exception);
    }

    calculateCostPerCase() {
        let costPerCase = 0;
        if (this.resultArray.length > 0) {
            this.resultArray.forEach(resultItem => {
                const _price = (resultItem.amount * resultItem.BillingType.price_per_unit / 10000).toFixed(2);
                costPerCase += Number(_price);
            })
        }
        return costPerCase.toFixed(2);
    }

    countMsgAndfilesPerCase() {
        let messagesSuccess = 0;
        let messagesFailed = 0;
        let filesSuccess = 0;
        let filesFailed = 0;
    
        if (this.resultArray.length > 0) {
            this.resultArray.forEach(resultItem => {
                if (resultItem.BillingType.step_name === 'count_files_content') {
                    if (resultItem.state === 'success') {
                        filesSuccess += resultItem.amount;
                    } else if (resultItem.state === 'failed') {
                        filesFailed += resultItem.amount;
                    }
                } else if (resultItem.BillingType.step_name === 'count_messages') {
                    if (resultItem.state === 'success') {
                        messagesSuccess += resultItem.amount;
                    } else if (resultItem.state === 'failed') {
                        messagesFailed += resultItem.amount;
                    }
                }
            });
        }
    
        return {
            messagesSuccess,
            messagesFailed,
            filesSuccess,
            filesFailed
        };
    }

}


export default evidenceParser;