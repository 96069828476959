<style scoped>
.cT {}

.pcT {
    color: rgb(94, 97, 101);
    margin: 0;
    font-size: 0.8125rem;
    font-weight: 500;
}

.cT .header {
    display: flex;
    border-bottom: solid 1px #00000010;
    margin-top: 25px;
}

.cT .header p {
    margin: 0;
    font-size: 0.8125rem;
    font-weight: 500;
}

.cT .header p.case {
    flex: 0 0 40%;
    font-weight: 700;
}

.cT .header p:not(.case) {
    flex: 1;
}

.cT .item {
    cursor: pointer;
    display: flex;
    border-bottom: solid 1px #00000010;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.cT .item:hover {
    background: rgba(0, 0, 0, 0.042) !important;
}

.cT .item section {
    color: rgb(94, 97, 101);
    margin: 0;
    font-size: 0.8125rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
}

.cT .item section.case {
    flex: 0 0 40%;
    gap: 15px !important;
}

.cT .item section:not(.case) {
    flex: 1;
}

.cT .item section.flag {
    width: 100px;
}

.icon-size {
    width: 14px;
    height: 23px;
}

.redflag {
    background: #ff000003 !important;
}


.circular-progress {
    --size: 30px;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 3px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * pi * 2);
    --dash: calc((var(--progress) * var(--circumference)) / 100);
}

.circular-progress circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: round;
}

.circular-progress circle.bg {
    stroke: #ddd;
}

.circular-progress circle.fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    transition: stroke-dasharray 0.3s linear 0s;
    stroke: var(--primary-500);
}
</style>

<template>
    <div class="cT">
        <div class="header p-2">
            <p class="case">Case</p>
            <p>Cost</p>
            <p>Lead</p>
            <p>Date</p>
            <p>Status</p>
        </div>
        <div class="tabl-content">
            <div class="item p-2" v-for="caseItem in props.sortedCases" :key="caseItem.createdAt"
                @click="navigateToAddEvidence(caseItem)"
                :class="{ 'redflag': !caseItem.DbInitProcess || caseItem.DbInitProcess?.state === 'failed', '': caseItem.DeleteCaseProcessId }">
                <section class="case">
                    <span>
                        <ExclamationWarningIcon
                            v-if="!caseItem.DbInitProcess || caseItem.DbInitProcess?.state === 'failed'"
                            class="icon-size-18px" style="color: var(--red-500);" />

                        <InboxIcon v-else class="icon-size-18px" />
                    </span>
                    <span style="color:black;">{{ caseItem.caseName }}</span>
                </section>
                <section>
                    <span class="icon-size">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                            <path fill-rule="evenodd"
                                d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0ZM6.875 6c.09-.22.195-.42.31-.598.413-.638.895-.902 1.315-.902.264 0 .54.1.814.325a.75.75 0 1 0 .953-1.158C9.772 3.259 9.169 3 8.5 3c-1.099 0-1.992.687-2.574 1.587A5.518 5.518 0 0 0 5.285 6H4.75a.75.75 0 0 0 0 1.5h.267a7.372 7.372 0 0 0 0 1H4.75a.75.75 0 0 0 0 1.5h.535c.156.52.372.998.64 1.413C6.509 12.313 7.402 13 8.5 13c.669 0 1.272-.26 1.767-.667a.75.75 0 0 0-.953-1.158c-.275.226-.55.325-.814.325-.42 0-.902-.264-1.315-.902a3.722 3.722 0 0 1-.31-.598H8.25a.75.75 0 0 0 0-1.5H6.521a5.854 5.854 0 0 1 0-1H8.25a.75.75 0 0 0 0-1.5H6.875Z"
                                clip-rule="evenodd" />
                        </svg>
                    </span>
                    {{ formatNumber(caseItem.cost) || 'Calculating...' }}€
                </section>
                <section class="flag">
                    <span class="icon-size">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                            <path fill-rule="evenodd"
                                d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0Zm-5-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM8 9c-1.825 0-3.422.977-4.295 2.437A5.49 5.49 0 0 0 8 13.5a5.49 5.49 0 0 0 4.294-2.063A4.997 4.997 0 0 0 8 9Z"
                                clip-rule="evenodd" />
                        </svg>
                    </span>
                    <span class="text-truncate">{{ caseItem.responsiblePerson }}</span>
                </section>
                <section class="flag">
                    <span class="icon-size">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                            <path
                                d="M5.75 7.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM7.25 8.25A.75.75 0 0 1 8 7.5h2.25a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75ZM5.75 9.5a.75.75 0 0 0 0 1.5H8a.75.75 0 0 0 0-1.5H5.75Z" />
                            <path fill-rule="evenodd"
                                d="M4.75 1a.75.75 0 0 0-.75.75V3a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2V1.75a.75.75 0 0 0-1.5 0V3h-5V1.75A.75.75 0 0 0 4.75 1ZM3.5 7a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4.5a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V7Z"
                                clip-rule="evenodd" />
                        </svg>
                    </span>
                    <TimeFormat :dateString="caseItem.createdAt" :showTime="true" />
                </section>
                <section class="flag">
                    <div v-if="caseItem.Evidence?.length != 0 && !caseItem.DeleteCaseProcessId"
                        class="d-flex align-items-center gap-2 align-items-start">
                        <div class="progress-circle-container">
                            <svg width="25" height="25" viewBox="0 0 30 30" class="circular-progress"
                                :style="{ '--progress': getEvidenceProgress(caseItem) }">
                                <circle class="bg" cx="50" cy="50" r="45"></circle>
                                <circle class="fg" cx="50" cy="50" r="45"></circle>
                            </svg>
                        </div>
                        <p class="m-0 pcT">
                            {{ getEvidenceProgress(caseItem) }}%
                        </p>
                    </div>
                    <!-- deleting case / failed case -->
                    <!-- TODO: not displayed -->
                    <div v-else-if="caseItem.DeleteCaseProcessId">
                        <span class="status-removing" style="">
                            <svg data-v-0b6eab7f="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke-width="1.5" stroke="currentColor"
                                class="size-6 spin-right-round icon-size-15px color-grey-400">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99">
                                </path>
                            </svg>
                            Pending removal</span>
                    </div>
                    <div v-else-if="!caseItem.DbInitProcess || caseItem.DbInitProcess?.state === 'failed'"
                        class="color-red-500">
                        <span>Case not created</span>
                    </div>
                </section>
            </div>
        </div>
    </div>

    <div v-if="props.sortedCases.length === 0 && !props.loading">
        <p class="text-sm mt-2">No cases found matching your description</p>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { formatNumber } from '@/_helpers/helpers.js';
import TimeFormat from '@/components/widgets/TimeFormat.vue';
import ExclamationWarningIcon from '@/components/icons/ExclamationWarningIcon.vue'
import InboxIcon from '@/components/icons/InboxIcon.vue'

const router = useRouter();

const props = defineProps({
    sortedCases: {
        type: Array,
        required: true
    },
    loading: {
        type: Boolean,
        required: true
    },
});

const navigateToAddEvidence = (caseItem) => {
    const id = caseItem.caseId;
    if (!caseItem.DeleteCaseProcessId) {
        router.push(`/new-evidence/${id}`);
    }
};

// TODO: process failed red progress bar
const getEvidenceProgress = (caseItem) => {
    const totalEvidence = caseItem.Evidence?.length || 0;
    const processedEvidence = caseItem.Evidence?.filter(ev => ev.ProcessingProcess?.state === 'success' || ev.ProcessingProcess?.state === 'failed').length || 0;
    return totalEvidence ? Math.round((processedEvidence / totalEvidence) * 100) : 0;
};
</script>