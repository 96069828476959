import { useAuthStore } from "../store/auth.store";
import { createRouter, createWebHistory } from "vue-router";
// components
import Dashboard from "../views/Dashboard.vue";

import SignIn from "../views/SignIn.vue";
import NewUser from "../views/NewUser.vue";
// import BackendApiDemo from "../views/BackendApiDemo.vue";

import CaseOverview from '../views/CaseOverview.vue';

import Basic from "../components/reset/Basic.vue";
import Error404 from "../components/error/Error404.vue";

import AddEvids from "@/views/AddEvids.vue";
import UserControl from "../views/UserControl.vue";

import BlockDisplay from '@/components/BlockDisplay.vue';


const routes = [{
        path: "/",
        name: "/",
        redirect: "/dashboard",
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
    },
    {
        path: "/signin",
        name: "Sign in",
        component: SignIn,
    },
    {
        path: "/case-overview/:source?",
        name: "Case Overview",
        component: CaseOverview,
        props: true
    },
    {
        path: '/new-evidence/:caseId',
        name: "Case Information",
        component: AddEvids,
        props: true
    },
    {
        path: "/change-password/:source?",
        name: "Change Password",
        component: Basic,
        props: true
    },
    {
        path: "/user-control",
        name: "User Management",
        component: UserControl
    },
    {
        path: "/new-user/:source?",
        name: "New User",
        component: NewUser,
        props: true
    },
    {
        path: '/:patchMatch(.*)*',
        component: Error404,
    },
    {
        path: '/dev-block',
        component: BlockDisplay
    }
];

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes,
    linkActiveClass: "active",
});


router.beforeEach(async(to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/signin'];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();

    // already logged in
    if (auth.user && to.path == '/signin') {
        return '/';
    }

    // redirect to signin if not authenticated
    if (authRequired && !auth.user) {
        auth.returnUrl = to.fullPath;
        return '/signin';
    }
});

export default router;