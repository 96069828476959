<template>
    <!-- select file alert -->
    <Modal class="z-index-highest" :isOpen="isFileAlertOpen"  :title="fileAlertTitle" status="alert">
        <div class="d-flex flex-column align-items-center w-100 px-3">
            <span>{{ fileAlertMsg }}</span>
            <button class="cbtn-sm mt-4 w-100 color-white bg-primary-700 hover-bg-primary-600" @click="handleUploadAlert">Select file</button>
        </div>
    </Modal>
    <!-- step 2: main content -->
    <!-- TODO: after file selection the upload-box expanded -->
    <!-- Define `style="height: ???rem!important"` Delete unnecessary padding / margin -->
    <div style="margin-top: 20px;">
    <div class="upload-box d-flex flex-column justify-content-center bg-white border-radius-0-5-rem flex-grow-1 h-100 m-0 pb-3"
        :class="{ 'pt-3': !newEvidForm.fileName }" draggable="true"
        @dragover.prevent @dragenter.prevent @drop.prevent="handleFileDrop">

        <div class="w-100 d-flex flex-column align-items-center justify-content-end mt-1" style="height: 75%!important;">
            <div class="d-flex flex-column align-items-center">
                <p class="m-0" style="font-size: 0.8125rem;padding-top: 10px;" v-if="newEvidForm.fileName">
                    <strong>Selected file: </strong>
                    <span class="text-primary color-grey-700" style="font-size: 0.8125rem">{{ newEvidForm.fileName }}</span>
                </p>
                <upload-cloud class="icon-holder-upload" v-if="!newEvidForm.fileName" />
                <check class="stroke-success" style="width: 3.34rem; height: 3.34rem;" v-else />
            </div>
            <div class="d-flex flex-column align-items-center mt-1">
                <div class="d-flex align-items-center gap-1">
                    <span class="cursor-default" style="font-size: 0.9rem;"> Drag & Drop or</span>
                    <button class="newbtn bg-white"
                        :class="newEvidForm.fileName ? 'bg-success' : 'bg-red'" style="min-width: 5.89rem"
                        @click.prevent="triggerFileInput">
                        <span v-if="!newEvidForm.fileName">Browse</span>
                        <span v-else>Change</span> file
                    </button>
                </div>
                <p class="text-xs m-0 mt-2">Allowed compressed file format: .zip, .e01, .ad1</p>
                <input type="file" id="upload-input" @change="onFileChange" style="display:none"
                    accept=".zip, .e01, .ad1">
            </div>
        </div>
    </div>
    </div>
    <div class="d-flex justify-content-between mt-3">
        <button class="newbtn bg-white border font-weight-700" @click="prevStep">
            <span>Go back</span>
        </button>
        <button class="newbtn font-weight-700 color-white"
            :class="unlockSummary ? 'bg-primary-700' : 'bg-light-gray'" @click="nextStep">
            <span>Next step</span>
        </button>
    </div>
    
</template>

<script setup>
import { toRefs, watchEffect, ref, computed } from 'vue';
import { useStore } from "vuex";
// handleUpload,
import { triggerFileInput } from '@/_helpers/upload.js';
// icon
import UploadCloud from '@/components/icons/UploadCloud.vue';
import Check from '@/components/icons/Check.vue';
// 
import Modal from '@/components/widgets/modal/Modal.vue';

const props = defineProps({
    newEvidForm: {
        type: Object,
        required: true,
    }
})
const emit = defineEmits(['handle-selection']);
const { newEvidForm } = toRefs(props);

const store = useStore();

const warning = ref(false);
const isFileAlertOpen = ref(false);

const fileAlertTitle = ref('');
const fileAlertMsg = ref('');

const unlockSummary = computed(() => {
    return newEvidForm.value.file !== null && newEvidForm.value.fileName !== '';
});

function onFileChange(event) {
    // `file` is a `File Object`. It can't be printed by using `JSON.stringify`
    // cause some binary content and internal properties **can't be serialized** by JSON
    const file = event.target.files[0];
    if ( file ) {
        const allowedTypes = ['application/zip', 'application/x-zip-compressed', 'application/octet-stream'];
        if (!allowedTypes.includes(file.type) && !['.zip', '.e01', '.ad1'].some(ext => file.name.endsWith(ext))) {
            const modalInput = 'wrong-format';
            openSelectFileAlert(modalInput);
        } else {
            emit('handle-selection', file);
        }
    };
};


const handleFileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log("files length:" + files.length)
    if (files.length !== 1) {
        openSelectFileAlert('multiple');
    } else {
        onFileChange({ target: { files: files } });
    }
};

// handle warning
watchEffect(() => {
    if (warning.value) {
        openSelectFileAlert('no-file');
    }
});

function handleUploadAlert() {
    triggerFileInput();
    isFileAlertOpen.value = false;
};

function openSelectFileAlert(alertType) {
    console.log(`[dev] upload wizard: ${alertType}`);
    if ( alertType === 'wrong-format' ) {
        fileAlertTitle.value = 'Wrong file format';
        fileAlertMsg.value = 'Please upload file in following format: .zip, .e01 or .ad1.'
    } else if ( alertType === 'multiple' ) {
        fileAlertTitle.value = 'Multiple files are selected.'
        fileAlertMsg.value = 'Per evidence can take only one upload file.';
    } else if ( alertType === 'no-file' ) {
        fileAlertTitle.value = 'No evidence file is selected.';
        fileAlertMsg.value = 'Please click select file button.';
    }
    isFileAlertOpen.value = true;
};

// workflow
const prevStep = () => {
    store.dispatch('changeEvidenceStep', 0);
};

const nextStep = () => {
    if (unlockSummary.value) {
        store.dispatch('changeEvidenceStep', 2);
        // console.log(`ready for 3 in step 2: ${JSON.stringify(props.newEvidForm)}`);
    } else {
        openSelectFileAlert('no-file');
    }
}
</script>

<style scoped>
.upload-box {
    outline: 1px dashed #c8c8c8;
    transition: outline-offset .15s ease-in-out, background-color .15s, linear;
    border-radius: .5rem;
}

.icon-holder-upload {
    border-radius: 50%;
    width: 4.34rem;
    height: 4.77rem;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: var(--grey-600);
    padding: 12px;
}
</style>