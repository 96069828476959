<template>
    <div class="icon-wrapper relative" @click="toggleModal($event)">
        <slot name="icon"></slot>
        <div v-if="isModalOpen" class="qModal-overlay d-flex flex-column" @click="toggleModal($event)" ref="modalRef">
            <div class="qModal-content" @click.stop>
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script setup lang="js">
import { ref, onMounted, onUnmounted } from 'vue';

const isModalOpen = ref(false);

const toggleModal = (event) => {
    if (event) {
        event.stopPropagation();
    }
    isModalOpen.value = !isModalOpen.value;
};

const modalRef = ref(null);

const handleOutsideClick = (event) => {
    if (modalRef.value && !modalRef.value.contains(event.target) && isModalOpen.value) {
        toggleModal();
    }
};

onMounted(() => {
    window.addEventListener('click', handleOutsideClick);
});

onUnmounted(() => {
    window.removeEventListener('click', handleOutsideClick);
});
</script>

<style scoped>
.icon-wrapper {
    position: relative;
    display: inline-block;
}

.qModal-overlay {
    position: absolute;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    width: max-content;
    margin-top: 10px;
}

.qModal-content {
    background: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    border: solid 1px #00000026;
    max-height: 400px;
    overflow: auto;
}
</style>