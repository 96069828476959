<template>
    <SideModal currentview="Case" :isOpen="isEvidenceModalOpen"
        @close="resetNewEvidSideModal"
        :title="evidModalTitle" :desc="evidModalDesc">
        <StepOne v-if="currentStep === 0" 
            :new-evid-form="newEvidForm" @close="resetNewEvidSideModal" class="mt-4" />
        <StepTwo v-else-if="currentStep === 1" 
            :new-evid-form="newEvidForm"
            @handle-selection="handleFileSelection" />
        <StepThree v-else-if="currentStep === 2" 
            :new-evid-form="newEvidForm" :caseId="props.caseId" :caseName="props.caseName"
            @reset="resetNewEvidSideModal" />
        
        <!--error handling reset curretnStep-->
        <div class="d-flex flex-column" v-else>
            <span class="mt-3">Please click reset button and try it again.</span>
            <button class="mt-3" @click="store.dispatch('changeEvidenceStep', 0)">reset</button>
        </div>

    </SideModal>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
// components
import SideModal from '@/components/widgets/SideModal.vue';
// grand child components
import StepOne from './NewEvidSteps/StepOneMeta.vue';
import StepTwo from './NewEvidSteps/StepTwoSelectFile.vue';
import StepThree from './NewEvidSteps/StepThreeSubmit.vue';

const props = defineProps({
    caseId: {
        type: String,
        required: true
    },
    caseName: {
        type: String,
        required: true
    }
});
const store = useStore();
const isEvidenceModalOpen = computed(() => store.state.isEvidenceModalOpen);
const currentStep = computed(() => store.state.newEvidenceStep);

const newEvidForm = ref({
    fileName: "",
    file: {},
    evidenceNumber: "",
    uniqueDescription: "",
    notes: ""
});

// Load state from local storage or use initial props
function loadInitialState() {
    const savedForm = localStorage.getItem('newEvidForm');
    if (savedForm) {
        newEvidForm.value = JSON.parse(savedForm);
    } else {
        newEvidForm.value.caseId = props.caseId;
        newEvidForm.value.caseName = props.caseName;
    }
}

onMounted(loadInitialState);

function resetNewEvidSideModal() {
    newEvidForm.value.fileName = "";
    newEvidForm.value.file = null;
    newEvidForm.value.evidenceNumber = "";
    newEvidForm.value.uniqueDescription = "";
    newEvidForm.value.notes = "";
    // newEvidForm.value.caseId = props.caseId;
    // newEvidForm.value.caseName = props.caseName;
    store.dispatch('closeEvidenceModal');
    // close side modal animation 0.5s
    // css: .side-modal (transition)
    setTimeout(() => {
        store.dispatch('changeEvidenceStep', 0);
    }, 510)

};

const evidModalTitle = computed(() => {
    switch (currentStep.value) {
        case 0:
            return "Upload Evidence";
        case 1:
            return "Select File";
        case 2:
            return "Verify Your Evidence Detail";
        default:
            return "Upload Evidence"
    }
});
const evidModalDesc = computed(() => {
    switch (currentStep.value) {
        case 0:
            return "Please provide the evidence detail";
        case 1:
            return "Please select one file or drag the file to uploading area";
        case 2:
            return "Please verify the evidence metadata and files before uploading";
        default:
            return "Follow the instructions";
    }
});

// functions
const handleFileSelection = (file) => {
    newEvidForm.value.file = file;
    newEvidForm.value.fileName = file.name;
};
</script>