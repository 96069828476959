<style scoped>
.uC {

}

.uC .dirnav {
    padding-top: 10px;
    border-right: solid 1px var(--grey-200);
}

</style>
<template>
    <!-- user control tutorial -->
    <TutorialSteps @handle-tutorial-step="handleTutorialStep" />
    
    <!-- MAIN CONTENT -->
    <div class="uC d-flex flex-grow-1 gap-2 realign-directory">
        <!-- main right -->
        <div class="dirnav mobile-padding" style="flex-basis: 30%;">
            <UserList :filteredUsers="filteredUsers" 
                :searchQuery="searchQuery" :loading="loading" :userId="selectedUserId" :focusedId="focusedUserId"
                @update:searchQuery="searchQuery = $event" @handle-new-user="handleNewUser"
                @handle-change-pw="handleChangePw" @select-user="selectUser" @open-remove-user-modal="openRemoveUserModal" />
        </div>
        <!-- main left -->
        <div class="p-2" 
            style="flex-basis: 70%;">
            <UserDetails :loading="loading" :userItem="currentUserDetail" @refresh="refetchUserItem" ref="userDetailsRef" />
        </div>
    </div>
    <!-- modals -->
    <RemoveUser :openStepOne="showRemoveUserModal" :removeUserItem="removeUserItem"
        @close-remove-user-modal="closeRemoveUserModal" @confirm-remove-user="confirmRemoveUser" />
    <UnsavedCase :openStepOne="showUnsavedCaseModal" :openStepTwo="showUnsavedCaseModalConfirm"
        @abort-asigned-case-changes="abortAsignedCaseChanges" @save-asigned-case-changes="saveAsignedCaseChanges" @close-unsaved-case-modal-confirm="closeUnsavedCaseModalConfirm" />
    <UserChangePw :isOpen="showUserChangePw" :userItem="targetUserItemForChangePw" @close="closeChangePw" />

</template>

<script setup>
import router from '@/router';
import { computed, watch, ref, onMounted } from 'vue';
import { useStore } from "vuex";
import { useUserStore } from '@/store/userStore';
import tutorialLandingStore from '@/store/frontend/tutorialLandingStore.js';
// components
import UserDetails from '@/components/user_control/UserDetails.vue';
import TutorialSteps from '@/components/user_control/TutorialSteps.vue';
import UserList from '@/components/user_control/UserList.vue';
// modals
import RemoveUser from '@/components/user_control/_modals/RemoveUser.vue';
import UnsavedCase from '@/components/user_control/_modals/UnsavedCase.vue';
import UserChangePw from '@/components/user_control/_modals/UserChangePw.vue';

const store = useStore();
const userStore = useUserStore();

const searchQuery = ref('');
const selectedUserId = ref('');
const currentUserDetail = ref({});
const nextSelectedUserItem = ref(null);
const loading = ref(true);
const userDetailsRef = ref(null);
// modal control
const showRemoveUserModal = ref(false);
const removeUserItem = ref({});
const showUnsavedCaseModal = ref(false);
const showUnsavedCaseModalConfirm = ref(false);
const showUserChangePw = ref(false);
const targetUserItemForChangePw = ref({});
// tutorial steps
const { tutorStep, landingStep, setTutorialStep, setRestartTutor } = tutorialLandingStore;

const _checkStoreConnection = (isConnected) => {
    loading.value = !isConnected;
    if ( isConnected ) {
        setDefaultUser();
    }
};

onMounted(() => {
    _checkStoreConnection(userStore.connected);
});

watch(() => userStore.connected, (connected) => {
    _checkStoreConnection(connected);
});

// computed
const users = computed(() => userStore.users.map((user, index) => ({
    userId: user.userId,
    isLast: index === userStore.users.length - 1,
    username: user.name,
    is_admin: user.is_admin
})));

const filteredUsers = computed(() => {
    const query = searchQuery.value.toLowerCase();
    return users.value.filter(user => user.username.toLowerCase().includes(query));
});

const focusedUserId = computed(() => store.state.userControlFocus);

// methods
const handleNewUser = () => {
    if (tutorStep.value === 1) {
        router.push('/new-user/tutor');
        setTutorialStep(2);
    } else {
        router.push('/new-user')
    }
};

const selectUser = (user) => {
    // handle unsaved asigned case
    if (user.userId !== selectedUserId.value) {
        nextSelectedUserItem.value = user;
        if (focusedUserId.value !== '' && focusedUserId.value !== user.userId) {
            openUnsavedCaseModal(user);
        } else {
            _toAnoutherUser(user);
        }
    };
    // handle tutorial
    if ( tutorStep.value === 2 ) {
        if ( landingStep.value === 1 ) {
            setTutorialStep(4)
        } else {
            setTutorialStep(3);
        }
    } 
};

const _toAnoutherUser = async(user) => {
    selectedUserId.value = user.userId;
    currentUserDetail.value =  await userStore.fetchUserById(selectedUserId.value);
    store.state.userControlFocus = '';
    // console.log(`[dev][_toAnoutherUser]: ${user.userId} | ${JSON.stringify(user)} | ${user}`);
    // console.log(`[dev][_toAnoutherUser]: ${JSON.stringify(currentUserDetail.value)}`);
};

const setDefaultUser = () => {
    const adminUser = userStore.loggedInUser;
    // console.log(`default admin user: ${JSON.stringify(adminUser)}`);
    // tutorial mode
    if ( tutorStep.value === 2 || tutorStep.value === 3 ) {
        if (users.value.length > 1) {
            _toAnoutherUser(users.value[1]);
        } else {
            _toAnoutherUser(adminUser);
        }
    } else {
        _toAnoutherUser(adminUser);
    }
};

const refetchUserItem = async() => {
    currentUserDetail.value =  await userStore.fetchUserById(selectedUserId.value);
};

// tutorial
const handleTutorialStep = () => {
    if ( tutorStep.value === 1 ) {
        handleNewUser();
    } else if ( tutorStep.value === 2 ) {
        const _currentUser = users.value.find(user => user.userId === selectedUserId.value);
        selectUser(_currentUser);
    } else if ( tutorStep.value === 3 ) {
        userStore.updateTutorialDone(true);
        setRestartTutor(false);
    }
};

// modal control
const handleChangePw = (user) => {
    if (user.is_admin) {
        router.push(`/change-password/user-control`);
    } else {
        showUserChangePw.value = true;
        targetUserItemForChangePw.value = user;
    }
};

const closeChangePw = () => {
    showUserChangePw.value = false;
};

const openRemoveUserModal = (user) => {
    showRemoveUserModal.value = true;
    removeUserItem.value = user;
};

const closeRemoveUserModal = () => {
    showRemoveUserModal.value = false;
};

const confirmRemoveUser = () => {
    showRemoveUserModal.value = false;
    userStore.deleteUser(removeUserItem.value.userId);
    setDefaultUser();
};

const openUnsavedCaseModal = () => {
    showUnsavedCaseModal.value = true;
};

const abortAsignedCaseChanges = () => {
    showUnsavedCaseModal.value = false;
    _toAnoutherUser(nextSelectedUserItem.value);
    nextSelectedUserItem.value = null;
};

const saveAsignedCaseChanges = () => {
    userDetailsRef.value.handleManageCaseSave();
    showUnsavedCaseModal.value = false;
};

const closeUnsavedCaseModalConfirm = () => {
    _toAnoutherUser(nextSelectedUserItem.value);
    nextSelectedUserItem.value = null;
    showUnsavedCaseModalConfirm.value = false;
};
</script>