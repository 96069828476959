import { createStore } from "vuex";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: false,
    showConfig: false,
    sidebarType: "bg-white",
    mcolor: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    showGTC: false,
    userControlFocus: '',
    isCaseModalOpen: false,
    newCaseStep: 0,
    isEvidenceModalOpen: false,
    newEvidenceStep: 0,
    isHelpModalOpen: false,
    displayLogOut: true,
    notifications: [],
    isProcessModalOpen: false,
  },
  mutations: {
    setCaseModalOpen(state, isOpen) {
      state.isCaseModalOpen = isOpen;
    },
    setEvidenceModalOpen(state, isOpen) {
      state.isEvidenceModalOpen = isOpen;
    },
    setProcessModalOpen(state, isOpen) {
      state.isProcessModalOpen = isOpen;
    },
    setHelpModalOpen(state, isOpen) {
      state.isHelpModalOpen = isOpen;
    },
    setShowGtc(state, isOpen) {
      state.showGTC = isOpen;
    },
    sidebarMinimize(state) {
      let sidenav_show = document.querySelector("#app");
      if (state.isPinned) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    goToCaseStep(state, payload) {
      state.newCaseStep = payload;
    },
    goToEvidenceStep(state, payload) {
      state.newEvidenceStep = payload;
    },
    ADD_NOTIFICATION(state, notification) {
      state.notifications.push(notification);
    },
    REMOVE_NOTIFICATION(state, index) {
      state.notifications.splice(index, 1);
    },
    MARK_ALL_AS_READ(state) {
      state.notifications.forEach(notification => {
        notification.read = true;
      });
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    openCaseModal({ commit }) {
      commit('setCaseModalOpen', true);
    },
    closeCaseModal({ commit }) {
      commit('setCaseModalOpen', false);
    },
    openEvidenceModal({ commit }) {
      commit('setEvidenceModalOpen', true);
    },
    closeEvidenceModal({ commit }) {
      commit('setEvidenceModalOpen', false);
    },
    openProcessModal({ commit }) {
      commit('setProcessModalOpen', true);
    },
    closeProcessModal({ commit }) {
      commit('setProcessModalOpen', false);
    },
    openGtc({ commit }) {
      commit('setShowGtc', true)
    },
    closeGtc({ commit }) {
      commit('setShowGtc', false)
    },
    changeCaseStep({ commit }, payload) {
      commit("goToCaseStep", payload);
    },
    changeEvidenceStep({ commit }, payload) {
      commit("goToEvidenceStep", payload);
    },
    openHelpModal({ commit }) {
      commit('setHelpModalOpen', true);
    },
    closeHelpModal({ commit }) {
      commit('setHelpModalOpen', false);
    },
    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification);
    },
    removeNotification({ commit }, index) {
      commit('REMOVE_NOTIFICATION', index);
    },
    markAllAsRead({ commit }) {
      commit('MARK_ALL_AS_READ');
    }
  },
  getters: {
    notifications: (state) => state.notifications,
    unreadNotifications: (state) => state.notifications.filter(n => !n.read)
  },
});

