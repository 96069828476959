<template>
    <div class="d-flex flex-column">
        <!-- loading statistic card -->
        <div style="height: 10.5vh;"
            class="d-flex gap-4 justify-content-between">
            <div class="table-skeleton w-25">
                <div class="row-black h-100" style="border-radius: 0.6rem!important"></div>
            </div>
            <div class="table-skeleton w-25 h-100">
                <div class="row-white h-100" style="border-radius: 0.6rem!important"></div>
            </div>
            <div class="table-skeleton w-25 h-100">
                <div class="row-black h-100" style="border-radius: 0.6rem!important"></div>
            </div>
            <div class="table-skeleton w-25 h-100">
                <div class="row-white h-100" style="border-radius: 0.6rem!important"></div>
            </div>
        </div>
        <!-- loading: storage / progress bars -->
        <div class="mt-4 d-flex flex-row gap-4 align-items-stretch"
            style="height: 26vh;">
            <div class="table-skeleton" style="flex-basis: 36.5%!important">
                <div class="row-white h-100" style="border-radius: 0.6rem!important"></div>
            </div>
            <div class="table-skeleton flex-grow-1">
                <div class="row-white h-100" style="border-radius: 0.6rem!important"></div>
            </div>
        </div>
        <!-- loading: recent cases -->
         <div class="mt-4 flex-grow-1" style="height: 45vh!important;">
            <div class="table-skeleton w-100 h-100">
                <div class="row-black h-100" style="border-radius:0.6rem!important"></div>
            </div>
         </div>
    </div>
</template>