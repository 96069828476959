import { defineStore } from 'pinia';
// helpers
import { fetchWrapper } from '@/_helpers/fetch-wrapper';
import notificationStore from '@/store/frontend/notifications.js';
import { PermissionError } from '@/_helpers/exceptions';

const API_URL = process.env.VUE_APP_API_URL;

import { getSocket, updateSocketToken } from '../socket';
const baseUrl = API_URL;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        returnUrl: null,
    }),
    actions: {
        async login(username, password) {
            try {
                const user = await fetchWrapper.post(`${baseUrl}/login`, { name: username, password });
                // update pinia state
                this.user = user;
                if (!user.is_admin) {
                    throw new PermissionError("Only admins can log into the dashboard");
                }
                // store user details and jwt in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                updateSocketToken(user.access_token);
                if (this.user && this.user.password_change_required) {
                    return "change-pw"
                };
                return "success";
            } catch (err) {
                notificationStore.showSideNotification('alert', 'Unable to Sign In', err);
            }
        },
        logout() {
            try {
                const socket = getSocket()
                if (socket) {
                    socket.disconnect();
                }
            } catch (err) {
                notificationStore.showSideNotification('alert', 'Unable to Log out', err);
            }
            this.user = null;
            localStorage.removeItem('user');
            return true;
        }
    }
});