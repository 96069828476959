import { useAuthStore } from '../store/auth.store';
import { tusUploadInstances, uploadProgress, uploadState } from '../store/upload.state';
import * as tus from 'tus-js-client';

const API_URL = process.env['VUE_APP_API_URL'];

export function handleUpload(file, caseId, caseName, evidMeta) {

    return new Promise((resolve, reject) => {
        const validExtensions = ['.zip', 'e01', 'ad1'];

        if (!validExtensions.some(ext => file.name.endsWith(ext))) {
            alert("Allowed upload format: E01, AD1, ZIP");
            reject({ success: false, message: 'Invalid file format' });
            return;
        };

        const { user } = useAuthStore();

        var upload = new tus.Upload(file, {
            chunkSize: 100000000,
            endpoint: API_URL + "/upload",
            metadata: {
                evidenceNumber: evidMeta.evidenceNumber,
                uniqueDescription: evidMeta.uniqueDescription,
                notes: evidMeta.notes,
                filename: file.name,
                caseName: caseName,
                caseId: caseId,
            },
            headers: {
                Authorization: `Bearer ${user.access_token}`
            },
            onShouldRetry: function () {
                return false
            },
            onError: (error) => {
                reject({ success: false, message: error.message });
            },
            onUploadUrlAvailable: () => {
                const uploadId = upload.url.split('/').pop();
                tusUploadInstances[uploadId] = upload;
                uploadState[uploadId] = { 'state': 'upload' };
                // create progress object
                const newProgress = {
                    id: uploadId,
                    meta: evidMeta,
                    progress: 0
                };
                if (Object.prototype.hasOwnProperty.call(uploadProgress, caseId)) {
                    if (!uploadProgress[caseId].some(f => f.id === uploadId)) {
                        uploadProgress[caseId].push(newProgress);
                    }
                } else {
                    uploadProgress[caseId] = [newProgress];
                }
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                const percentage = Math.round((bytesUploaded / bytesTotal) * 100);
                const uploadId = upload.url.split('/').pop();
                // update
                let progress = uploadProgress[caseId].find(p => p.id === uploadId);
                progress.progress = percentage;
            },
            onSuccess: () => {
                const uploadId = upload.url.split('/').pop();
                // remove progressObject from uploadProgress
                const progressIdx = uploadProgress[caseId].find(p => p.id === uploadId);
                uploadProgress[caseId].splice(progressIdx, 1);
                if (uploadProgress[caseId].length === 0) {
                    delete uploadProgress[caseId];
                };
                // remove upload from tusUploadInstances
                delete tusUploadInstances[caseId];
                resolve({ success: true });
            }
        });
        upload.start();
    });
};

export function triggerFileInput() {
    const fileInput = document.getElementById(`upload-input`);
    fileInput.click();
}
