<template>
    <Modal id="remove-user-modal" :isOpen="props.openStepOne" :title="'Delete User: ' + props.removeUserItem.username" status="alert">
        <div id="remove-user-text">
            <span>
                Click ‘Confirm’ to proceed with deleting this user.
            </span>
        </div>
        <div id="remove-user-btns" class="d-flex w-100 gap-2 justify-content-between mt-3">
            <button class="newbtn flex-1 justify-content-center" @click="closeRemoveUserModal">
                Back
            </button>
            <button class="newbtn flex-1 bg-red-500 color-white justify-content-center" @click="confirmRemoveUser">
                Confirm
            </button>
        </div>
    </Modal>
</template>

<script setup>
import Modal from '@/components/widgets/modal/Modal.vue';

const props = defineProps({
    openStepOne: { type: Boolean, required: true },
    removeUserItem: { type: Object, required: true }
});
const emit = defineEmits(
    ['close-remove-user-modal', 'confirm-remove-user']
);

const closeRemoveUserModal = () => {
    emit('close-remove-user-modal');
};

const confirmRemoveUser = () => {
    emit('confirm-remove-user');
};
</script>