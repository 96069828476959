<template>
    <div class="d-flex justify-content-between align-items-center py-4 realign-evid">
        <!-- right: case infos -->
        <div class="d-flex align-items-center color-grey-600 gap-2">
            <InboxIcon class="circle-icon" />
            <div class="d-flex flex-column">
                <!-- first row: case name -->
                <div class="d-flex" v-if="!props.loading">
                    <h6 class="m-0"
                        :class="{ 'color-customized-red': props.selectedCaseItem.DbInitProcess?.state === 'failed' }">
                        {{ props.selectedCaseItem.caseName }}
                    </h6>
                    <span class="ms-2 color-customized-red text-sm d-flex align-items-center"
                        v-if="props.selectedCaseItem.DbInitProcess?.state === 'failed'">
                        <ExclamationWarningIcon class="warning-icon"/> Create case failed.
                    </span>
                </div>
                <div class="table-skeleton mb-2" v-else>
                    <div style="height: 1.1rem; width: 16.1rem;" class="row-black"></div>
                </div>   
                <!-- second row: details -->
                <div class="d-flex gap-4">
                    <!--
                    <div class="d-flex align-items-center gap-1">
                        <BankNotesIcon class="icon-size-15px" />
                        <p class="text-sm m-0"
                            v-if="!props.loading">
                            {{ cost }}€
                        </p>
                        <div class="table-skeleton" v-else>
                            <div style="height: 0.87rem; width: 2.17rem;" class="row-black"></div>
                        </div>
                    </div> -->
                    <div class="d-flex align-items-center gap-1 hide-mobile">
                        <CalendarIcon class="icon-size-15px" />
                        <p style="font-size: 0.8125rem;" class=" m-0 d-flex gap-1"  v-if="!props.loading">
                            Created 
                            <TimeFormat :dateString="props.selectedCaseItem.createdAt" :showTime="true" />
                        </p>
                        <div class="table-skeleton" v-else>
                            <div style="height: 0.87rem; width: 6.2rem;" class="row-white"></div>
                        </div>  
                    </div>
                    <div class="d-flex align-items-center gap-1">
                        <ArrowPath class="icon-size-15px" />
                        <p style="font-size: 0.8125rem;" class="m-0 d-flex gap-1"  v-if="!props.loading">
                            Updated
                            <TimeFormat :dateString="props.selectedCaseItem.updatedAt" :showTime="true" />
                        </p>
                        <div class="table-skeleton" v-else>
                            <div style="height: 0.87rem; width: 6.2rem;" class="row-white"></div>
                        </div>  
                    </div>
                    <div class="d-flex align-items-center gap-1">
                        <UserIcon class="icon-size-15px" />
                        <p style="font-size: 0.8125rem;" class="m-0" v-if="!props.loading">{{ props.selectedCaseItem.responsiblePerson }}</p>
                        <div class="table-skeleton" v-else>
                            <div style="height: 0.87rem; width: 3.1rem;" class="row-black"></div>
                        </div>   

                    </div>
                </div>
            </div>
        </div>
        <!-- left: buttons -->
        <!-- TODO: test recreate case function! -->
        <div class="d-flex gap-2">
            <template v-if="!props.loading">
                <button class="newbtn" @click="openRemoveCaseModal">
                    Delete
                </button>
                <button v-if="!isEvidenceModalOpen && props.selectedCaseItem.DbInitProcess?.state === 'success'" 
                    class="newbtn font-weight-700 color-white bg-primary-700 hover-bg-primary-600"
                    @click="store.dispatch('openEvidenceModal')">
                    <span>Upload evidence</span>
                </button>
                <!-- <button v-else-if="props.selectedCaseItem.DbInitProcess?.state === 'failed'"
                    class="newbtn font-weight-700 color-white bg-red-500"
                    @click="recreateSelectedCase">
                    Recreate this case
                </button> -->
                <button v-else-if="!isEvidenceModalOpen && props.selectedCaseItem.DbInitProcess?.state && props.selectedCaseItem.DbInitProcess?.state !== 'failed'"
                    class="newbtn font-weight-700 color-white bg-grey-400 cursor-default hover-bg-grey-300 px-3 d-flex align-items-center">
                    <Spinner class="m-0 p-0 me-2" />
                    <span>Initializing</span>
                </button>
            </template>
            <template v-else>
                <div class="table-skeleton me-2">
                    <div style="height: 35px; width: 65px;" class="row-white"></div>
                </div>
                <div class="table-skeleton">
                    <div style="height: 35px; width: 125px;" class="row-black"></div>
                </div>   
            </template>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from "vuex";
// widgets
import TimeFormat from '@/components/widgets/TimeFormat.vue';
// icons
import InboxIcon from '@/components/icons/InboxIcon.vue';
import CalendarIcon from '@/components/icons/CalendarIcon.vue';
import ArrowPath from '@/components/icons/ArrowPath.vue';
import UserIcon from '@/components/icons/UserIcon.vue';
import ExclamationWarningIcon from '@/components/icons/ExclamationWarningIcon.vue';
import Spinner from '@/components/icons/Spinner.vue';


const props = defineProps({
    selectedCaseItem: {
        type: Object,
        required: true
    },
    loading: {
        type: Boolean,
        required: true
    }
});
const emit = defineEmits(['remove-case', 'set-loading']);

const store = useStore();

const isEvidenceModalOpen = computed(() => store.state.isEvidenceModalOpen);

// TODO: by recreate case (#50)
// import { useCaseStore } from '@/store/caseStore';
// import { useRouter } from 'vue-router';

// const router = useRouter();
// const caseStore = useCaseStore();
// import sideNotificationStore from '@/store/frontend/sideNotificationStore.js';

// const recreateSelectedCase = async() => {
//     emit('set-loading');
//     const recreateCaseForm = {
//         caseName: props.selectedCaseItem.caseName,
//         responsiblePerson: props.selectedCaseItem.responsiblePerson,
//         additionalInformation: props.selectedCaseItem.additionalInformation
//     };
//     const delResponse = await caseStore.deleteCase(props.selectedCaseItem.caseId);
//     if (delResponse.success) {
//         try {
//             const recreateResponse = await caseStore.createCase(recreateCaseForm);
//             // success[boolean]: if get the response
//             if (recreateResponse.success) {
//                 console.log(`recreateCase: state: success`);
//                 router.push(`/new-evidence/${recreateResponse.data.caseId}`);
//                 window.location.reload();
//             } else {
//                 router.push('/case-overview');
//                 sideNotificationStore.showSideNotification('alert', 'Error occurred', 'Failed to create case. Please try again.');
//             }
//         } catch (error) {
//             sideNotificationStore.showSideNotification('alert', 'Error occurred', 'Failed to create case.');
//             router.push('/case-overview')
//         }
//     } else {
//         sideNotificationStore.showSideNotification('alert', 'Error occurred', 'Failed to delete case by recreate.');
//         router.push('/case-overview');
//     }
// };

// emits
const openRemoveCaseModal = () => {
    emit('remove-case');
}
</script>

<style scoped>
.circle-icon {
    border-radius: 50%;
    border: 1px solid var(--grey-200);
    height: 3.1rem;
    width: 3.1rem;
    padding: 0.8rem;
}
</style>