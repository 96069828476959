<script setup>
import { computed, watch, ref, toRefs, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
// stores
import { updateSocketToken } from './socket';
import { useAuthStore } from "./store/auth.store";
import { useCaseStore } from "@/store/caseStore";
import { useUserStore } from '@/store/userStore';
import notificationStore from '@/store/frontend/notifications.js';
import tutorialLandingStore from '@/store/frontend/tutorialLandingStore.js';
import HelpCenterModal from '@/components/widgets/modal/HelpCenterModal.vue';
// components
//import Sidenav from "@/components/template/Sidenav";
import SideNavNew from "@/components/template/navigation/sidenav.vue";
//import Navbar from "@/components/template/Navbars/Navbar.vue";
import SideNotification from '@/components/widgets/SideNotification.vue'
import TermsAndConditions from "@/components/TermsAndConditions.vue";
import BlockDisplay from '@/components/BlockDisplay.vue';

const store = useStore();
const userStore = useUserStore();
const { user } = useAuthStore();
const caseStore = useCaseStore();

// computed from store
// const isNavFixed = computed(() => store.state.isNavFixed);
// const darkMode = computed(() => store.state.darkMode);
// const isAbsolute = computed(() => store.state.isAbsolute);
const showSidenav = computed(() => store.state.showSidenav);
const layout = computed(() => store.state.layout);
//const showNavbar = computed(() => store.state.showNavbar);
const showGTC = computed(() => store.state.showGTC);
const isHelpModalOpen = computed(() => store.state.isHelpModalOpen);

const { sideNotification } = toRefs(notificationStore);
const { closeSideNotification } = notificationStore;
const { setLandingStep, setTutorialStep, setRestartTutor, isFirstTutor, continueTutor, restartTutor  } = tutorialLandingStore;

// check connection for userStore
const caseLen = computed(() => caseStore.cases?.length || 0);
const evidLen = computed(() => caseStore.cases?.reduce((sum, caseItem) => sum + (caseItem.Evidence?.length || 0), 0) || 0);
const tutorialDone = computed(() => userStore.loggedInUser?.tutorial_done || false);

const checkStoresConnected = () => {
    if (userStore.connected && caseStore.connected) {
        updateLandingStep();
        updateTutorStep();
    };
};

const updateLandingStep = () => {
    if (caseLen.value === 0) {
        setLandingStep(1);
    } else if (caseLen.value > 0 && evidLen.value <= 0) {
        setLandingStep(2);
    } else if (caseLen.value > 0 && evidLen.value > 0) {
        if (!tutorialDone.value) {
            setLandingStep(3);
        } else {
            setLandingStep(-1);
        }
    }
};

const updateTutorStep = () => {
    if (!continueTutor.value) {
        // first tutorial
        if (tutorialDone.value) {
            setTutorialStep(-1);
            isFirstTutor(false);
            // restart user control tutorial
            if (restartTutor.value) {
                setTutorialStep(1);
            } else {
                setTutorialStep(-1);
            }
        } else if (!tutorialDone.value) {
            setTutorialStep(1);
            isFirstTutor(true);
        }
    }
};

const windowWidth = ref(window.innerWidth);
function updateWindowSize() {
    windowWidth.value = window.innerWidth;
};

onMounted(() => {
    window.addEventListener('resize', updateWindowSize);
    checkStoresConnected();
});

watch(() => [caseStore.connected, userStore.connected], () => {
    updateLandingStep();
    updateTutorStep();
});

// landing: step 1 & 2 depands on caseLen / evidLen
watch([caseLen, evidLen, tutorialDone, restartTutor], () => {
    updateLandingStep();
    updateTutorStep();
});

// init tutorial done
onUnmounted(() => {
    window.removeEventListener('resize', updateWindowSize);
});

// refresh socket token
if (user) {
    updateSocketToken(user.access_token);
};

// const navClasses = computed(() => {
//     return {
//         "position-sticky bg-white left-auto top-2 z-index-sticky":
//             isNavFixed.value && !darkMode.value,
//         "position-sticky bg-default left-auto top-2 z-index-sticky":
//             isNavFixed.value && darkMode.value,
//         "position-absolute px-4 mx-0 w-100 z-index-2": isAbsolute.value,
//         "px-0": !isAbsolute.value,
//     };
// }); 

// dev & debug (landing / tutorial steps)
const devMode = ref(false);
const setTutorialDone = (state) => {
    userStore.updateTutorialDone(state);
};
</script>

<template>
    <block-display v-if="windowWidth < 375"
        style="min-width: 100% !important; min-height: 100vh !important; overflow: auto;" />

    <div v-else>
        <div v-show="layout === 'landing'" class="bg-white h-100 bg-gradient-primary position-fixed w-100"></div>

        <terms-and-conditions v-if="showGTC" />
        <help-center-modal :isOpen="isHelpModalOpen" @close="store.dispatch('closeHelpModal')" />

        <SideNotification :isOpen="sideNotification.isOpen" :status="sideNotification.state"
            :title="sideNotification.title" :desc="sideNotification.message" @close="closeSideNotification" />
        
        <!--  <sidenav v-if="showSidenav" /> -->
        <SideNavNew v-if="showSidenav" />
        <main class="main-content position-relative d-flex flex-column min-height-100vh" style="position: relative;">
          <!--  <navbar :class="[navClasses]" v-if="showNavbar" class="flex-shrink-0" /> -->

            <div style='position: absolute; top: 2rem; left: 10rem; border: solid 1px var(--red-500)'
                class="d-flex flex-column p-1" v-if="devMode">
                <span style="font-size: small; font-weight: 800">tutorial / landing store</span>
                <span style="font-size: x-small; border-bottom: 1px solid var(--grey-300)">
                    show landing: {{ tutorialLandingStore.landingStep }} | caseLen: {{ caseLen }} | evidLen: {{ evidLen }}
                </span>
                <span style="font-size: x-small">
                    tutor step: {{ tutorialLandingStore.tutorStep }} | tutorial_done: {{ tutorialDone }} | first tutorial: {{ tutorialLandingStore.firstTutor }}
                </span>
                <span style="font-size: x-small">
                    restart: {{ tutorialLandingStore.restartTutor }} | continueTutorFromStep: {{ continueTutor }}
                </span>
            </div>
            <div style="position: absolute; top: 2rem; left: 28rem;" class="d-flex flex-column" v-if="devMode">
                <button @click="setTutorialDone(false)">tutorial_done: false</button>
                <button @click="setTutorialDone(true)">tutorial_done: true</button>
                <button @click="setRestartTutor(true)">restartTutor(store): restart</button>
                <button @click="setRestartTutor(false)">restartTutor(store): end</button>
            </div>

            <router-view />
        </main>
    </div>
</template>

<style scoped>
.min-height-100vh {
    min-height: 100vh;
}
</style>