<template>
    <div :class="['modal-alert position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center', { open: isOpen }]">
        <div class="bg-grey-50 d-flex flex-column align-items-center p-4 border-radius-0-5-rem" style="max-width: 90%!important; min-width: 20rem!important;">
            <div v-if="status != 'none'" class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column align-items-center">
                    <component :is="statusIcon" :class="['modal-icon p-2 mb-2 border-round icon-size-3rem', statusClass]" v-show="props.status !== 'empty'" />
                    <h6 :class="{ 'mt-3': props.status === 'empty', 'mt-0': props.status !== 'empty' }">{{ title }}</h6>
                    <p class="text-sm">{{ desc }}</p>
                </div>
                <!--
                <button @click="closemodal-alert" class="cbtn-sm modal-alert-close">
                    <XMarkIcon class="size-6 icon-xs text-grey-600" />
                </button>
                -->
            </div>
            <div id="modal-buttons">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import CheckIcon from '@/components/icons/Check.vue';
import InfoIcon from '@/components/icons/Info.vue';
import WarningIcon from '@/components/icons/WarningIcon.vue';

const props = defineProps({
    status: {
        type: String,
        required: false,
        default: 'info'
    },
    isOpen: {
        type: Boolean,
        required: true
    },
    title: {
        type: String,
        required: false
    },
    desc: {
        type: String,
        required: false
    }
});

const statusIcon = computed(() => {
    switch (props.status) {
        case 'alert':
            return WarningIcon;
        case 'success':
            return CheckIcon;
        case 'info':
        default:
            return InfoIcon;
    }
});

const statusClass = computed(() => {
    switch (props.status) {
        case 'alert':
            return 'modal-red';
        case 'success':
            return 'modal-green';
        case 'info':
        default:
            return 'modal-grey';
    }
});
</script>

<style scoped>
.modal-alert {
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    z-index: 99999;
    pointer-events: none;
}

.modal-alert.open {
    opacity: 1;
    pointer-events: all;
    z-index:999999;
}

.modal-icon {
    color: inherit;
    background-color: inherit;
}

/* use color from c-palatte? */
.modal-red {
    background-color: rgb(254, 226, 226);
    color: rgb(220, 38, 38);
}

.modal-green {
    background-color: rgb(236, 252, 203);
    color:rgb(101, 163, 13);
}

.modal-grey {
    background-color: #f5f5f5;;
    color: #757575;
}

/* currently not used commented */
.modal-alert-close {
    align-self: flex-end;
    margin-bottom: 20px;
}
</style>
