<template>
    <svg viewBox="0 0 595.3 841.9" fill="none" stroke-width="1.5" stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M325.9 255.1h-60l-30 51.9 30 52h60l30-52z"
            style="fill: rgb(255, 255, 255); stroke-width: 4px; stroke: rgb(84, 97, 123);"
            transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)" />
        <path
            d="M325.9 141.1h-60l-30 52 30 52h60l30-52zM325.9 596.8h-60l-30 52 30 52h60l30-52zM325.9 482.9h-60l-30 52 30 51.9h60l30-51.9z"
            style="stroke: rgb(84, 97, 123); stroke-width: 4px;"
            transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)" />
        <path d="M325.9 369h-60l-30 51.9 30 52h60l30-52z"
            style="stroke: rgb(84, 97, 123); stroke-width: 4px; fill: rgb(255, 255, 255);"
            transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)" />
        <path d="M325.9 710.8h-60l-30 51.9 30 52h60l30-52z" style="stroke: rgb(84, 97, 123); stroke-width: 4px;"
            transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)" />
        <path d="M424.1 198.1h-60l-30 52 30 51.9h60l30-51.9z"
            style="stroke: rgb(84, 97, 123); stroke-width: 4px; fill: rgb(255, 255, 255);"
            transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)" />
        <path
            d="M424.1 84.2h-60l-30 51.9 30 52h60l30-52zM424.1 539.9h-60l-30 51.9 30 52h60l30-52zM424.1 425.9h-60l-30 52 30 52h60l30-52zM424.1 312h-60l-30 52 30 51.9h60l30-51.9zM424.1 653.8h-60l-30 52 30 51.9h60l30-51.9z"
            style="stroke: rgb(84, 97, 123); stroke-width: 4px;"
            transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)" />
        <path d="M522.4 141.1h-60l-30 52 30 52h60l30-52z"
            style="stroke: rgb(84, 97, 123); fill: rgb(255, 255, 255); stroke-width: 4px;"
            transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)" />
        <path
            d="M522.4 27.2h-60l-30 52 30 51.9h60l30-51.9zM522.4 482.9h-60l-30 52 30 51.9h60l30-51.9zM522.4 369h-60l-30 51.9 30 52h60l30-52zM522.4 255.1h-60l-30 51.9 30 52h60l30-52zM522.4 596.8h-60l-30 52 30 52h60l30-52z"
            style="stroke: rgb(84, 97, 123); stroke-width: 4px;"
            transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)" />
        <path d="M167.3 643.8h60l30-52-30-51.9h-60l-30 51.9z"
            style="stroke: rgb(84, 97, 123); stroke-width: 4px; fill: rgb(255, 255, 255);"
            transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)" />
        <path
            d="M167.3 757.7h60l30-51.9-30-52h-60l-30 52zM167.3 302h60l30-51.9-30-52h-60l-30 52zM167.3 415.9h60l30-51.9-30-52h-60l-30 52z"
            style="stroke: rgb(84, 97, 123); stroke-width: 4px;"
            transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)" />
        <path d="M167.3 529.9h60l30-52-30-52h-60l-30 52zM68.9 700.8h60l30-52-30-52h-60l-30 52z"
            style="stroke: rgb(84, 97, 123); fill: rgb(255, 255, 255); stroke-width: 4px;"
            transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)" />
        <path
            d="M68.9 359h60l30-52-30-51.9h-60l-30 51.9zM68.9 472.9h60l30-52-30-51.9h-60l-30 51.9zM68.9 586.8h60l30-51.9-30-52h-60l-30 52z"
            style="stroke: rgb(84, 97, 123); stroke-width: 4px;"
            transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)" />
    </svg>
</template>