<template>
    <main class="bg-grey-100 h-100 mt-0">
        <div class="page-header min-vh-80">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-7">
                        <!-- navigate back -->
                        <div id="change-pw-navigate-back" class="d-flex pb-4">
                            <div class="d-flex align-items-center gap-1  cursor-pointer hover-text-black me-auto"
                                v-if="props.source === 'user-control'" @click="router.push('/user-control')">
                                <ChevronLeft class="icon-size-10px" style="color: inherit;" />
                                <p class="m-0" style="color: inherit;font-size: 0.8125rem;">User management</p>
                            </div>
                        </div>
                        <div class="card z-index-0 border p-4">
                            <span class="font-sina color-red-500 mb-2">{{ highlightPassword.msg }}</span>

                            <div class="pb-3" v-show="userStore.loggedInUser?.password_change_required">
                                    <h1 class=" m-0 font-sina-xl">Company Registration</h1>
                                    <p class="m-0 font-sina">Kindly provide your company details below.</p>
                                    <div class="d-flex flex-column gap-2">
                                        <sinacluster-input id="company-name" class="mt-3" placeholder="Company Name"
                                            v-model="userDetails.company" />
                                        <!-- str hausnummer -->
                                        <div class="d-flex gap-2">
                                            <sinacluster-input id="street" placeholder="Street" style="flex-basis: 70%;"
                                                v-model="userDetails.streetAddress" />
                                            <sinacluster-input id="street-nr" placeholder="Nr"
                                                style="flex-basis: 30%;" />
                                        </div>
                                        <!-- plz ort -->
                                        <div class="d-flex gap-2">
                                            <sinacluster-input id="postal-code" placeholder="Zip"
                                                style="flex-basis: 35%;" v-model="userDetails.postalCode" />
                                            <sinacluster-input id="city" placeholder="City" style="flex-basis: 65%;"
                                                v-model="userDetails.city" />
                                        </div>
                                    </div>
                                    <h1 class="mt-3 m-0 font-sina-xl">Contact Partner</h1>
                                    <div class="d-flex flex-column gap-2">
                                        <!-- name vorname -->
                                        <div class="d-flex gap-2 mt-2">
                                            <sinacluster-input id="first-name" placeholder="First Name"
                                                v-model="userDetails.contactFirstName" />
                                            <sinacluster-input id="last-name" placeholder="Last Name"
                                                v-model="userDetails.contactLastName" />
                                        </div>
                                        <!-- email tel -->
                                        <div class="d-flex gap-2">
                                            <sinacluster-input id="contact-email" placeholder="E-Mail"
                                                v-model="userDetails.email" />
                                            <sinacluster-input id="contact-tel" placeholder="Tel. / Handy"
                                                v-model="userDetails.phone" />
                                        </div>
                                    </div>
                            </div>

                            <div class="mb-2 text-left" style="background: transparent;">
                                <h4 style="font-size: 1rem" class="font-weight-font-weight-700er mb-1">
                                    Change your password
                                </h4>
                                <p class="mb-0 text-xs color-grey-800"
                                    v-show="userStore.loggedInUser?.password_change_required">
                                    Please change your password after your first login.
                                </p>
                            </div>
                            <!-- main content -->
                                <div class="d-flex flex-column gap-2">
                                    <sinacluster-input id="old-pw-input" passwordMode :error="highlightPassword.old"
                                        placeholder="Current password" v-model="userPassword.old"
                                        @change="highlightPassword.old = false" />
                                    <sinacluster-input id="new-pw-input" passwordMode :error="highlightPassword.new"
                                        placeholder="New password" v-model="userPassword.new"
                                        @change="highlightPassword.new = false" />
                                    <sinacluster-input id="repeate-pw-input" passwordMode
                                        :error="highlightPassword.repeat" placeholder="Repeat new password"
                                        v-model="userPassword.repeat" @change="highlightPassword.repeat = false" />
                                    <div class="text-center mt-2">
                                        <button @click="handleChangePw"
                                            class="d-flex justify-content-center newbtn hover-bg-primary-600 bg-primary-700 w-100 color-white"
                                            type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script setup>
import { reactive } from 'vue';
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useUserStore } from '@/store/userStore';
import router from '@/router';
import SinaclusterInput from '@/components/widgets/SinaclusterInput.vue';
import ChevronLeft from '@/components/icons/ChevronLeft.vue';

const store = useStore();
const userStore = useUserStore();

onMounted(() => {
    store.state.showSidenav = false;
});

onUnmounted(() => {
    store.state.showSidenav = true;
})

const props = defineProps({
    source: {
        type: String,
        default: 'normal'
    }
});

const userDetails = reactive({
    company: "",
    contactLastName: "",
    contactFirstName: "",
    streetAddress: "",
    postalCode: "",
    city: "",
    email: "",
    phone: ""
})

const userPassword = reactive({
    old: "",
    new: "",
    repeat: ""
})

const highlightPassword = reactive({
    old: false,
    new: false,
    reprepeateatedPw: false,
    msg: ""
})

const updateUserDetails = async () => {
    await userStore.updateUser(
        userStore.loggedInUser.userId,
        {
            company: userDetails.company,
            contactLastName: userDetails.contactLastName,
            contactFirstName: userDetails.contactFirstName,
            streetAddress: userDetails.streetAddress,
            postalCode: userDetails.postalCode,
            city: userDetails.city,
            email: userDetails.email,
            phone: userDetails.phone
        }
    );
}

const validateUserDetails = () => {
    const requiredFields = ['company', 'contactLastName', 'contactFirstName', 'streetAddress', 'postalCode', 'city', 'email', 'phone'];
    let isValid = true;
    
    requiredFields.forEach(field => {
        if (!userDetails[field] || userDetails[field].trim() === "") {
            isValid = false;
        }
    });

    return isValid;
};

const validatePassword = () => {
    highlightPassword.old = userPassword.old.length === 0;
    highlightPassword.new = userPassword.new.length === 0;
    highlightPassword.repeat = userPassword.repeat.length === 0;

    if (highlightPassword.old || highlightPassword.new || highlightPassword.repeat) {
        highlightPassword.msg = "All password fields are required!";
        return false;
    }

    if (userPassword.old === userPassword.new) {
        highlightPassword.msg = "Old and new passwords should not be the same!";
        highlightPassword.old = highlightPassword.new = true;
        return false;
    }

    if (userPassword.new !== userPassword.repeat) {
        highlightPassword.msg = "New passwords do not match!";
        highlightPassword.new = highlightPassword.repeat = true;
        return false;
    }

    highlightPassword.msg = "";
    return true;
};

const handleChangePw = async () => {
    // If password change is required, first validate user details
    if (userStore.loggedInUser?.password_change_required) {
        if (!validateUserDetails()) {
            highlightPassword.msg = "Please fill out all required company details.";
            return;
        }
    }

    // Validate passwords
    if (!validatePassword()) return;

    // update user details if first-time password change is required
    if (userStore.loggedInUser?.password_change_required) {
        await updateUserDetails();
        /* Clear userDetails form */
        Object.keys(userDetails).forEach(key => userDetails[key] = "");
    }

    // change pw
    await userStore.changePassword(userStore.loggedInUser?.name, userPassword.old, userPassword.new);

    /* Clear password form */
    userPassword.old = "";
    userPassword.new = "";
    userPassword.repeat = "";
};

</script>

<style scoped>
.card {
    border: 0.5px solid rgb(216, 216, 216) !important;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 8px, rgba(0, 0, 0, 0.067) 0px 2px 5px, rgba(0, 0, 0, 0.067) 0px 1px 1px !important;
}

.warning-customized-margin {
    margin: 0rem 0rem 0rem 0.5rem;
}

.skip {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    background: #f7f7f7;
    margin-top: -7px;
    cursor: pointer;
    font-weight: 700;
    line-height: 2;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 0.8rem;
    height: 40px;
}

.skip:hover {
    background: #e7e7e7;
    transition: all 0.15s ease-in;
}
</style>
