import { ref } from 'vue';

const landingStep = ref(null);

// landing: depends only on caseLen & evidLen
const setLandingStep = (state) => {
    landingStep.value = state;
};

const restartTutor = ref(null);
const setRestartTutor = (state) => {
    restartTutor.value = state
};

// landing - page 3 || display: 
const firstTutor = ref(null);
const isFirstTutor = (state) => {
    firstTutor.value = state
};

const continueTutor = ref(false);
const setContinueFromStep = (state) => {
    continueTutor.value = state;
};

const tutorStep = ref(null);
// user-control tutorl: depends only on `userItem.tutorial_done`
const setTutorialStep = (state) => {
    tutorStep.value = state;
};

export default {
    landingStep,
    setLandingStep,
    restartTutor,
    setRestartTutor,
    tutorStep,
    setTutorialStep,
    firstTutor,
    isFirstTutor,
    continueTutor,
    setContinueFromStep,
};