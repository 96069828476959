
import { defineStore } from 'pinia';
import { fetchWrapper } from '../_helpers/fetch-wrapper';
import notificationStore from '@/store/frontend/notifications.js';
import { monitorAirflowProcess } from '@/_helpers/helpers';

const API_URL = process.env['VUE_APP_API_URL'];

export const useCaseStore = defineStore('case', {
    state: () => ({
        cases: [],
        previousCases: [],
        connected: false,
        dbInitWatcher: [],
        removeWatcher: [],
        fileCrawlerWatcher: [],
        processWatcher: []
    }),
    getters: {
        getCaseById: (state) => (id) => state.cases.find((c) => c.caseId === id),
        getAllCases: (state) => () => state.cases,
    },
    actions: {
        bindEvents(socket) {
            socket.on("connect", async () => {
                this.cases = await this.fetchAllCases();
                // console.log(`[caseStore/socket-connected]: \n${JSON.stringify(this.cases)}\n`);
                this.connected = true;
            });
            socket.on("disconnect", () => {
                this.connected = false;
            });
            socket.on("case:created", (msg) => {
                // console.log(`[caseStore/created]: \n${JSON.stringify(msg)}\n`);
                this.cases.push(msg);
            });
            socket.on("case:updated", (msg) => {
                // console.log(`[caseStore/updated]: \n${JSON.stringify(msg)}\n`);
                const index = this.cases.findIndex((c) => c.caseId === msg.caseId);
                if (index !== -1) {
                    this.cases[index] = msg;
                }
            });
            socket.on("case:destroyed", (msg) => {
                // console.log(`[caseStore/destroy]: \n${JSON.stringify(msg)}\n`);
                this.cases = this.cases.filter((c) => c.caseId !== msg.caseId);
                if (this.removeWatcher.length > 0) { this.watchRemoveCaseProcess(msg) }
            });
            socket.on("case:process:updated", async (msg) => {
                // console.log(`[caseStore/process-updated]: \n${JSON.stringify(msg.Evidence?.length)}\n`);
                const caseData = await this.fetchCaseById(msg.caseId);
                if (caseData) {
                    const index = this.cases.findIndex((c) => c.caseId === caseData.caseId);
                    if (index !== -1) { 
                        this.cases[index] = caseData;
                        // object in monitor list?
                        if (this.dbInitWatcher.length > 0) { this.watchDbInitProcess(caseData); }
                        this.watchFileCrawler(msg);
                        this.watchProcessing(msg);
                    }
                }
            });
        },
        // receive
        async fetchAllCases() {
            try {
                const cases = await fetchWrapper.get(`${API_URL}/case`);
                if (cases) { return cases; } 
                else { throw new Error('Failed to get cases'); }
            } catch (error) {
                console.error(error);
            }
        },
        async fetchCaseById(id) {
            try {
                const caseData = await fetchWrapper.get(`${API_URL}/case/${id}`);
                if (caseData) { return caseData; } 
                else { throw new Error('Failed to get case'); }
            } catch (error) {
                console.error(error);
            }
        },
        // send
        async createCase(caseData) {
            try {
                const newCase = await fetchWrapper.post(`${API_URL}/case`, caseData);
                if (newCase && newCase.caseId) {
                    this.dbInitWatcher.push(newCase.caseId);
                } else {
                    notificationStore.addNew('alert', 'Unable to create a new case', 'Case Id is not created.');
                }
                return { success: true, message: '', response: newCase };
            } catch (error) {
                notificationStore.addNew('alert', 'Unable to create a new case', error);
                return { success: false, message: error || 'Unspecified error occured.', response: '' };
            }
        },
        async deleteCase(id) {
            try {
                await fetchWrapper.delete(`${API_URL}/case/${id}`);
                this.removeWatcher.push(id);
                return { success: true };
            } catch (error) {
                notificationStore.addNew('alert', 'Unable to remove case', error || 'Remove case process is not triggered.');
                return { success: false };
            }
        },
        async updateCase(id, caseData) {
            try {
                const updatedCase = await fetchWrapper.put(`${API_URL}/case/${id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(caseData),
                });
                if (updatedCase) {
                // const index = this.cases.findIndex((c) => c.caseId === updatedCase.caseId);
                // if (index !== -1) {
                //   this.cases[index] = updatedCase;
                // }
                    return updatedCase;
                } else {
                    throw new Error('Failed to update case');
                }
            } catch (error) {
                console.error(error);
            }
        },
        // monitor
        watchDbInitProcess(response) {
            const state = response.DbInitProcess?.state;
            const targetId = response.caseId;
            const notificationTitle = "Create case";
            const successMsg = `${response.caseName} is created successfully.`;
            const failedMsg = `${response.caseName} database initializing failed.`;
            monitorAirflowProcess(state, targetId, this.dbInitWatcher, notificationTitle, successMsg, failedMsg);
        },
        watchRemoveCaseProcess(response) {
            const state = response.DeleteCaseProcess.state;
            const targetId = response.caseId;
            const notificationTitle = "Remove case";
            const successMsg = `${response.caseName} is removed successfully`;
            const failedMsg = `${response.caseName} can't be removed because of failed process.`;
            monitorAirflowProcess(state, targetId, this.removeWatcher, notificationTitle, successMsg, failedMsg);
        },
        watchFileCrawler(response) {
            // check response from 'filecrawler'
            if (response?.dag_id.includes('filecrawler')) {
                // [1] state is queued / running -> add dag-run-id
                if ( response.state === 'queued' || response.state === 'running' ) {
                    if ( !this.fileCrawlerWatcher.includes(response.dag_run_id) ) { this.fileCrawlerWatcher.push(response.dag_run_id) };
                } 
                // [2] state is success / failed -> remove dag-run-id & display notification
                else if ( response.state === 'success' || response.state === 'failed' ) {
                    const state = response.state;
                    const targetId = response.dag_run_id;
                    const notificationTitle = "File Crawler";
                    const successMsg = `Files from ${response.conf.evidence.fileName} are extracted.`;
                    const failedMsg = `File crawler failed by extracting ${response.conf.evidence.fileName}.`;
                    const link = `/new-evidence/${response.caseId}`;
                    monitorAirflowProcess(state, targetId, this.fileCrawlerWatcher, notificationTitle, successMsg, failedMsg, link, link);
                }
            }
        },
        watchProcessing(response) {
            if ( response?.dag_id.includes('rest') ){
                const state = response.state;
                const processId = response.dag_run_id;
                if ( state === 'queued' ) {
                    if ( !this.processWatcher.includes(processId) ) { this.processWatcher.push(processId) }
                    notificationStore.addNew('info', 'Processing', `Waiting for processing. `, `/new-evidence/${response.caseId}`);
                } else if ( state === 'running' ) {
                    notificationStore.addNew('info', 'Processing', 'Process started.', `/new-evidence/${response.caseId}`);
                } else {
                    const targetId = response.dag_run_id;
                    const notificationTitle = "Processing";
                    const successMsg = `Processing is finished successfully.`;
                    const failedMsg = `Processing is finished with error.`;
                    const link = `/new-evidence/${response.caseId}`;
                    monitorAirflowProcess(state, targetId, this.processWatcher, notificationTitle, successMsg, failedMsg, link, link);
                }
            }
        }
    },
});
