<style>
.uL {
    padding-left: 15px;
    padding-right: 15px;
}

.uL h5 {
    font-style: normal;
  color: rgb(27, 27, 27);
  line-height: normal;
  font-weight: 500;
  font-size: 0.8125rem;
  margin: 0; 
}

.uL .bold {
    font-weight: 600;
}

.uL button {
    color: black ;
  font-size: 0.8125rem !important;
  font-weight: 500 !important;
  padding: 4px 10px;
  border: solid 1px #00000036;
  border-radius: 5px;
  background: white ;
  box-shadow: 0px 2px 3px 0px #0000000a;
  display: flex;
  align-items: center;
  gap: 5px;
} .uL button:hover {
    background-color: var(--grey-100);
}
</style>
<template>
    <aside class="uL">
        <!-- header -->
        <div class="d-flex align-items-center justify-content-between">
            <h5>Directory</h5>
            <button v-if="!props.loading" 
                class="cbtn-xs d-flex align-items-center gap-2" 
                :class="{'z-index-highest': tutorStep === 1 }"
                @click="handleNewUser">
                <AddUser class=" p-0 icon-size-15px color-grey-600" />
                Create user
            </button>
            <div v-else class="table-skeleton">
                <div class="row-black" style="width: 7.3rem; height: 2.3rem;"></div>
            </div>
        </div>
        <!-- search bar -->
        <div class=" d-flex align-items-center mt-3 mb-2 px-2 py-1"
            style="border-radius: 0.5rem; border: 1px solid var(--grey-300)"
            :class="{ 'focus-input-transition': searchFocus }">
            <SearchIcon class="icon-xs text-grey-600 me-1" />
            <input id='user-search' class="w-100 transparent-input"
                :disabled="props.loading"
                :value="localSearchQuery" @input="updateSearchQuery($event.target.value)"
                :placeholder="props.loading ? 'Now loading' : 'Search directory'">
        </div>
        <!-- user list -->
        <div v-if="props.loading" class="table-skeleton gap-2">
            <div class="row-white" style="height: 3.5rem; width: 100%;"></div>
            <div class="row-black" style="height: 3.5rem; width: 100%;"></div>
            <div class="row-white" style="height: 3.5rem; width: 100%;"></div>
        </div>
        <div v-else-if="props.filteredUsers.length !== 0" class="col gap-2 hidden-overflow" 
            style="overflow-y: auto!important; max-height: 75vh;">
            <div v-for="user in props.filteredUsers" :key="user.userId"
                @click="selectUser(user)" 
                class="d-flex gap-2 p-2 align-items-center cursor-pointer user-card"
                :class="{ 'user-card-selected': user.userId === props.userId, 'opacity-5': props.focusedId !== '' && props.focusedId !== user.userId, 'z-index-highest': tutorStep === 2 }">
                <div id="user-icon">
                    <UserAvatar :isAdmin="user?.is_admin" :name="user?.username" />
                </div>
                <div id="user-name-and-right" class="d-flex col">
                    <h5 class="m-0 bold">{{ user.username }}</h5>
                    <p class="text-xs m-0">{{ user.is_admin ? 'Admin' : 'User' }}</p>
                </div>
                <div v-if="props.userId === user.userId" id="user-control-btns"
                    class="d-flex align-items-end svg-size-15px me-1 fill-gray">
                    <KeyIcon :class="{ 'hover-fill-primary-400': user.is_admin, 'hover-fill-grey-800': !user.is_admin }"
                        title="change password" @click="handleChangePw(user)" />
                    <TrashIcon v-show="user.is_admin === false" class="ms-2 hover-fill-grey-800" 
                        title="remove user" @click="openRemoveUserModal(user)" />
                </div>
            </div>
        </div>
        <div v-else>
            <p class="text-xs">No user found. Try log in again.</p>
        </div>
    </aside>
</template>

<script setup>
import { ref, watch } from 'vue';
import tutorialLandingStore from '@/store/frontend/tutorialLandingStore.js';
import UserAvatar from '../widgets/UserAvatar.vue';
// icons
import AddUser from '@/components/icons/AddUser.vue';
import SearchIcon from '@/components/icons/SearchIcon.vue';
import KeyIcon from '@/components/icons/KeyIcon.vue';
import TrashIcon from '@/components/icons/TrashIcon.vue';

const searchFocus = ref(false);
const { tutorStep } = tutorialLandingStore;

const emit = defineEmits(
    ['handle-new-user', 'handle-change-pw', 'select-user', 'open-remove-user-modal']
);
const props = defineProps({
    filteredUsers: { type: Array, required: true },
    searchQuery: { type: String, required: true },
    loading: { type: Boolean, required: true },
    userId: { type: String, required: true },
    focusedId: { type: String, required: true }
});

const localSearchQuery = ref(props.searchQuery);

// search
const updateSearchQuery = (value) => {
  emit('update:searchQuery', value);
};

watch(() => props.searchQuery, (newVal) => {
  localSearchQuery.value = newVal;
});

// other emits functions
const handleNewUser = () => {
    emit('handle-new-user');
};

const handleChangePw = (user) => {
    emit('handle-change-pw', user);
};

const selectUser = (user) => {
    emit('select-user', user)
};

const openRemoveUserModal = (user) => {
    /* in tutorial it should not be possible to delete the highlighted user */
    if (tutorStep.value === 2) {
        return 0;
    }
    emit('open-remove-user-modal', user);
};
</script>

<style scoped>
/* search bar */ 
.focus-input-transition {
    box-shadow: inset 0 0 0 2px var(--grey-400);
    transition: box-shadow 0.2s ease-in-out;
}

.transparent-input {
    border: none;
    box-shadow: none;
    font-size: 0.8125rem;
    background-color: transparent !important;
}

.transparent-input:focus {
    outline: none;
}

/* select user */
.user-card:hover {
    background-color: var(--grey-100);
    border-radius: 0.5rem;
    transition: background-color 0.3s ease;
}

.user-card-selected {
    background-color: var(--grey-100) !important;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease;
}

.user-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}
</style>