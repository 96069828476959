import { defineStore } from 'pinia';
import { fetchWrapper } from '../_helpers/fetch-wrapper';

const API_URL = process.env['VUE_APP_API_URL'];

export const useEvidenceStore = defineStore('evidence', {
    state: () => ({
        evidences: [],
        previousEvidences: [],
        connected: false,
    }),
    getters: {
        getEvidenceById: (state) => (id) => state.evidences.find((e) => e.evidId === id),
        getEvidenceByCaseId: (state) => (caseId) => state.evidences.filter((e) => e.caseId === caseId),
        getAllEvidences: (state) =>  () => state.evidences,
    },
    actions: {
        bindEvents(socket) {
            socket.on("connect", async () => {
                this.evidences = await this.fetchAllEvidences();
                console.log(`[evidStore/socket-connected]: \n${JSON.stringify(this.evidences)}\n`);
                this.connected = true;
            });
            socket.on("disconnect", () => {
                this.connected = false;
            });
            socket.on("evidence:created", (instance) => {
                console.log(`[evidenceStore] created: ${instance}`)
                this.evidences.push(instance);
            });
            socket.on("evidence:updated", (instance) => {
                console.log(`[evidenceStore] updated: ${instance}`);
                const index = this.evidences.findIndex((c) => c.evidId === instance.evidId);
                if (index !== -1) {
                    this.evidences[index] = instance;
                }
            });
            socket.on("evidence:destroyed", (instance) => {
                // console.log("on evidence:destroyed", instance);
                this.evidences = this.evidences.filter((c) => c.evidId !== instance.evidId);
            })
        },
        // receive
        async fetchAllEvidences() {
            try {
                const evidences = await fetchWrapper.get(`${API_URL}/evidence`);
                console.log("getAllEvidences", evidences);
                if (evidences) {
                    return evidences;
                } else {
                    console.log(evidences)
                    throw new Error('Failed to get evidences');
                }
            } catch (error) {
                console.error(error);
            }
        },
        // TODO: not works
        // async fetchEvidencesForCaseId(caseId) {
        //     try {
        //         const response = await fetchWrapper.get(`${API_URL}/case/${caseId}/evidence`);
        //         if (response.ok) {
        //             const evidenceData = await response.json();
        //             return evidenceData;
        //         } else if (response.status === 404) {
        //             throw new Error('Evidence not found');
        //         } else {
        //             throw new Error('Failed to get evidence');
        //         }
        //     } catch (error) {
        //         console.error(error);
        //     }
        // },
        // createEvidence not needed. It is handled by the upload process
        // updateEvidence not needed.
        async deleteEvidence(id) {
            try {
                await fetchWrapper.delete(`${API_URL}/evidence/${id}`);
                return {success: true};
            } catch (error) {
                return {success: false, error: error};
            }
        },
    },
});
