<style scoped>
/* toggle btn */
.toggle-btn-input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.toggle-btn-label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: var(--grey-300);
    display: block;
    border-radius: 100px;
    position: relative;
}

.toggle-btn-label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 30px;
    transition: 0.3s;
}

.toggle-btn-input:checked+.toggle-btn-label {
    background: var(--primary-500);
}

.toggle-btn-input:checked+.toggle-btn-label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.toggle-btn-label:active:after {
    width: 24px;
}

/* icons */
.user-icon-card {
    width: 60px;
    height: 60px;
}

/* lend from casetable.vue BUT I HAD TO CHANGE STUFF!! */
.cT {}

.pcT {
    color: rgb(94, 97, 101);
    margin: 0;
    font-size: 0.8125rem;
    font-weight: 500;
}

.cT .header {
    display: flex;
    border-bottom: solid 1px #00000010;
    margin-top: 25px;
}

.cT .header p {
    margin: 0;
    font-size: 0.8125rem;
    font-weight: 500;
}

.cT .header p.case {
    flex: 1;
    font-weight: 700;
}

.cT .header p:not(.case) {
    flex: 1;
}

.cT .item {
    cursor: pointer;
    display: flex;
    border-bottom: solid 1px #00000010;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.cT .item:hover {
    background: rgba(0, 0, 0, 0.042) !important;
}

.cT .item section {
    color: rgb(94, 97, 101);
    margin: 0;
    font-size: 0.8125rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
}

.cT .item section.case {
    flex: 1;
    gap: 15px !important;
}

.cT .item section:not(.case) {
    flex: 1;
}

.cT .item section.flag {
    width: 100px;
}

.icon-size {
    width: 14px;
    height: 23px;
}

.redflag {
    background: #ff000003 !important;
}


.circular-progress {
    --size: 30px;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 3px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * pi * 2);
    --dash: calc((var(--progress) * var(--circumference)) / 100);
}

.circular-progress circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: round;
}

.circular-progress circle.bg {
    stroke: #ddd;
}

.circular-progress circle.fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    transition: stroke-dasharray 0.3s linear 0s;
    stroke: var(--primary-500);
}
</style>

<template>
    <div class="d-flex align-items-center justify-content-between border-radius-0-5-rem mb-2">
        <div :id="`user-card-title-${props.userItem?.userId}`" class="d-flex align-items-center gap-2">
            <!-- icon -->
            <UserAvatar :isAdmin="props.userItem?.is_admin" :name="props.userItem?.name" />
            <!-- user name -->
            <div class="col gap-1 table-skeleton" v-if="props.loading">
                <div class="row-black" style="width: 10rem; height: 2rem;"></div>
                <div class="row-black" style="width: 7rem; height: 1rem"></div>
            </div>
            <div class="cursor-default d-flex flex-column" v-else>
                <h6 class="m-0">{{ props.userItem?.name }}</h6>
                <p class="text-xs m-0" v-if="props.userItem?.is_admin">Admin</p>
                <p class="text-xs m-0" v-else>User</p>
            </div>
        </div>
        <!-- buttons -->
        <div id="user-card-title-btns" class="d-flex align-items-center gap-2">
            <div v-if="props.loading" class="table-skeleton">
                <div class="row-black" style="width: 10rem; height: 2.4rem"></div>
            </div>
            <div id="user-control-asign-cases-btns" v-else-if="Array.from(allCaseNames).length > 0"
                :class="{ 'z-index-highest': tutorStep === 3 }">
                <button v-if="focusedUserId === ''" class="newbtn bg-white" @click="handleManageCase">
                    <ClipboardIcon class="icon-size-20px" />
                    <span class="hide-mobile">Manage Cases</span>
                </button>
                <button v-else class="newbtn text-white bg-blue-500" @click="handleManageCaseSave">
                    <span>Save</span>
                </button>
            </div>
            <div id="user-control-asign-cases-btns-no-case" v-else :class="{ 'z-index-highest': tutorStep === 4}">
                <button class="newbtn bg-white"
                    @click="handleNewCase">
                    <AddIcon class="icon-xs color-grey-900 bg-white me-2"></AddIcon>
                    <span>New Case</span>
                </button>
            </div>
        </div>
    </div>
    <!-- new table -->
    <div class="cT">
        <div class="" style="margin-top:20px;margin-bottom: -20px;">
            <div class="table-skeleton" v-if="props.loading">
                <div class="row-white" style="height: 1.7rem; width: 15rem;"></div>
            </div>
            <h6 v-else-if="focusedUserId !== ''" class="ms-1 text-font-weight-700 cursor-default"
                style="margin-bottom: 0;font-weight: 400 !important;font-size: 0.9rem">
                All Cases
            </h6>
            <h6 v-else-if="Array.from(allCaseNames).length > 0 && Array.from(selectedCaseNames).length > 0"
                class="ms-1 text-font-weight-700 cursor-default" style="margin-bottom: 0;font-weight: 400 !important;font-size: 0.9rem">
                Assigned Cases
            </h6>
            <span
                v-if="focusedUserId === '' && Array.from(allCaseNames).length > 0 && Array.from(selectedCaseNames).length <= 0"
                class="cursor-default">
                Please assign a case to this user.
            </span>
            <div class="cursor-default" v-if="Array.from(allCaseNames).length === 0">
                <span v-if="props.userItem?.is_admin">No case found in your database. Please create a new case.</span>
                <span v-else>Please create a new case. Then asign the case for this user.</span>
            </div>
        </div>
        <div class="header p-2">
            <p v-if="focusedUserId !== ''" style="display: flex;width:50px;margin-right:10px;flex:0 !important">
                <input class="toggle-btn-input" type="checkbox" id="switch-all-none" :checked="allSelected"
                    @change="toggleAllOrNoneCase" />
                <label class="toggle-btn-label m-0" for="switch-all-none"></label>
            </p>
            <p class="case">Case</p>
            <p>Lead</p>
            <p>Status</p>
        </div>
        <div class="tabl-content">
            <div class="item p-2" v-for="caseItem in caseItems" :key="caseItem.createdAt"
                :class="{ 'redflag': !caseItem.DbInitProcess || caseItem.DbInitProcess?.state === 'failed', '': caseItem.DeleteCaseProcessId }">
                <section v-if="focusedUserId !== ''" style="width:50px;margin-right:10px;flex:0 !important">
                    <div :id="`accessible-cases-toggle-single-case-${caseItem.caseId}`" class="cursor-default">
                        <div class="d-flex justify-content-end">
                            <input class="toggle-btn-input" type="checkbox" :id="`switch-${caseItem.caseId}`"
                                :checked="selectedCaseNames.has(caseItem.caseName)"
                                @change="updateSelectedCaseNames(caseItem.caseName)" />
                            <label class="toggle-btn-label m-0" :for="`switch-${caseItem.caseId}`"></label>
                        </div>
                    </div>
                </section>
                <section class="case">
                    <span>
                        <ExclamationWarningIcon
                            v-if="!caseItem.DbInitProcess || caseItem.DbInitProcess?.state === 'failed'"
                            class="icon-size-18px" style="color: var(--red-500);" />

                        <InboxIcon v-else class="icon-size-18px" />
                    </span>
                    <span style="color:black;" class="text-truncate">{{ caseItem.caseName }}</span>
                </section>

                <section class="flag">
                    <span class="icon-size">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                            <path fill-rule="evenodd"
                                d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0Zm-5-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM8 9c-1.825 0-3.422.977-4.295 2.437A5.49 5.49 0 0 0 8 13.5a5.49 5.49 0 0 0 4.294-2.063A4.997 4.997 0 0 0 8 9Z"
                                clip-rule="evenodd" />
                        </svg>
                    </span>
                    <span class="text-truncate">{{ caseItem.responsiblePerson }}</span>
                </section>
                <section class="flag">
                    <div v-if="caseItem.Evidence?.length != 0 && !caseItem.DeleteCaseProcessId"
                        class="d-flex align-items-center gap-2 align-items-start">
                        <div class="progress-circle-container">
                            <svg width="25" height="25" viewBox="0 0 30 30" class="circular-progress"
                                :style="{ '--progress': getEvidenceProgress(caseItem) }">
                                <circle class="bg" cx="50" cy="50" r="45"></circle>
                                <circle class="fg" cx="50" cy="50" r="45"></circle>
                            </svg>
                        </div>
                        <p class="m-0 pcT">
                            {{ getEvidenceProgress(caseItem) }}%
                        </p>
                    </div>
                    <!-- deleting case / failed case -->

                    <div v-else-if="caseItem.DeleteCaseProcessId">
                        <span class="status-removing" style="">
                            <svg data-v-0b6eab7f="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke-width="1.5" stroke="currentColor"
                                class="size-6 spin-right-round icon-size-15px color-grey-400">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99">
                                </path>
                            </svg>
                            Pending removal</span>
                    </div>
                    <div v-else-if="!caseItem.DbInitProcess || caseItem.DbInitProcess?.state === 'failed'"
                        class="color-red-500">
                        <span>Case not created</span>
                    </div>
                </section>

            </div>
        </div>

    </div>
    <!-- SKELETON -->

    <div v-if="caseItems.length === 0" class="table-skeleton mt-2">
        <!-- skeleton loader -->
        <div class="row-black" style="height: 2.4rem;"></div>
        <div class="row-white" style="height: 2.4rem;"></div>
        <div class="row-black" style="height: 2.4rem;"></div>
        <div class="row-white" style="height: 2.4rem;"></div>
        <div class="row-black" style="height: 2.4rem;"></div>
        <div class="row-white" style="height: 2.4rem;"></div>
    </div>

</template>

<script setup>
import { ref, watch, computed, watchEffect, onMounted } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import { useUserStore } from '@/store/userStore';
import { useCaseStore } from '@/store/caseStore';
import tutorialLandingStore from '@/store/frontend/tutorialLandingStore.js';
import UserAvatar from '../widgets/UserAvatar.vue';
// icons
import AddIcon from '../icons/AddIcon.vue';
import ClipboardIcon from '@/components/icons/ClipboardIcon.vue';
import InboxIcon from '../icons/InboxIcon.vue'
import ExclamationWarningIcon from '../icons/ExclamationWarningIcon.vue';

const props = defineProps({
    userItem: {
        type: Object,
        required: true,
    },
    loading: { type: Boolean, required: true },
});
const emit = defineEmits(['refresh']);

const { tutorStep, setTutorialStep, setContinueFromStep, continueTutor, setRestartTutor } = tutorialLandingStore;

const store = useStore();
const userStore = useUserStore();
const caseStore = useCaseStore();
const selectedCaseNames = ref(null);
const caseItems = ref([]);

// computed
const focusedUserId = computed(() => store.state.userControlFocus);
const allCaseNames = computed(() => new Set(caseStore.cases.map(caseItem => caseItem.caseName)));
const allSelected = computed(() => {
    const allNames = allCaseNames.value;
    return selectedCaseNames.value.size === allNames.size && [...selectedCaseNames.value].every(name => allNames.has(name));
});

watch(() => props.userItem?.Cases, (newCases) => {
    if (newCases?.length > 0) {
        selectedCaseNames.value = new Set(newCases.map(caseItem => caseItem.caseName));
    } else {
        selectedCaseNames.value = new Set();
    }
}, { immediate: true });

watchEffect(async () => {
    if (focusedUserId.value) {
        caseItems.value = caseStore.cases; // Assuming caseStore.cases is available and reactive
    } else {
        if (props.userItem?.Cases && props.userItem?.Cases.length > 0) {
        let casesFromStore = [];
        for (const item of props.userItem.Cases) {
            try {
            const caseDetail = await caseStore.getCaseById(item.caseId);
            casesFromStore.push(caseDetail);
            } catch (error) {
            console.error('Failed to fetch case details:', error);
            // Handle error, e.g., by pushing a placeholder or error message
            }
        }
        caseItems.value = casesFromStore;
        } else {
        caseItems.value = [];
        }
    }
});

// methods
const toggleAllOrNoneCase = () => {
    if (allSelected.value) {
        selectedCaseNames.value = new Set();
    } else {
        selectedCaseNames.value = new Set(allCaseNames.value);
    };
};

// const navigateToAddEvidence = (caseItem) => {
//     const id = caseItem.caseId;
//     if (!caseItem.DeleteCaseProcessId) {
//         router.push(`/new-evidence/${id}`);
//     }
// };

const getEvidenceProgress = (caseItem) => {
    const totalEvidence = caseItem.Evidence?.length || 0;
    const processedEvidence = caseItem.Evidence?.filter(ev => ev.ProcessingProcess?.state === 'success' || ev.ProcessingProcess?.state === 'failed').length || 0;
    return totalEvidence ? Math.round((processedEvidence / totalEvidence) * 100) : 0;
};

const updateSelectedCaseNames = (toggledCaseName) => {
    const _updateSet = new Set(selectedCaseNames.value);
    if (_updateSet.has(toggledCaseName)) {
        _updateSet.delete(toggledCaseName);
    } else {
        _updateSet.add(toggledCaseName);
    }
    selectedCaseNames.value = _updateSet;
};

const handleManageCase = () => {
    store.state.userControlFocus = props.userItem?.userId;
    if (tutorStep.value === 3) {
        setTutorialStep(-1);
        userStore.updateTutorialDone(true);
        setRestartTutor(false);
        if (continueTutor.value) {
            setContinueFromStep(false);
        };
    }
};

const handleManageCaseSave = async () => {
    store.state.userControlFocus = '';
    await userStore.updateUser(props.userItem?.userId, { access: Array.from(selectedCaseNames.value) });
    emit('refresh');
};

const handleNewCase = () => {
    store.dispatch('openCaseModal');
    router.push('/case-overview/new-case');
    if (tutorStep.value === 4) {
        setTutorialStep(3);
        setContinueFromStep(3);
    }
}

onMounted(() => {
    if(tutorStep.value === 3 && caseStore.cases.length === 0) {
        setTutorialStep(4);
        setContinueFromStep(4);
    } 
});

defineExpose({
    handleManageCaseSave
});

</script>