<template>
    <div class="modal"
        :class="displayModalClass">

        <div class="modal-dialog modal-lg modal-dialog-centered">

            <div class="modal-content p-2 rounded">
                <div class="modal-header">
                    <div>
                        <h6 class="text-font-weight-700er text-lg ms-2">
                            <span v-if="languageEn">General terms and conditions</span>
                            <span v-else>Allgemeine Geschäftsbedingungen</span>
                        </h6>
                    </div>
                    <div id="gtc-agb-flags">
                        <flag-uk @click="setLanguageEn"
                            class="cursor-pointer"
                            :class="languageEn? '' : 'p-1'" />
                        <flag-de @click="setLanguageDe"
                            class="cursor-pointer"
                            :class="languageEn ? 'p-1' : ''"/>

                    </div>
                </div>
                
                <div class="modal-body overflow-y-auto cusor-default text-sm">
                    <div id="gtc-en" class="cusor-default" v-if="languageEn">
                        <ol class="main-list gtc-abg-padding">
                            <li>
                                Scope, Changes
                                <ol>
                                    <li class="mt-2">The following General Terms and Conditions (GTC) govern all services provided by Sinabis GmbH (hereinafter referred to as "Sinabis").</li>
                                    <li class="mt-2">Sinabis provides all services exclusively in accordance with these GTC. This provision remains eﬀective even if the customer uses their own general terms and conditions that contain conﬂicting or deviating conditions from those listed here. In the event of awareness of conﬂicting or deviating conditions from the customer that deviate from those listed in these GTC, exclusively the GTC provided by Sinabis apply, provided that Sinabis executes the order without reservation.</li>
                                    <li class="mt-2">If we process personal data as a data processor in accordance with Art. 28 GDPR for you, an agreement on data processing is eﬀective in addition to these General Terms and Conditions.</li>
                                    <li class="mt-2">Sinabis reserves the right to change these General Terms and Conditions with due consideration of the customer's legitimate interest and with reasonable notice. If the customer does not object to the change within the deadline set by Sinabis, the change is considered approved.</li>

                                </ol>
                            </li>
                            <li class="mt-3">
                                Service of Sinabis
                                <ol>
                                    <li class="mt-2">The scope of each service is determined by the description of services current at the time of the order.</li>
                                    <li class="mt-2">Servers and data pathways up to the handover point to the screening solution are available, on average annually, with a minimum availability of 97%. This excludes times when the relevant servers are not accessible due to technical or other issues beyond Sinabis's control (such as force majeure or third-party fault).</li>
                                    <li class="mt-2">Sinabis is authorized to modify its services and, at its reasonable discretion according to § 315 of the German Civil Code (BGB), adjust its prices. The price adjustment is limited to the extent of the service changes and can lead to both an increase and a decrease in the prices to be paid by the customer.</li>
                                    <li class="mt-2">In the case of the continuous provision of a digital product, Sinabis reserves the right to modify digital products not only to maintain contractual compliance but also for reasons of technological progress, security, technical availability including support from the provider or manufacturer, and for the stable operation and integrity of Sinabis systems. Sinabis may add, disable, or modify individual features, applications,scripts, apps, links, and programs. Such adjustments do not result in additional costs for the customer.</li>
                                    <li class="mt-2">The provision of services (in whole or in part) to anonymous third parties is prohibited.</li>
                                    <li class="mt-2">In the case of defects, the statutory warranty provisions apply.</li>
                                    <li class="mt-2">For certain additional services, Sinabis incurs costs which are passed on to our customers in the form of a service fee.</li>
                                </ol>
                            </li>
                            <li class="mt-3">
                                Copyrights, Retention of Ownership
                                <ol>
                                    <li class="mt-2">Sinabis grants the customer a simple right of use for the provided programs, applications, scripts, and apps, limited in time to the duration of the respective contractual relationship. Granting usage rights to third parties, particularly the sale, is prohibited. After the termination of the contractual relationship, the customer shall neither continue to use nor retain copies of the provided software.</li>
                                    <li class="mt-2">Sinabis retains ownership rights to hardware and other goods until the corresponding invoice is fully settled.</li>
                                </ol>
                            </li>
                            <li class="mt-3">
                                Payment Terms
                                <ol>
                                    <li class="mt-2">Non-usage-based fees are due a?er the completion of the respective billing period. Usage-based fees are based on the current price list determined by Sinabis at its reasonable discretion. Customer payments are made by bank transfer.</li>
                                    <li class="mt-2">Sinabis reserves the right to adjust the prices to be paid, at its reasonable discretion according to § 315 of the German Civil Code (BGB), a maximum of once a year, in line with the development of costs relevant to price formation. The cost factors relevant to price formation include, in particular, technology costs, costs for the operation of services, customer support costs, personnel and service costs, energy costs, overhead costs, as well as government-imposed fees, taxes, levies, and contributions. The price development of these cost factors can lead to both an increase and a decrease in the prices to be paid by the customer. Price adjustments are limited to the extent of the changes in the cost factors.</li>
                                </ol>
                            </li>
                            <li class="mt-3">
                                Customer Obligations
                                <ol>
                                    <li class="mt-2">Sinabis provides all services exclusively based on these General Terms and Conditions (GTC). This is binding even if the customer uses their own general terms and conditions that contain different conditions. Contracts are exclusively processed using the GTC provided by Sinabis, even if Sinabis, with knowledge of conflicting or deviating conditions from the customer, executes the order without reservation.</li>
                                    <li class="mt-2">As soon as Sinabis processes personal data on behalf of the customer in accordance with Art. 28 GDPR, an agreement for data processing automatically comes into effect between Sinabis and the customer.</li>
                                </ol>
                            </li>
                            <li class="mt-3">
                                Liability of Sinabis
                                <ol>
                                    <li class="mt-2">Sinabis is liable exclusively for intent and gross negligence of Sinabis or its vicarious agents in accordance with Section 6.1. In the case of a significant breach of contractual obligations (cardinal duties) that jeopardizes the purpose of the contract, the liability is limited to the typical, foreseeable damage at the time of contract conclusion, unless the breach of duty is intentional or grossly negligent.</li>
                                    <li class="mt-2">This limitation does not apply in the event of a violation of life, body, and/or health and in the case of liability under the Product Liability Act.</li>
                                    <li class="mt-2">If the customer acts as an entrepreneur, legal entity under public law, or public-law special fund, Sinabis's liability in the case of intent and gross negligence is limited to the amount of contractual fees paid to Sinabis within the last two years before the harmful event, which were paid to Sinabis within the specific contractual relationship.</li>
                                </ol>
                            </li>
                            <li class="mt-3">
                                Term of Contract, Termination
                                <ol>
                                    <li class="mt-2">The contract can be terminated with a notice period of 30 days.</li>
                                </ol>
                            </li>
                            <li class="mt-3">Revocation Instruction</li>
                        </ol>
                        <div class="gtc-abg-padding ms-3 me-2 text-sm"
                            style="text-align: justify">
                            <p class="text-font-weight-700" id="gtc-right-of-withdrawal">Right of Withdrawal</p>
                            <p class="ms-3 mt-2">You have the right to withdraw from this contract within fourteen days without stating any reasons.</p>
                            <p class="ms-3 mt-2">The withdrawal period is fourteen days from the day of concluding the contract.</p>
                            <p class="ms-3 mt-2">To exercise your right of withdrawal, you must inform us 
                                <span style="text-decoration: underline;">(Sinabis GmbH, Auf der Krautweide 24, 65812 Bad Soden am Taunus, Email: office@sinabis.com)</span>
                                by means of a clear statement (e.g., a letter sent by post or email) about your decision to withdraw from this contract. You may use the attached sample withdrawal form, but it is not mandatory.</p>
                            <p class="ms-3 mt-2">To meet the withdrawal deadline, it is sufficient for you to send your communication concerning your exercise of the right of withdrawal before the withdrawal period has expired.</p>
                            <p class="text-font-weight-700 mt-2" id="gtc-consequences-of-withdrawal">Consequences of Withdrawal</p>
                            <p class="ms-3 mt-2">If you withdraw from this contract, we will reimburse you for all payments received from you, including delivery costs (with the exception of additional costs resulting from your choice of a type of delivery other than the least expensive standard delivery offered by us), without undue delay and at the latest within fourteen days from the day on which we receive notice of your withdrawal from this contract. We will carry out such reimbursement using the samemeans of payment as you used for the initial transaction, unless expressly agreed otherwise; in any event, you will not incur any fees as a result of such reimbursement.</p>
                            <p class="ms-3 mt-2">You must return or hand over the goods to us (SINABIS GmbH, Auf der Krautweide 24, 65812 Bad Soden am Taunus) immediately and in any event no later than fourteen days from the day on which you notify us of your withdrawal from this contract. The deadline is met if you send back the goods before the period of fourteen days has expired. You bear the direct cost of returning the goods.</p>
                            <p class="ms-3 mt-2">You are only liable for any diminished value of the goods resulting from the handling other than what is necessary to establish the nature, characteristics, and functioning of the goods.</p>
                            <p class="ms-3 mt-2">If you have requested that the services begin during the withdrawal period, you shall pay us an amount which is in proportion to what has been provided until you have communicated to us your withdrawal from this contract, in comparison with the full coverage of the contract.</p>
                            <p class="text-font-weight-700 mt-2" id="gtc-sample-withdrawal-form">Sample Withdrawal Form</p>
                            <p class="ms-3 mt-2">(If you want to withdraw from the contract, please fill out this form and send it back.)</p>
                            <p class="ms-3 mt-2">To:</p>
                            <p class="ms-3 text-font-weight-700">SINABIS GmbH Auf der Krautweide 24</p>
                            <p class="ms-3 text-font-weight-700">65812 Bad Soden am Taunus</p>
                            <p class="ms-3 text-font-weight-700">Email: office@sinabis.com</p>
                            <p class="ms-3 mt-2">I/we(*) hereby withdraw from the contract concluded by me/us(*) for the provision of the following service Ordered on(*) / received on(*):</p>
                            <p class="ms-3 mt-2">Name of consumer(s):</p>
                            <p class="ms-3">Address of consumer(s):</p>
                            <p class="ms-3">Signature of consumer(s) (only for communication on paper)</p>
                            <p class="ms-3">Date:</p>
                            <p class="ms-3 mt-2">(*) Delete as appropriate.</p>
                            <p class="text-font-weight-700 mt-2" id="gtc-end-of-sample-withdrawal-form">End of Sample Withdrawal Form</p>
                            <p class="ms-3 mt-2">The right of withdrawal does not apply if the transaction can be attributed to your commercial or professional activity.</p>
                        </div>
                    </div>
                    <div id="gtc-de" class="cursor-default" v-else>
                        <ol class="main-list gtc-abg-padding">
                            <li>
                                Geltungsbereich, Änderungen
                                <ol>
                                    <li class="mt-2">Die nachstehenden Allgemeinen Geschäftsbedingungen (AGB) regeln sämtliche Leistungen der Sinabis GmbH (im Folgenden als "Sinabis" bezeichnet).</li>
                                    <li class="mt-2">Sinabis erbringt sämtliche Leistungen ausschließlich in Übereinstimmung mit diesen AGB. Diese Regelung bleibt wirksam, selbst wenn der Kunde eigene Allgemeine Geschäftsbedingungen verwendet, die gegenstehende oder von den hier aufgeführten AGB abweichende Bedingungen enthalten. Im Falle einer Kenntnisnahme von entgegenstehenden oder abweichenden Bedingungen des Kunden, die von den hier aufgeführten AGB abweichen, gelten dennoch ausschließlich die von Sinabis bereitgestellten AGB, sofern Sinabis den Auftrag ohne Vorbehalt ausführt.</li>
                                    <li class="mt-2">Zusätzlich zu diesen Allgemeinen Geschäftsbedingungen von Sinabis ﬁnden für bestimmte Produkte ergänzende spezielle Bedingungen Anwendung. Diese speziellen Bedingungen behalten ihre Gültigkeit, selbst wenn das betreﬀende Produkt lediglich ein Bestandteil eines vom Kunden gebuchten Pakets ist, das auch andere Leistungen umfasst.</li>
                                    <li class="mt-2">Sofern wir personenbezogene Daten als Auftragsverarbeiter gemäß Art. 28 DSGVO für Sie verarbeiten, ist neben diesen Allgemeinen Geschäftsbedingungen auch eine Vereinbarung zur Auftragsdatenverarbeitung wirksam.</li>
                                    <li class="mt-2">Sinabis behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen unter Berücksichtigung des berechtigten Interesses des Kunden mit einer angemessenen Ankündigungsfrist zu ändern. Sofern der Kunde der Änderung nicht innerhalb der von Sinabis festgelegten Frist widerspricht, gilt die Änderung als genehmigt.</li>
                                </ol>
                            </li>
                            <li class="mt-3">
                                Leistungen der Sinabis
                                <ol>
                                    <li class="mt-2">Der Leistungsumfang der einzelnen Leistungen ergibt sich aus der zum Zeitpunkt der Bestellung aktuellen Beschreibung der Leistungen.</li>
                                    <li class="mt-2">Die Server und Datenwege bis zum Übergabepunkt an die Sichtungslösung stehen im Jahresmittel mit einer Verfügbarkeit von mindestens 97% zur Verfügung. Ausgenommen hiervon sind Zeiten, in denen die betreﬀenden Server aufgrund von technischen oder sonstigen Problemen, die nicht im Einﬂussbereich von Sinabis liegen (wie höhere Gewalt oder Verschulden Dritter), nicht erreichbar sind.</li>
                                    <li class="mt-2">Sinabis ist berechtigt, Ihre Leistungen zu ändern und kann nach billigem Ermessen gem. 315 BGB Ihre Preise anpassen. Die Preisanpassung ist auf dem Umfang der Leistungsänderungen begrenzt. Die Preisanpassung kann sowohl zur Steigerung als auch zur Senkung der vom Kunden zu zahlenden Preise führen.</li>
                                    <li class="mt-2">Bei einer dauerhaften Bereitstellung eines digitalen Produkts behält sich Sinabis das Recht vor, die digitalen Produkte nicht nur zur Aufrechterhaltung der Vertragsmäßigkeit, sondern auch aus Gründen des technischen Fortschritts, der Sicherheit, der technischen Verfügbarkeit einschließlich des Supports von Anbieter- oder Herstellerseite sowie aus Gründen des stabilen Betriebs und der Integrität der Sinabis-Systeme zu modiﬁzieren. Sinabis kann dabei einzelne Features, Anwendungen, Skripte, Apps, Links und Programme hinzufügen, deaktivieren oder verändern. Durch derartige Anpassungen entstehen dem Kunden keine zusätzlichen Kosten.</li>
                                    <li class="mt-2">Eine Nutzungsüberlassung von Dienstleistungen (ganz oder teilweise) an anonyme Dritte ist untersagt.</li>
                                    <li class="mt-2">Bei Mängeln gelten die gesetzlichen Mängelgewährleistungsregelungen.</li>
                                    <li class="mt-2">Für einige zusätzliche Dienste entstehen Sinabis Kosten, die wir an unsere Kunden in Form einer Servicegebühr weitergeben.</li>
                                </ol>
                            </li>
                            <li class="mt-3">
                                Urheberrechte, Eigentumsvorbehalt
                                <ol>
                                    <li class="mt-2">Sinabis gewährt dem Kunden ein einfaches Nutzungsrecht an den bereitgestellten Programmen, Anwendungen, Skripten und Apps, das zeitlich auf die Laufzeit des entsprechenden Vertragsverhältnisses beschränkt ist. Es ist untersagt, Dritten Nutzungsrechte einzuräumen, insbesondere ist die Veräußerung nicht gestattet. Nach Beendigung des Vertragsverhältnisses wird der Kunde Kopien der überlassenen Software weder weiterverwenden noch behalten.</li>
                                    <li class="mt-2">Die Eigentumsrechte an Hardware und anderen Waren behält sich Sinabis bis zur vollständigen Begleichung der entsprechenden Rechnung vor.</li>
                                </ol>
                            </li>
                            <li class="mt-3">
                                Zahlungsbedingungen
                                <ol>
                                    <li class="mt-2">Die nutzungsunabhängigen Entgelte werden nach Abschluss des jeweiligen Abrechnungszeitraums fällig. Die nutzungsabhängigen Entgelte orientieren sich an der jeweils aktuellen Preisliste, die Sinabis nach billigem Ermessen festlegt. Die Zahlungen des Kunden erfolgen mittels Banküberweisung.</li>
                                    <li class="mt-2">Sinabis behält sich das Recht vor, maximal einmal jährlich die zu zahlenden Preise nach billigem Ermessen gemäß § 315 BGB an die Entwicklung der für die Preisbildung maßgeblichen Kosten anzupassen. Zu den Kostenfaktoren, die für die Preisbildung relevant sind, zählen insbesondere die Technikkosten, die Kosten für den Betrieb der Leistungen, die Kosten für die Kundenbetreuung, Personal- und Dienstleistungskosten, Energiekosten, Gemeinkosten sowie hoheitlich auferlegte Gebühren, Steuern, Abgaben und Beiträge. Die Preisentwicklung dieser Kostenfaktoren kann sowohl zu einer Steigerung als auch zu einer Senkung der vom Kunden zu zahlenden Preise führen. Eine Preisanpassung ist dabei auf den Umfang der Änderungen der Kostenfaktoren begrenzt.</li>
                                    <li class="mt-2">Der Kunde gerät auch ohne Mahnung in Verzug, wenn er den fälligen Betrag nicht innerhalb von zehn Kalendertagen ab Rechnungszugang leistet. Entscheidend ist, dass dieser Betrag innerhalb dieser Frist auf dem in der Rechnung angegebenen Konto bei Sinabis eingegangen ist.</li>
                                    <li class="mt-2">Sinabis behält sich das Recht vor, im Rahmen der Billigkeit die Erbringung ihrer Dienste zu sperren, solange die fällige Vergütung nicht geleistet wurde.</li>
                                </ol>
                            </li>
                            <li class="mt-3">
                                Pflichten des Kunden
                                <ol>
                                    <li class="mt-2">Sinabis erbringt sämtliche Leistungen ausschließlich auf Basis dieser Allgemeinen Geschäftsbedingungen (AGB). Dies gilt auch dann verbindlich, wenn der Kunde eigene Allgemeine Geschäftsbedingungen verwendet, die abweichende Bedingungen enthalten. Verträge werden ausschließlich unter Anwendung der von Sinabis gestellten AGB abgewickelt, selbst wenn Sinabis in Kenntnis entgegenstehender oder abweichender Bedingungen des Kunden den Auftrag vorbehaltlos ausführt.</li>
                                    <li class="mt-2">Sobald SINABIS personenbezogene Daten im Auftrag des Kunden gemäß Art. 28 DSGVO verarbeitet, kommt automatisch eine Vereinbarung zur Auftragsverarbeitung zwischen SINABIS und dem Kunden zustande.</li>
                                </ol>
                            </li>
                            <li class="mt-3">
                                Haftung der Sinabis
                                <ol>
                                    <li class="mt-2">Sinabis haftet ausschließlich bei Vorsatz und grober Fahrlässigkeit von Sinabis oder seinen Erfüllungsgehilfen gemäß Punkt 6.1. Bei einer wesentlichen Verletzung von Vertragspflichten (Kardinalpflicht), die den Vertragszweck in einer die Erfüllung gefährdenden Weise beeinträchtigt, beschränkt sich die Haftung auf den typischen, vorhersehbaren Schaden zum Zeitpunkt des Vertragsschlusses, es sei denn, die Pflichtverletzung erfolgt vorsätzlich oder grob fahrlässig.</li>
                                    <li class="mt-2">Diese Beschränkung gilt nicht bei einer Verletzung von Leben, Körper und/oder Gesundheit und bei einer Haftung nach dem Produkthaftungsgesetz.</li>
                                    <li class="mt-2">Wenn der Kunde als Unternehmer, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen agiert, ist die Haftung von Sinabis im Falle von Vorsatz und grober Fahrlässigkeit auf die Höhe der innerhalb der letzten zwei Jahre vor dem schädigenden Ereignis gezahlten vertraglichen Entgelte beschränkt, die im Rahmen des konkreten Vertragsverhältnisses an Sinabis geleistet wurde.</li>
                                </ol>
                            </li>
                            <li class="mt-3">
                                Vertragslaufzeit, Kündigung
                                <ol>
                                    <li class="mt-2">Der Vertrag ist mit einer Frist von 30 Tagen kündbar.</li>
                                </ol>
                            </li>
                            <li class="mt-3">Widerrufsbelehrung</li>
                            <div class="gtc-abg-padding ms-3 me-2 text-sm" 
                                style="text-align: justify">
                                <p class="text-font-weight-700" id="gtc-right-of-withdrawal">Widerrufsrecht</p>
                                <p class="ms-3 mt-2">Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</p>
                                <p class="ms-3 mt-2">Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.</p>
                                <p class="ms-3 mt-2">
                                    Um Ihr Widerrufsrecht auszuüben, müssen Sie uns <span style="text-decoration: underline;">(Sinabis GmbH, Auf der Krautweide 24, 65812 Bad Soden am Taunus, Email: office@sinabis.com)</span> mittels einer eindeuEgen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
                                </p>
                                <p class="ms-3 mt-2">Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
                                <p class="text-font-weight-700 mt-2" id="gtc-consequences-of-withdrawal">Folgen des Widerrufs</p>
                                <p class="ms-3 mt-2">Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieserRückzahlung Entgelte berechnet.</p>
                                <p class="ms-3 mt-2">Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns (SINABIS GmbH, Auf der Krautweide 24, 65812 Bad Soden am Taunus) zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.</p>
                                <p class="ms-3 mt-2">Sie müssen für einen etwaigen Wertverlust der Waren nur autommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaﬀenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.</p>
                                <p class="ms-3 mt-2">Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.</p>
                                <p class="text-font-weight-700 mt-2" id="gtc-sample-withdrawal-form">Muster-Widerrufsformular</p>
                                <p class="ms-3 mt-2">(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</p>
                                <p class="ms-3 mt-2">An:</p>
                                <p class="ms-3 text-font-weight-700">SINABIS GmbH Auf der Krautweide 24</p>
                                <p class="ms-3 text-font-weight-700">65812 Bad Soden am Taunus</p>
                                <p class="ms-3 text-font-weight-700">Email: office@sinabis.com</p>
                                <p class="ms-3 mt-2">Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistung</p>
                                <p class="ms-3 mt-2">Bestellt am (*)/erhalten am (*):</p>
                                <p class="ms-3">Name des/der Verbraucher(s):</p>
                                <p class="ms-3">Anschrift des/der Verbraucher(s):</p>
                                <p class="ms-3">Unterschrift des/der Verbraucher(s)</p>
                                <p class="ms-3">(nur bei Mitteilung auf Papier)</p>
                                <p class="ms-3">Datum:</p>
                                <p class="ms-3 mt-2">(*) Unzutreﬀendes streichen.</p>
                                <p class="text-font-weight-700 mt-2" id="gtc-end-of-sample-withdrawal-form">Ende des Muster-Widerrufsformulars</p>
                                <p class="ms-3 mt-2">Das Widerrufsrecht gilt nicht, sofern das Rechtsgeschäft Ihrer gewerblichen oder beruﬂichen Tätigkeit zugerechnet werden kann.</p>
                            </div>
                        </ol>
                    </div>

                </div>

                <div class="modal-footer d-flex justify-content-center" style="padding: 0.2rem!important;">
                    <span class="hover-text-underline-primary cursor-pointer py-1 px-5 cursor-pointer" @click="closeGtcAgbModal">close</span>
                </div>

            </div>
        </div>

    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import FlagDe from '@/components/icons/FlagDe.vue';
import FlagUk from '@/components/icons/FlagUk.vue';

const store = useStore();

const languageEn = ref(true);

const displayModalClass = computed(() => store.state.showGTC? 'show display-block bgc-modal-overlay' : 'fade');

const setLanguageEn = () => {
    languageEn.value = true;
}

const setLanguageDe = () => {
    languageEn.value = false;
}

const closeGtcAgbModal = () => {
    languageEn.value = true;
    store.state.showGTC = false;
}

</script>

<style scoped>
/* side-nav-bar has z-index: 9999 */
.modal-header {
    padding: 0.5rem 0rem 0.85rem 0;
    position: sticky;
    top: 0;
    background-color: #FFF;
    z-index: 10;
}

.overflow-y-auto {
    overflow-y: auto;
}

.modal-body {
    height: 80vh;
    max-height: 90vh;
}

.gtc-abg-padding {
    padding: 0 1rem;
}

ol {
  counter-reset: section; /* Creates a new instance of the counter at each <ol> element */
  list-style-type: none; /* Removes default list style */
}

ol.main-list > li {
  counter-increment: section; /* Increments the main list counter */
  font-weight: font-weight-700;
  cursor: default;
}

ol.main-list > li:before {
  content: counters(section, ".") " "; /* Adds the counter value before the list item */
}

ol.main-list > li > ol {
  counter-reset: subsection; /* Resets the subsection counter for each new sub-list */
  padding: 0 1rem;
}

ol.main-list > li > ol > li {
    counter-increment: subsection; /* Increments the subsection counter */
    font-weight: normal;
    cursor: default;
    text-align: justify;
}

ol.main-list > li > ol > li:before {
  content: counters(section, ".") "." counter(subsection) " "; /* Formats the subsection counter */
}

p {
    margin-bottom: 0;
    font-size: 0.875rem;
}
</style>