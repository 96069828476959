<template>
    <div class="bg-tutorial" v-if="tutorStep > 0 && tutorStep <= 4">
        <div class="position-absolute d-flex align-items-center z-index-highest"
            :class="{ 'bubble-pos-create-user': tutorStep === 1,
                'bubble-pos-select-user': tutorStep === 2,
                'bubble-pos-manage-case': tutorStep === 3 || tutorStep === 4 }">
            <div class="arrow-left hide-mobile" v-if="tutorStep <= 2"></div>
            <div class="bubble" @click="handleTutorialStep">
                <span v-if="tutorStep === 1">Please create a user to get started.</span>
                <span v-else-if="tutorStep === 2">Select the user to continue.</span>
                <span v-else-if="tutorStep === 3">Allocate cases to selected user.</span>
                <span v-else-if="tutorStep === 4">
                    Please create a case first
                </span>
            </div>
            <div class="arrow-right hide-mobile" v-if="tutorStep === 3 || tutorStep === 4"></div>
        </div>
    </div>
</template>

<script setup>
import tutorialLandingStore from '@/store/frontend/tutorialLandingStore.js';

const { tutorStep } = tutorialLandingStore;

const emit = defineEmits(['handle-tutorial-step'])

const handleTutorialStep = () => {
    emit('handle-tutorial-step');
}

</script>

<style scoped>
.bg-tutorial {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    position: absolute;
    z-index: 10;
}

.bubble {
    background: white;
    padding: 10px;
    width: fit-content;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.8125rem;
}

/* bubble position */ 
.bubble-pos-create-user {
    top: 3px;
  left: 29%;
}

.bubble-pos-select-user {
    top: 9.6rem; 
    left: 30%;
}

.bubble-pos-manage-case {
    top: 0.5rem;
    right: 10rem;
}

/* triangle arrow */
.arrow-left {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid white;
}

.arrow-right {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid white;
} 
</style>