<template>
    <SideModal currentview="Case Overview" :isOpen="isCaseModalOpen"
        @close="resetNewCaseSideModal"
        title="New Case" :desc="caseModalDesc">
        <StepOneMeta :newCaseForm="newCaseForm" @reset-form="resetNewCaseSideModal"/>
    </SideModal>
</template>

<script setup>
import { computed, ref, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import SideModal from '@/components/widgets/SideModal.vue';
// side modal contents
import StepOneMeta from './NewCaseSteps/StepOneMeta.vue';

const store = useStore();
const isCaseModalOpen = computed(() => store.state.isCaseModalOpen);

const newCaseForm = ref({
    caseName: "",
    responsiblePerson: "",
    additionalInformation: ""
});

// ---------- //
// SIDE MODAL //
// ---------- //
// close
function resetNewCaseSideModal() {
    newCaseForm.value.caseName = "";
    newCaseForm.value.responsiblePerson = "";
    newCaseForm.value.additionalInformation = "";
    store.dispatch('closeCaseModal');
    console.log(`reset new case`);
}

onBeforeUnmount(() => {
    resetNewCaseSideModal();
});
</script>