<template>
  <main class="mt-0 main-content bg-white">
    <section>
      <div class="page-header min-vh-100">
        <div class="sinacluster-logo">
          <img src="@/assets/img/illustrations/logo.svg" width="50" height="50" />
          <h2><span class="color-primary-700">SINA</span>CLUSTER</h2>
        </div>
        <div class="container">
          <div class="row">
            <div class="pb-0 card-header position-relative text-start bg-transparent">
              <h1 class="font-weight-font-weight-700er text-grey-800">404 Page Not Found</h1>
              <p>Oops! The page you are looking for does not exist.</p>
              <p>It might have been moved or deleted.</p>
              <p><a href="/">← Go back to the dashboard</a></p>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div
                class="a404bg position-relative h-100 m-3 border-radius-lg d-flex flex-column justify-content-center overflow-hidden">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
import { useStore } from "vuex";
import { onBeforeUnmount, onBeforeMount } from "vue";

const store = useStore();

onBeforeMount(() => {
  store.state.showNavbar = false;
  store.state.showSidenav = false;
});

onBeforeUnmount(() => {
  store.state.showNavbar = true;
  store.state.showSidenav = true;
});
</script>
