export function normalizeAlphaNumericSpace(input) {
    let sanitized = input.trimStart();
    sanitized = sanitized.replace(/[^a-zA-Z0-9äöüÄÖÜß .\-_]+/g, '');
    sanitized = sanitized.replace(/\s\s+/g, ' ');
    return sanitized;
};

export function normalizeSpace(input) {
    // remove all whitespace and only allow English letters, numbers, and special characters on a standard keyboard.
    return input.replace(/[\s]+|[^\w!@#$%^&*()_+|~=`{}[\]:";'<>?,.\\/-]/g, '');}

export function normalizeEvidUniqueDescription(input) {
    let sanitized = input.trimStart();
    sanitized = sanitized.replace(/[^a-zA-Z0-9äöüÄÖÜß .,\-<>.?/:;"'{}[\]|\\~!@#$%^&*()_+=]+/g, '');
    sanitized = sanitized.replace(/\s\s+/g, ' ');
    return sanitized;
};