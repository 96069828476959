<template>
    <Modal :isOpen="isModalOpen" title="Forgot Password?" status="alert" @update:isOpen="isModalOpen = $event">
        Please contact the Sinabis Call Center at<br>0619-69216657
        <button @click="closeModal" class="cbtn-sm mt-4 w-100 bg-red-ef5350 color-white hover-bg-red-600">Close</button>
    </Modal>
    <main class="mt-0 main-content bg-white">
        <section>
            <div class="page-header min-vh-100">
                <div class="sinacluster-logo d-flex align-items-center">
                    <img src="@/assets/img/illustrations/logo.svg" width="50" height="50" />
                    <h2 class="bold m-0" style="font-size: 2rem;"><span class="color-primary-700">SINA</span>CLUSTER</h2>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0" style="width: 350px;">
                            <div class="card card-plain">
                                <div class="pb-0 card-header position-relative text-start bg-transparent">
                                    <h4 class="font-weight-font-weight-700er text-grey-800" style="letter-spacing: normal;font-size:1rem;">Sign In</h4>
                                    <p class="mb-0 text-grey-700" style="font-size: 0.8125rem;">Please provide your username and password</p>
                                    <div id="warningMessage" class="warning-message color-customized-red" style="margin: 0rem 1.25rem 0rem 1.5rem;">
                                        {{ loginError }}
                                    </div>
                                </div>
                                <div class="card-body">
                                    <form role="form" @submit.prevent="handleLogin">
                                        <sinacluster-input id="user-name-input" :passwordMode="false"
                                            sanitize="normalize-alpha-numeric-space" class="mb-3" placeholder="Username"
                                            v-model="formData.username" />
                                        <sinacluster-input id="pw-input" :passwordMode="true" class="mb-3"
                                            placeholder="Password" v-model="formData.password" />

                                        <div class="mb-2">
                                            <a href="#"
                                                class="forgot-password-link color-primary-500 cursor-pointer text-xs"
                                                style="letter-spacing: normal;font-size:0.8125rem;"
                                                @click="openModal">
                                                Forgot password?
                                            </a>
                                        </div>
                                        <div class="text-center">
                                            <button class="mt-2 newbtn d-flex justify-content-center bg-primary-700 hover-bg-primary-600 color-white w-100"
                                                type="submit">
                                                Sign in
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
                            <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden">
                                <video class="position-absolute h-100 w-100 centered-video" autoplay loop muted playsinline>
                                    <source src="../assets/img/sinacluster.webm" preload="meta" type="video/webm">
                                    <!-- TODO: SHOW IMAGE IF VIDEO NOT SUPPORTED -->
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script setup>
import { useStore } from "vuex";
import { onBeforeUnmount, onBeforeMount, ref } from "vue";
import router from '@/router';
import { useAuthStore } from '@/store/auth.store';
// components
import Modal from '@/components/widgets/modal/Modal.vue';
import SinaclusterInput from '@/components/widgets/SinaclusterInput.vue';

const store = useStore();
const formData = ref({ username: '', password: '' });

onBeforeMount(() => {
    store.state.showNavbar = false;
    store.state.showSidenav = false;
    formData.value.username = generateRandomUsername()
    formData.value.password = generateRandomWord(8)

});

onBeforeUnmount(() => {
    store.state.showNavbar = true;
    store.state.showSidenav = true;
});

//Modal
const isModalOpen = ref(false);

const openModal = () => {
    isModalOpen.value = true;
};

const closeModal = () => {
    isModalOpen.value = false;
};

// login + display error message
const authStore = useAuthStore();

const loginError = ref('');
async function handleLogin() {
    let loginState = false;
    try {
        loginState = await authStore.login(formData.value.username, formData.value.password);
        if (loginState) {
            if (loginState === 'success') {
                router.push('/');
            } else if (loginState === 'change-pw') {
                router.push('/change-password/required')
            }
        }
    } catch (err) {
        loginError.value = err.message;
    }
};

function generateRandomUsername() {
  var characters = '0123456789';
  var result = 'Admin';
  for (var i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

function generateRandomWord(length) {
  var characters = 'abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ';
  var result = '';
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

</script>
