// frontend: store/resultStore.js
import { defineStore } from 'pinia';
import { fetchWrapper } from '../_helpers/fetch-wrapper';

const API_URL = process.env['VUE_APP_API_URL'];

export const useResultStore = defineStore('result', {
    state: () => ({
        results: [],
        connected: false,
        resultsCaseId: [],
    }),
    actions: {
        bindEvents(socket) {
            socket.on("connect", async () => {
                this.results = await this.getAllResults();
                this.connected = true;
            });
            socket.on("disconnect", () => {
                this.connected = false;
            });
            socket.on("process:result:created", (msg) => {
                this.results.push(msg);
            });
            socket.on("process:result:updated", (msg) => {
                this.update(msg, this.results);
                this.update(msg, this.resultsCaseId);
            });
            socket.on("process:result:destroyed", (msg) => {
                this.results = this.results.filter((r) => r.id !== msg.id);
            });
        },
        update(update, source) {
            const index = source.findIndex((item) => item.id === update.id);
            if (index !== -1) {
                source[index] = update;
            }
        },
        async getAllResults() {
            try {
                const results = await fetchWrapper.get(`${API_URL}/results`);
                if (results) {
                    return results
                }
            } catch (error) {
                throw new Error(`[resultStore] failed to get all results. ${error}`);
            }
        },
        async getResultByCaseId(caseId) {
            try {
                const response = await fetchWrapper.get(`${API_URL}/results/case/${caseId}`);
                this.resultsCaseId = await response;
                return response;
            } catch (error) {
                console.error('Failed to fetch results for case id:', error);
            }
        }
        // TODO: use filter
        // async getResultByProcessId(processId) {
        //     try {
        //         const response = await fetchWrapper.get(`${API_URL}/results/process/${processId}`);
        //         this.results = await response;
        //         return response;
        //     } catch (error) {
        //         console.error('Failed to fetch results by process id:', error);
        //     }
        // },

        // async getResultById(resultId) {
        //     try {
        //         const response = await fetchWrapper.get(`${API_URL}/results/${resultId}`);
        //         this.currentResult = await response;
        //         return response;
        //     } catch (error) {
        //         console.error('Failed to fetch result by id:', error);
        //     }
        // },
    },
});
