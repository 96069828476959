import {createApp} from 'vue';
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ArgonDashboard from "./argon-dashboard";
import {createPinia} from 'pinia';
import '/src/assets/css/sinacluster-main.css';

const appInstance = createApp(App);
const pinia = createPinia();

appInstance.use(pinia);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");
