<template>
    <main class="main-content main-content-bg mt-0">
        <div class="page-header min-vh-80">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-7">
                        <div v-if="props.source !== 'tutor'"
                            class="flex gap-1 align-center cursor-pointer pb-4 hover-text-black" 
                            @click="backToUserControl">
                            <ChevronLeft class="icon-size-15px" style="color: inherit;" />
                            <p class="text-sm m-0" style="color: inherit;">User management</p>
                        </div>
                        <div class="card z-index-0 border">
                            <div class="card-header pt-4 pb-1">
                                <h4 style="font-size: 0.9rem;" class="font-weight-font-weight-700er mb-1">
                                    Create User
                                </h4>
                                <p style="font-size: 0.8125rem;" class="mb-0 color-grey-700">
                                    Please set a name and password
                                </p>
                            </div>
                            <!-- main content -->
                            <div class="card-body">
                                <form @submit.prevent="handleCreateUser" role="form">
                                    <label for="userName" class="font-weight-500">
                                        User Name
                                    </label>
                                    <sinacluster-input id="input-user-name" :passwordMode="false"
                                        sanitize="normalize-alpha-numeric-space" class="mb-3" placeholder="Max Mustermann"
                                        v-model="userName" />

                                    <label for="userPassword" class="font-weight-500">
                                        Password
                                    </label>
                                    <sinacluster-input id="new-pw-input" :passwordMode="true" class="mb-3"
                                        placeholder="" v-model="userPassword" />

                                    <!-- user hint / user warnings -->
                                    <div class="position-relative pt-2 mb-3">
                                        <span
                                            class="warning-message color-customized-red warning-customized-margin d-flex align-item-center"
                                            v-if="false">
                                            * required field not filled.
                                        </span>
                                    </div>
                                    <div class="text-center">
                                        <button class="mt-2 newbtn d-flex align-items-center justify-content-center hover-bg-primary-600 bg-primary-700 w-100 color-white"
                                            type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script setup>
import router from '@/router';
import { ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useUserStore } from '@/store/userStore';
import tutorialLandingStore from '@/store/frontend/tutorialLandingStore.js';
// components
import SinaclusterInput from '@/components/widgets/SinaclusterInput.vue';
import ChevronLeft from '@/components/icons/ChevronLeft.vue';

const userStore = useUserStore();
const { setTutorialStep } = tutorialLandingStore;

const props = defineProps({
    source: {
        type: String,
        default: '',
        required: false,
    }
});

// css style control
const store = useStore();

onMounted(() => {
    store.state.showSidenav = false;
    if (props.source === 'tutor' ) {
        store.state.displayLogOut = false;
        setTutorialStep(2);
    }
});

onUnmounted(() => {
    store.state.showSidenav = true;
    if (!store.state.displayLogOut) {
        store.state.displayLogOut = true;
    }
})

// change password
const userName = ref('');
const userPassword = ref('');

// create user submit
const handleCreateUser = async () => {
    await userStore.createUser(
        { name: userName.value, password: userPassword.value, access: {} }
    );
    backToUserControl();
}

const backToUserControl = () => {
    router.push('/user-control')
}
</script>

<style scoped>
.warning-customized-margin {
    margin: 0rem 0rem 0rem 0.5rem;
}

.skip {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    background: #f7f7f7;
    margin-top: -7px;
    cursor: pointer;
    font-weight: 700;
    line-height: 2;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 0.8rem;
    height: 40px;
}

.skip:hover {
    background: #e7e7e7;
    transition: all 0.15s ease-in;
}
</style>