<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import CalendarIcon from '@/components/icons/CalendarIcon.vue';
import ArrowPath from '@/components/icons/ArrowPath.vue';
import BankNotesIcon from '@/components/icons/BankNotesIcon.vue';
import BarsArrowUpIcon from '@/components/icons/BarsArrowUpIcon.vue';
import BarsArrowDownIcon from '@/components/icons/BarsArrowDown.vue';

const props = defineProps({
    currentCriterion: {
        type: String,
        required: true
    }
});
const emit = defineEmits(['set-criterion']);

const displayDropDown = ref(false);
const dropdownRef = ref(null);

const toggleSort = () => {
    displayDropDown.value = !displayDropDown.value;
};

const handleClickOutside = (event) => {
    if (!dropdownRef.value.contains(event.target)) {
        displayDropDown.value = false;
    }
};

const setCriterion = (choice) => {
    emit('set-criterion', choice);
    toggleSort();
};

const newDirection = ref(props.currentCriterion)

const toggleCriterionDirection = () => {
    const [field, direction] = props.currentCriterion.split(' ');
    if (direction === 'ascending') {
        newDirection.value = 'descending'
    } else {
        newDirection.value = 'ascending'
    }
    const newCriterion = `${field} ${newDirection.value}`;

    switch (newCriterion) {
        case "Date descending":
            return setCriterion("createdAt_desc");
        case "Date ascending":
            return setCriterion("createdAt_asc");
        case "Updated descending":
            return setCriterion("updatedAt_desc");
        case "Updated ascending":
            return setCriterion("updatedAt_asc");
        case "Cost descending":
            return setCriterion("cost_desc");
        case "Cost ascending":
            return setCriterion("cost_asc");
        default:
            return setCriterion("createdAt_desc");
    }
};

onMounted(() => {
    document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
    <div class="cursor-pointer d-flex gap-2 align-items-center" ref="dropdownRef">
        <BarsArrowUpIcon @click="toggleCriterionDirection" v-if="newDirection.includes('ascending')"
            class="icon-size-15px color-grey-700" />
        <BarsArrowDownIcon @click="toggleCriterionDirection" v-if="newDirection.includes('descending')"
            class="icon-size-15px color-grey-700" />
        <div class="yAxeSeperator">
            <div class="yhr"></div>
        </div>
        <div @click.stop="toggleSort" style="width: auto">
            <span>{{ props.currentCriterion }}</span>
        </div>
        <div v-if="displayDropDown"
            class="position-absolute select-dropdown bg-white d-flex flex-column align-items-center">
            <div style="margin-top: 10px;" class="customized-dropdown-item" value="createdAt_desc"
                @click="setCriterion('createdAt_desc')">
                <CalendarIcon class="icon-size-15px" />
                Created
            </div>
            <div class="seperator">
                <div class="select-hr"></div>
            </div>
            <div class="customized-dropdown-item" value="updatedAt_desc" @click="setCriterion('updatedAt_desc')">
                <ArrowPath class="icon-size-15px" />
                Updated
            </div>

            <div class="seperator">
                <div class="select-hr"></div>
            </div>
            <div style="margin-bottom: 10px;" class="customized-dropdown-item" value="cost_desc"
                @click="setCriterion('cost_desc')">
                <BankNotesIcon class="icon-size-15px" />
                Cost
            </div>
        </div>
    </div>
</template>


<style scoped>
.select-hr {
    border-bottom: 0.5px solid rgb(224, 224, 224);
    width: 100%;
}

.seperator {
    display: flex;
    align-content: center;
    align-items: center;
    height: 11px;
    width: 100%;
}

.select-dropdown {
    width: 178px;
    left: 0px;
    top: -10px;
    border: 0.5px solid rgb(216, 216, 216);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.067) 0px 3px 8px, rgba(0, 0, 0, 0.067) 0px 2px 5px, rgba(0, 0, 0, 0.067) 0px 1px 1px;
}

.border-underline {
    border-bottom: 1px solid #00000024
}

.customized-dropdown-item {
    color: var(--grey-800);
    font-size: 0.8125rem !important;
    width: 160px;
    padding: 6px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.customized-dropdown-item:hover {
    background-color: var(--grey-100);
}

.yAxeSeperator {
    width: 3px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yhr {
    border-right: solid 1px var(--grey-300);
    width: 1px;
    height: 20px;
}
</style>