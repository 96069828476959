<template>
    <aside class="sidenav bg-grey-50 navbar-vertical navbar-expand-xs fixed-start d-flex flex-column">
        <!-- header -->
        <div class="sidenav-header">
            <div class="sinaicon">
                <img :src="logo" class="h-100" alt="main_logo" />
            </div>
            <div class="welcome">
                <span class="semibold">SINACLUSTER</span>
                <span>Dashboard</span>
            </div>
            <div class="history">
                 <NotificationBell />
            </div>
        </div>

        <!-- list -->
        <div class="sidenav-content" style="flex: 1; overflow-y: auto;">
            <sidenav-list />
        </div>
        <div class="sidenav-footer w-100 ps-4 mb-4 justify-content-end" style="flex: 0;">
            <p class="text-xs m-0 color-grey-800 cursor-default">
                © Sinabis GmbH
            </p>
            <p class="text-xs m-0 pt-1 cursor-pointer color-grey-800 hover-color-grey-600" 
                @click="showTermsAndConditions"> Terms &amp; Conditions </p>
        </div>
    </aside>
</template>

<style scoped>
.sidenav .sidenav-header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px 10px 20px;
}

.sidenav .sidenav-header .welcome {
    display: flex;
    flex-direction: column;
    font-size: 0.8125rem;
}

.sidenav .sidenav-header .history {
    padding: 2px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    margin-left: auto;
    cursor: pointer;
}

.sidenav .sidenav-header .sinaicon {
    height: 35px;
    width: 35px;
    background: var(--primary-500);
    padding: 5px;
    border-radius: 7px;
    display: flex;
}
</style>

<script setup lang="js">
import { useStore } from "vuex";
import logo from "@/assets/img/illustrations/logo_white.svg";
import SidenavList from "@/components/template/Sidenav/SidenavList.vue";
import NotificationBell from "../../widgets/NotificationBell.vue";

const store = useStore();

const showTermsAndConditions = () => {
    store.dispatch('openGtc');
}
</script>