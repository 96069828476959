<template>
    <Loading v-if="loading" class="p-2" />
    <div class="" v-else>
        <Landing v-if="landingStep > 0 && landingStep <= 3" :caseItem="firstCase" />
        <div v-else class="d-flex flex-column">
            <div class="d-flex justify-content-between gap-4 p-3 realign-db-stats">
                <mini-statistics-card title="Cases" :value="formatNumber(caseLength)"
                :icon="InboxIcon" />
                <mini-statistics-card title="Total Costs" :value="totalCost"
                :icon="BankNotesIcon" />
                <mini-statistics-card title="Data Processed" :value="dataProcessed"
                :icon="DocumentCheck" />
                <mini-statistics-card title="Pending Requests" :value="pendingRequest"
                :icon="ArrowPath" />
            </div>
            <DashboardContent :files="filesSuccess" :messages="messagesSuccess" class="py-2 mx-3" />
            <div class="p-3">
                <div class="p-4 dashboard-card-border">
                    <ProgressBarChart :chartData="progressTableData.barChartData" />
                </div>
            </div>
            <!--
            <div class="p-3">
                <RecentCases :caseItems="cases" :calculator="calculateCaseCost" :loading="loading" />
            </div> -->
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, watch, ref } from 'vue';
import { useCaseStore } from "@/store/caseStore";
import { useResultStore } from "@/store/resultStore";
import tutorialLandingStore from '@/store/frontend/tutorialLandingStore.js';
// helper
//import evidenceParser from '@/_helpers/ResponseParser.js';
import  { formatNumber } from '@/_helpers/helpers.js';
// components
import Landing from '@/components/dashboard/Landing.vue';
import Loading from '@/components/dashboard/Loading.vue';
import MiniStatisticsCard from "@/components/dashboard/Statcard.vue";
//import RecentCases from "@/components/dashboard/RecentCases.vue";
import DashboardContent from '@/components/dashboard/DashboardContent.vue';
import ProgressBarChart from '@/components/dashboard/ProgressBarChart.vue';

//icons
import InboxIcon from '../components/icons/InboxIcon.vue';
import BankNotesIcon from '../components/icons/BankNotesIcon.vue';
import ArrowPath from '../components/icons/ArrowPath.vue';
import DocumentCheck from '../components/icons/DocumentCheck.vue';

const caseStore = useCaseStore();
const resultStore = useResultStore();
const { landingStep } = tutorialLandingStore;

// onMounted
const loading = ref(true);
const storeConnection = ref(false);

const _checkStoreConnection = (_isConnected) => {
    storeConnection.value = _isConnected;
    if (_isConnected) {
        loading.value = false;
    };
};

// import tutorialLandingStore from '@/store/frontend/tutorialLandingStore.js';
onMounted(() => {
    _checkStoreConnection(caseStore.connected);
    calculateTotalCost();
    calculateCounts();
});

watch(() => [caseStore.connected, resultStore.connected], () => {
    const connected = caseStore.connected;
    _checkStoreConnection(connected);
    calculateTotalCost();
    calculateCounts();
});


// computed
const cases = computed(() => caseStore.cases?.slice().sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)));
const firstCase = computed(() => cases.value.length > 0 ? cases.value[0] : { Evidence: [], caseId: '' });
const caseLength = computed(() => cases.value.length);
// const results = computed(() => resultStore.results);

const dataProcessed = computed(() => {
    let numUploads = 0;
    let numProcessed = 0;
    caseStore.cases.forEach((casefound) => {
        if (casefound.Evidence && Array.isArray(casefound.Evidence)) {
            numUploads += casefound.Evidence.length;
            numProcessed += casefound.Evidence.filter(
                (ev) => ev.ProcessingProcess?.state === "success"
            ).length;
        }
    });
    const processedRate = numProcessed / numUploads || 0;
    const formattedProcessRate = (processedRate * 100).toFixed(2) + "%";
    return formattedProcessRate;
});

const pendingRequest = computed(() => {
    let numUploads = 0;
    let numProcessed = 0;
    caseStore.cases.forEach((casefound) => {
        if (casefound.Evidence && Array.isArray(casefound.Evidence)) {
            numUploads += casefound.Evidence.length;
            numProcessed += casefound.Evidence.filter(
                (ev) => ev.ProcessingProcess?.state === "success"
            ).length;
        }
    });

    return (numUploads - numProcessed) || "0";
});


// TODO: [FIX:resultStore] totalCost cannot be updated using computed
const totalCost = ref('0.00€');
const calculateTotalCost = async() => {
    let _totalCost = 0;
    const results = await resultStore.getAllResults();
    results.forEach(resultItem => {
        if (resultItem.state === 'success') {
            const cost =  Number((resultItem.amount * resultItem.BillingType.price_per_unit / 10000).toFixed(2))
            _totalCost += cost;
            _totalCost = Number(_totalCost.toFixed(2));
        }
    });
    totalCost.value = `${_totalCost.toFixed(2)}€`;
};

const messagesSuccess = ref(0);
const messagesFailed = ref(0);
const filesSuccess = ref(0);
const filesFailed = ref(0);

const calculateCounts = async () => {
    let totalMessagesSuccess = 0;
    let totalMessagesFailed = 0;
    let totalFilesSuccess = 0;
    let totalFilesFailed = 0;

    // Assuming `resultStore.getAllResults()` returns a promise that resolves to an array of results
    const results = await resultStore.getAllResults();
    results.forEach(resultItem => {
        if (resultItem.BillingType.step_name === 'count_files_content') {
            if (resultItem.state === 'success') {
                totalFilesSuccess += resultItem.amount;
            } else if (resultItem.state === 'failed') {
                totalFilesFailed += resultItem.amount;
            }
        } else if (resultItem.BillingType.step_name === 'count_messages') {
            if (resultItem.state === 'success') {
                totalMessagesSuccess += resultItem.amount;
            } else if (resultItem.state === 'failed') {
                totalMessagesFailed += resultItem.amount;
            }
        }
    });

    // Update reactive references
    messagesSuccess.value = totalMessagesSuccess;
    messagesFailed.value = totalMessagesFailed;
    filesSuccess.value = totalFilesSuccess;
    filesFailed.value = totalFilesFailed;
};

watch([pendingRequest, dataProcessed], () => { 
    console.log(`recalculate`);
    calculateTotalCost();
    calculateCounts();
 });

 

// [Table] Recent Case - cost per case
// async function calculateCaseCost(caseItem) {
//     if (storeConnection.value) {
//         const resultItem = await resultStore.getResultByCaseId(caseItem.caseId);
//         const costPerCase = new evidenceParser(caseItem, resultItem).calculateCostPerCase();
//         return costPerCase
//     } else {
//         return 'NaN'
//     }
// };

const progressTableData = computed(() => {
    const _report = {
        totalSizeGB: 0,
        barChartData: []
    };
    cases.value.forEach((caseItem) => {
        _report[caseItem.caseName] = {
            count: { processed: 0, running: 0, failed: 0, ready: 0, otal: 0 },
            sumSizeMB: { processed: 0, running: 0, failed: 0, ready: 0, total: 0 }
        }
        let _barChartData = {
            id: caseItem.caseId,
            name: caseItem.caseName,
            updatedAt: caseItem.updatedAt,
            data: []
        };
        if (caseItem.Evidence && caseItem.Evidence?.length > 0) {
            caseItem.Evidence.forEach((evidItem) => {
                const _evidSizeMB = evidItem.meta.upload.size / 1024 / 1024;
                _report.totalSizeGB += _evidSizeMB / 1024;
                if (evidItem.ProcessingProcess !== null) {
                    const _processingState = evidItem.ProcessingProcess.state;
                    if (_processingState === 'success') {
                        _report[caseItem.caseName].count.processed += 1;
                        _report[caseItem.caseName].sumSizeMB.processed += _evidSizeMB;
                    } else if (_processingState === 'failed') {
                        _report[caseItem.caseName].count.failed += 1
                        _report[caseItem.caseName].sumSizeMB.failed += _evidSizeMB;
                    } else {
                        // state: running or queued
                        _report[caseItem.caseName].count.running += 1;
                        _report[caseItem.caseName].sumSizeMB.running += _evidSizeMB;
                    }
                } else {
                    _report[caseItem.caseName].count.ready += 1;
                    _report[caseItem.caseName].sumSizeMB.ready += _evidSizeMB;
                }
            });
            _report[caseItem.caseName].count.total = caseItem.Evidence.length;
            _barChartData.data.push({
                title: 'Ready',
                value: `${Math.round(100 * _report[caseItem.caseName].count.ready / _report[caseItem.caseName].count.total)}%`
            });
            _barChartData.data.push({
                title: 'Processed',
                value: `${Math.round(100 * _report[caseItem.caseName].count.processed / _report[caseItem.caseName].count.total)}%`
            });
            _barChartData.data.push({
                title: 'Running',
                value: `${Math.round(100 * _report[caseItem.caseName].count.running / _report[caseItem.caseName].count.total)}%`
            });
            _barChartData.data.push({
                title: 'Failed',
                value: `${Math.round(100 * _report[caseItem.caseName].count.failed / _report[caseItem.caseName].count.total)}%`
            });
            _barChartData.data.push({
                title: 'NoEvid',
                value: '0%'
            });
        } else {
            _barChartData.data.push({ title: 'Ready', value: '0%' });
            _barChartData.data.push({ title: 'Processed', value: '0%' });
            _barChartData.data.push({ title: 'Running', value: '0%' });
            _barChartData.data.push({ title: 'Failed', value: '0%' });
            _barChartData.data.push({ title: 'NoEvid', value: '100%' });
        };
        _report.barChartData.push(_barChartData);
    });
    return _report;
})
</script>
