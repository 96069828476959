<template>
    <router-link :to="to" class="d-flex align-items-center p-1" @click="minimizeSidebar">
        <div class="border-radius-0-2rem icon-sm flex justify-content-center"
            style="padding: 0.45rem;">
            <slot name="icon"></slot>
        </div>
        <span class="">{{navText}}</span>
        <div v-if="notificationBadge" 
            class="ms-2 d-flex align-items-center justify-content-center border-round nav-notification-badge"
            :class="getRoute() === 'case-overview' ? 'color-white bg-red-500' : 'color-white bg-red-500'">
            <span style="margin-bottom: 1px!important;">{{notificationBadge}}</span>
        </div>
    </router-link>
</template>

<script setup>
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const sidebarMinimize = () => store.commit("sidebarMinimize");

const minimizeSidebar = () => {
  if (window.innerWidth < 1200) {
    sidebarMinimize();
  }
};

const getRoute = () => {
    const route = useRoute();
    const routeArr = route.path.split("/");
    return routeArr[1];
};

defineProps({
  to: {
    type: String,
    required: true,
  },
  navText: {
    type: String,
    required: true,
  },
  notificationBadge: {
    required: false,
    default: false
  }
});
</script>

<style scoped>
.nav-notification-badge {
    width: 16px;
    height: 16px;
    font-size: .6rem;
}
</style>